import React, { useEffect, useState } from 'react';
import {
  Form, Input, InputNumber, Select, Switch, Spin, Tooltip
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';

import { normalize, formatInput } from 'common/utils/number';
import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import { useGet, usePost, usePut } from 'common/api';

import { SHARED_CURRENCY_LIST } from 'modules/shared/_constants/cacheKeys';
import { DEBT_DEBT, DEBT_LIST, DEBT_TYPE_LIST } from 'modules/debt/_constants/cacheKeys';

import FormSubmitButtons from 'common/components/FormSubmitButtons';
import PersonSelect from '../../../shared/person/components/PersonSelect';

import DebtStatusStatic from '../static/DebtStatusStatic';
import DebtRecordTypeStatic from '../static/DebtRecordTypeStatic';
import DebtBondStatic from '../static/DebtBondStatic';
import DebtSourceStatic from '../static/DebtSourceStatic';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

const DebtForm = () => {
  const intl = useIntl();
  const { projectId, debtId } = useParams();
  const history = useHistory();
  const [form, setForm] = useState({
    id: debtId || null,
    projectId,
    creditorId: null,
    typeId: null,
    currencyId: null,
    name: null,
    description: null,
    value: null,
    status: null,
    recordType: null,
    dueDay: null,
    businessDay: false,
    installmentQuant: null,
    bond: null,
    source: null,
    metadata: null
  });

  const { data: typeData, isLoading: isLoadingType } = useGet(DEBT_TYPE_LIST, '/debt/type');
  const { data: currencyData, isLoading: isLoadingCurrency } = useGet(SHARED_CURRENCY_LIST, '/shared/currency');

  const cacheKey = [DEBT_DEBT, debtId];
  const { data, isLoading: isLoadingData } = useGet(cacheKey, '/debt/debt', {
    params: { id: debtId },
    query: { enabled: Boolean(debtId) },
  });
  useEffect(() => {
    if (data) {
      setForm({
        ...data[0],
        value: formatInput(data[0].value, { toNormalize: true }),
        metadata: data[0].metadata ? JSON.stringify(data[0].metadata, null, 2) : null
      });
    }
  }, [data]);

  const options = {
    fetch: {
      body: {
        ...form,
        value: normalize(form.value)
      }
    },
    onSuccess: history.goBack,
    cacheInvalidate: [
      [DEBT_LIST, projectId],
      debtId && [DEBT_DEBT, debtId],
    ],
  };
  const { mutate, isLoading } = usePost(() => '/debt/debt', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/debt/debt/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const typeOptions = typeData?.map(v => (
    <Option key={`${v.id}`} value={`${v.id}`}>
      {v.name}
    </Option>
  ));

  const currencyOptions = currencyData?.map(v => (
    <Option key={`${v.id}`} value={`${v.id}`}>
      {v.name}
    </Option>
  ));

  const statusOptions = Object.entries(DebtStatusStatic).map(
    ([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} />
      </Option>
    ),
  );

  const bondOptions = Object.entries(DebtBondStatic).map(
    ([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} />
      </Option>
    ),
  );

  const sourceOptions = Object.entries(DebtSourceStatic).map(
    ([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} />
      </Option>
    ),
  );

  const renderRecordTypeOptions = Object.entries(
    DebtRecordTypeStatic,
  ).map(([key, value]) => (
    <Option key={key} value={`${key}`}>
      <FormattedMessage id={value} values={{ plural: false }} />
    </Option>
  ));

  return (
    <Spin spinning={isLoading || isLoadingUpdate || isLoadingData}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'name' })}
        >
          <Input
            maxLength={200}
            onChange={e => setFormData({ name: e.target.value })}
            value={form.name}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <TextArea
            rows="2"
            onChange={e => setFormData({ description: e.target.value })}
            value={form.description}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'creditor' })}
        >
          <PersonSelect
            initialLabel={data ? data[0].creditor.name : null}
            onSelect={creditorId => {
              setFormData({ creditorId });
            }}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'type' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={typeId => setFormData({ typeId })}
            value={form.typeId ? `${form.typeId}` : ''}
            loading={isLoadingType}
          >
            {typeOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'record_type' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={recordType => setFormData({ recordType })}
            value={form.recordType ? `${form.recordType}` : ''}
          >
            {renderRecordTypeOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'bond' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={bond => setFormData({ bond })}
            value={form.bond ? `${form.bond}` : ''}
          >
            {bondOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'source' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={source => setFormData({ source })}
            value={form.source ? `${form.source}` : ''}
          >
            {sourceOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'currency' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={currencyId => setFormData({ currencyId })}
            value={form.currencyId ? `${form.currencyId}` : ''}
            loading={isLoadingCurrency}
          >
            {currencyOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'total_value' })}
        >
          <Input
            maxLength={20}
            onChange={e => setFormData({ value: formatInput(e.target.value) })}
            value={form.value}
            style={{ width: 200 }}
          />
        </FormItem>

        <FormItem {...defaultFormItemLayout} label="Status">
          <Select
            style={{ width: '100%' }}
            onChange={status => setFormData({ status })}
            value={form.status ? `${form.status}` : ''}
          >
            {statusOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'due_day' })}
        >
          <InputNumber
            onChange={dueDay => setFormData({ dueDay })}
            value={form.dueDay}
            min={1}
            max={31}
            style={{ width: 80 }}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage(
            {
              id: 'installment',
            },
            { plural: true },
          )}
        >
          <InputNumber
            onChange={installmentQuant => setFormData({ installmentQuant })}
            value={form.installmentQuant}
            min={1}
            max={9999}
            style={{ width: 80 }}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({
            id: 'business_day_payment',
          })}
        >
          <Switch
            checked={form.businessDay}
            onChange={businessDay => setFormData({ businessDay })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={(
            <>
              <Tooltip title="{ &quot;text&quot;: &quot;abcd&quot;, &quot;number&quot;: 10.57 }">
                <QuestionCircleOutlined style={{ marginRight: 4 }} />
              </Tooltip>
              {intl.formatMessage({ id: 'metadata' })}
            </>
          )}
        >
          <TextArea
            rows="4"
            onChange={e => setFormData({ metadata: e.target.value })}
            value={form.metadata}
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!debtId ? mutate() : mutateUpdate(debtId))}
          onCancel={() => history.goBack()}
        />
      </Form>
    </Spin>
  );
};

export default DebtForm;
