import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Tooltip, Button, Tag, Table, Menu, Modal, Dropdown
} from 'antd';
import { MoreOutlined, PlusOutlined, FilterOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import ContentWithLeftButtons from 'common/components/ContentWithLeftButtons';
import { useGet, useDelete, useQueryClient } from 'common/api';

import { PROJECT_TASK_LIST } from 'modules/project/_constants/cacheKeys';
import TaskListFilter from './TaskListFilter';

const { confirm } = Modal;
const renderTags = tags => tags.map(({ id, name, color }) => <Tag key={`${id}`} color={color}>{name}</Tag>);

const TaskList = props => {
  const { baseUrl: bUrl, showButtons } = props;
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { projectId } = useParams();
  const { push } = useHistory();
  const baseUrl = bUrl || `/project/${projectId}`;

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
    total: null
  });
  const [filters, setFilters] = useState({});
  const [showFilters, setShowFilters] = useState(false);

  const {
    data,
    isLoading
  } = useGet([PROJECT_TASK_LIST, projectId, pagination.current],
    '/project/task', {
      params: {
        projectId,
        page: pagination.current,
        pageSize: pagination.pageSize,
        ...filters
      },
      query: { keepPreviousData: true }
    });

  useEffect(() => {
    if (data?.rows) {
      setPagination({ ...pagination, total: data.count });
    }
  }, [data]);

  const { mutate } = useDelete(id => `/project/task/${id}`, { cacheInvalidate: [[PROJECT_TASK_LIST, projectId]] });

  const onTableChange = curPage => {
    setPagination(curPage);
  };

  const onFilter = filtersList => {
    setFilters(filtersList);
    queryClient.removeQueries([PROJECT_TASK_LIST, projectId]);
  };

  const menuActions = record => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`${baseUrl}/task/${record.id}/edit`}>
              <FormattedMessage id="edit" />
            </Link>
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.inactivate_item' }),
                onOk: () => mutate(record.id)
              });
            }}
          >
            <FormattedMessage id="delete" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      render: task => {
        const {
          dueAt, id, name, tag
        } = task;
        const colorDue = moment(dueAt).diff(moment()) < 0 ? 'red' : 'rgb(153,153,153)';
        return (
          <span>
            <small
              style={{
                marginRight: 8,
                color: colorDue,
                display: 'block'
              }}
            >
              {dueAt ? moment.utc(dueAt).fromNow() : ''}
            </small>
            <Link to={`${baseUrl}/task/${id}/details`}>
              {name}
            </Link>
            <div style={{ marginTop: 4 }}>
              {renderTags(tag)}
            </div>
          </span>
        );
      },
      key: 'name',
      width: '75%'
    },
    {
      render: task => {
        const { lastHistory: { progress } } = task;
        return (
          <span>
            <Tag
              style={{ width: 44, cursor: 'default', marginRight: 8 }}
              color="geekblue"
            >
              {progress}
              %
            </Tag>
            {menuActions(task)}
          </span>
        );
      },
      key: 'details',
      width: '25%',
      align: 'right'
    }
  ];

  const renderButtons = () => (
    <>
      <Tooltip
        title={<FormattedMessage id="add" />}
        placement="left"
      >
        <Button
          onClick={() => push(`${baseUrl}/task/add`)}
          icon={<PlusOutlined />}
        />
      </Tooltip>
      <Tooltip
        title={<FormattedMessage id="filter" values={{ plural: true }} />}
        placement="left"
      >
        <Button
          onClick={() => setShowFilters(true)}
          icon={<FilterOutlined />}
        />
      </Tooltip>
    </>
  );

  return (
    <ContentWithLeftButtons buttons={showButtons && renderButtons()}>
      <Table
        dataSource={data?.rows}
        rowKey="id"
        columns={renderTableColumns()}
        showHeader={false}
        loading={isLoading}
        pagination={{
          simple: true,
          hideOnSinglePage: true,
          ...pagination
        }}
        onChange={onTableChange}
      />

      <TaskListFilter
        show={showFilters}
        hide={() => setShowFilters(false)}
        onFilter={onFilter}
      />
    </ContentWithLeftButtons>
  );
};

export default TaskList;
