import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import { api } from 'config/api';

const { Option } = Select;

const PersonSelect = props => {
  const {
    onSelect, style, initialLabel, placeholder, disabled
  } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  let debounceTimeout = 0;

  const searchPerson = async inputValue => {
    if (inputValue.length <= 2) {
      return;
    }

    setLoading(true);
    const body = await api('/shared/person', {
      body: { search: inputValue }
    });

    setData(body);
    setLoading(false);
  };

  const searchDebounce = inputValue => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(searchPerson, 500, inputValue);
  };

  return (
    <>
      <Select
        placeholder={(placeholder || (
          <span>
            <FormattedMessage id="find" />
            {' '}
            <FormattedMessage id="person" values={{ plural: false }} />
          </span>
        ))}
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={searchDebounce}
        onChange={onSelect}
        showSearch
        allowClear
        style={style}
        value={initialLabel || undefined}
        disabled={disabled}
      >
        {data.map(d => (
          <Option key={d.id}>{d.name}</Option>
        ))}
      </Select>
    </>
  );
};

PersonSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  initialLabel: PropTypes.string
};

PersonSelect.defaultProps = {
  style: {},
  initialLabel: null
};

export default PersonSelect;
