import React from 'react';
import { FormattedMessage } from 'react-intl';

import TaskDetail from './TaskDetail';
import HistoryList from '../../history/components/HistoryList';

const TaskPage = () => (
  <div>
    <div style={{ marginBottom: 44 }}>
      <TaskDetail />
    </div>

    <h5 style={{ marginBottom: 8 }}>
      <FormattedMessage id="interaction" values={{ plural: true }} />
    </h5>
    <HistoryList />
  </div>
);

export default TaskPage;
