import React, { createContext, useContext, useState } from 'react';

import { useGet } from 'common/api';

import { DEBT_INSTALLMENT } from 'modules/debt/_constants/cacheKeys';

const InstallmentContext = createContext();
const InstallmentUpdateContext = createContext();

export const useInstallment = () => useContext(InstallmentContext);
export const useInstallmentUpdate = () => useContext(InstallmentUpdateContext);

export const InstallmentProvider = ({ children }) => {
  const [id, setId] = useState(null);

  const cacheKey = [DEBT_INSTALLMENT, id];

  const { data } = useGet(cacheKey, '/debt/installment', {
    params: { id },
    query: { enabled: Boolean(id) }
  });

  return (
    <InstallmentContext.Provider value={data ? data[0] : {}}>
      <InstallmentUpdateContext.Provider value={setId}>
        { children }
      </InstallmentUpdateContext.Provider>
    </InstallmentContext.Provider>
  );
};
