import React, { useState } from 'react';
import {
  Menu, Row, Col, Spin
} from 'antd';

import { useGet } from 'common/api';

import ReportForm from './ReportForm';

const ReportList = props => {
  const { domain } = props;
  const [report, setReport] = useState({});

  const { data, isLoading } = useGet(['reportList', domain], `/gateway/report/report/${domain}`);

  const buildMenuItems = () => (
    data?.map(rpt => (
      <Menu.Item
        key={rpt.id}
        onClick={() => setReport(rpt)}
      >
        {rpt.name}
      </Menu.Item>
    ))
  );

  return (
    <Row gutter={16}>
      <Col span={8}>
        <Spin spinning={isLoading}>
          <Menu defaultSelectedKeys={[report.id]}>
            {data && buildMenuItems()}
          </Menu>
        </Spin>
      </Col>

      <Col span={16}>
        <ReportForm parameters={report.parameters} reportId={report.id} />
      </Col>
    </Row>
  );
};

export default ReportList;
