import React from 'react';
import { Table } from 'antd';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const CompositionListGrouped = props => {
  const { debtCurrency, dataSource, loading } = props;

  const renderTableColumns = () => [
    {
      title: <FormattedMessage id="group" />,
      dataIndex: 'group',
      key: 'group'
    },
    {
      title: <FormattedMessage id="item" values={{ plural: true }} />,
      dataIndex: 'items',
      key: 'items',
      width: '20%',
      align: 'center'
    },
    {
      title: <FormattedMessage id="value" values={{ plural: false }} />,
      render: ({ amount }) => (
        debtCurrency && (
          <FormattedNumber
            value={amount}
            maximumFractionDigits={2}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={debtCurrency.symbol}
          />
        )
      ),
      key: 'amount',
      width: '20%',
      align: 'right'
    }
  ];

  return (
    <Table
      dataSource={dataSource}
      rowKey="group"
      columns={renderTableColumns()}
      showHeader
      size="middle"
      loading={loading}
      pagination={false}
    />
  );
};

export default CompositionListGrouped;
