import React from 'react';
import { notification, Modal } from 'antd';

const responseHandler = async (response, body, options) => {
  const { ok, status } = response;
  const { message, data } = body;
  const { notify, emptyIfError } = options;

  if (ok) {
    if (notify) {
      notification.success({ message });
    }

    return data || body || [];
  }

  if (status === 401 && (data && data[0].error === 'token-expired')) {
    window.location.href = '/401';
    return false;
  }

  Modal.error({
    title: message,
    content: data && <DescriptionList errorList={data} />
  });

  if (emptyIfError) {
    return [];
  }

  return 'error';
};

const DescriptionList = ({ errorList }) => (
  <ul style={{ listStyle: 'none', padding: 0, fontSize: '12px' }}>
    {Object.entries(errorList).map(([key, err]) => (
      <li key={`${key}`}>
        {`- ${err.error}`}
      </li>
    ))}
  </ul>
);

export default responseHandler;
