import React, { useState } from 'react';
import { Form, Switch } from 'antd';
import { useIntl } from 'react-intl';

import { usePost } from 'common/api';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

import CalculationSelectableTree from 'modules/debt/calculation/components/CalculationSelectableTree';

import { DEBT_INSTALLMENT } from 'modules/debt/_constants/cacheKeys';

const FormItem = Form.Item;

const InstallmentCalculationForm = props => {
  const {
    debtId, installmentList, onCancel, formLayout
  } = props;

  const intl = useIntl();
  const [form, setForm] = useState({
    clean: false,
    calculationList: []
  });

  const options = {
    fetch: {
      body: {
        ...form,
        debtId,
        installmentList
      }
    },
    onSuccess: onCancel,
    cacheInvalidate: installmentList.map(id => [DEBT_INSTALLMENT, `${id}`])
  };
  const { mutate, isLoading } = usePost(() => '/debt/installment/set-calculation', options);

  const setFormData = value => setForm({ ...form, ...value });

  return (
    <Form layout={formLayout}>
      <FormItem
        label={`${intl.formatMessage({
          id: 'debt.selected_calculations'
        })}:`}
      >
        <CalculationSelectableTree
          onCheck={calculationList => setFormData({ calculationList })}
          checkedKeys={form.calculationList}
        />
      </FormItem>

      <FormItem
        label={`${intl.formatMessage({
          id: 'debt.remove_calculations'
        })}:`}
      >
        <Switch
          checked={form.clean}
          onChange={clean => setFormData({ clean })}
        />
      </FormItem>

      <FormSubmitButtons
        noTail
        loading={isLoading}
        onStore={mutate}
        onCancel={onCancel}
      />
    </Form>
  );
};

InstallmentCalculationForm.defaultProps = {
  installmentList: [],
  formLayout: 'vertical'
};

export default InstallmentCalculationForm;
