import React, { Component } from 'react';
import { api } from 'config/api';

import responseHandler from 'common/utils/responseHandler';
import { showFileUrl } from 'config/app';

import DocForm from './DocForm';

class DocFormPage extends Component {
  state = {
    form: {
      id: null,
      personId: null,
      docTypeId: '',
      fileId: null,
      number: null,
      dueOn: null,
      note: null
    },

    dataDocType: [],
    fileList: [], // Image upload from document
    loading: false
  };

  componentDidMount() {
    const { match } = this.props;
    const { docId, personId } = match.params;
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        id: docId,
        personId
      }
    });

    if (docId) {
      this.loadDataToUpdate(docId);
    }

    this.loadDocType();
  }

  handleStore = async () => {
    const { history } = this.props;
    const { form } = this.state;

    this.setState({ loading: true });

    let response = null;

    if (!form.id) {
      response = await api('/shared/doc', {
        method: 'POST',
        body: form
      });
    } else {
      response = await api(`/shared/doc/${ form.id}`, {
        method: 'PUT',
        body: form
      });
    }

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      history.goBack();
    }
  };

  loadDataToUpdate = async docId => {
    this.setState({ loading: true });

    const body = await api('/shared/doc', {
      body: { id: docId }
    });

    const fileList = [];
    if (body[0].fileId) {
      const { file } = body[0];

      fileList[0] = {
        uid: file.recordedName,
        name: file.name,
        status: 'done',
        url: `${showFileUrl}/${file.recordedName}`
      };
    }

    this.setState({
      fileList,
      form: {
        ...body[0]
      },
      loading: false
    });
  };

  loadDocType = async () => {
    this.setState({ loading: true });

    const personType = await this.getPersonType();

    const body = await api('/shared/doc-type', {
      body: { personType }
    });

    this.setState({
      dataDocType: body,
      loading: false
    });
  };

  /**
   * Select person data to identify if is legal person or physical person
   *
   * @returns {Promise.<string>}
   */
  getPersonType = async () => {
    const { form } = this.state;
    this.setState({ loading: true });

    const body = await api('/shared/person', {
      body: { id: form.personId }
    });

    this.setState({ loading: false });

    return body[0].type;
  };

  handleChangeFileUpload = ({ fileList }) => {
    const { form } = this.state;
    const list = fileList.slice(-1); // Just one file per document

    const updatedList = list.map(f => {
      const file = f;
      if (file.response) {
        const r = file.response.fileList[0];

        file.url = `${showFileUrl}/${r.recordedName}`;
        this.setState({
          form: { ...form, fileId: r.id }
        });
      }

      return file;
    });

    this.setState({ fileList: updatedList });
  };

  handleOnRemoveFileUpload = () => {
    const { form } = this.state;
    this.setState({ form: { ...form, fileId: null } });
  };

  render() {
    const { history } = this.props;
    const {
      form, fileList, dataDocType, loading
    } = this.state;

    return (
      <div>
        <DocForm
          onStore={this.handleStore}
          onCancel={() => history.goBack()}
          onChangeFileUpload={this.handleChangeFileUpload}
          onRemoveFileUpload={this.handleOnRemoveFileUpload}
          setStateForm={value => this.setState({
            form: { ...form, ...value }
          })}
          getStateForm={form}
          getFileList={fileList}
          getDataDocType={dataDocType}
          loading={loading}
        />
      </div>
    );
  }
}

export default DocFormPage;
