import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Table, DatePicker } from 'antd';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { useGet } from 'common/api';
import { useDebt } from 'modules/debt/_contexts/DebtContext';
import { DEBT_DEBT_DASHBOARD } from 'modules/debt/_constants/cacheKeys';

const { RangePicker } = DatePicker;

const TotalsByYear = () => {
  const { formatMessage, formatNumber } = useIntl();
  const { debtId } = useParams();
  const debt = useDebt();
  const currencySymbol = debt?.currency?.symbol;
  const currentYear = new Date().getFullYear();

  const [dateRange, setDateRange] = useState([currentYear, currentYear + 10]);

  const { data, isLoading } = useGet([DEBT_DEBT_DASHBOARD, debtId, 'totalsByYear', dateRange], '/debt/debt-dashboard/totals-by-year', {
    params: {
      id: debtId,
      yearStart: dateRange[0],
      yearEnd: dateRange[1]
    }
  });

  const formatData = totals => {
    if (!totals) return null;

    // filter by scope = 'i' only installments amounts ??????

    const sum = totals.reduce((acc, { year, amortize, total }) => {
      const prev = acc[year] || { amortization: 0, other: 0 };

      return {
        ...acc,
        [year]: {
          amortization: amortize ? prev.amortization + total : prev.amortization,
          other: amortize ? prev.other : prev.other + total
        }
      };
    }, {});

    return Object.entries(sum).map(([year, amounts]) => ({ year, ...amounts }));
  };

  const formatCurrency = value => (currencySymbol ? (
    formatNumber(value || 0, {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: currencySymbol
    })
  ) : 0);

  const tableColumns = () => [
    {
      title: formatMessage({ id: 'year' }),
      dataIndex: 'year',
      key: 'year'
    },
    {
      title: formatMessage({ id: 'amortization' }),
      render: ({ amortization }) => formatCurrency(amortization),
      key: 'amortization',
      width: '25%',
      align: 'right',
    },
    {
      title: formatMessage({ id: 'other' }, { plural: true }),
      render: ({ other }) => formatCurrency(other),
      key: 'other',
      width: '25%',
      align: 'right',
    },
    {
      title: 'Total',
      render: ({ amortization, other }) => formatCurrency(amortization + other),
      key: 'total',
      width: '25%',
      align: 'right',
    }
  ];

  return (
    <>
      <RangePicker
        picker="year"
        defaultValue={[moment(currentYear, 'YYYY'), moment(currentYear + 10, 'YYYY')]}
        onChange={(_, dates) => setDateRange(dates)}
      />
      <Table
        style={{ marginTop: 6 }}
        dataSource={formatData(data)}
        rowKey="year"
        columns={tableColumns()}
        size="middle"
        loading={isLoading}
        pagination={false}
      />
    </>
  );
};

export default TotalsByYear;
