import {
  RightSquareOutlined,
  DoubleRightOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  QuestionCircleOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  WarningOutlined,
  IssuesCloseOutlined,
  StopOutlined,
  FormOutlined,
  UndoOutlined,
  FundOutlined,
  LockOutlined,
  DeleteOutlined,
  SearchOutlined,
  SettingOutlined,
  BulbOutlined,
  DashboardOutlined,
  FireOutlined,
  FileDoneOutlined,
  ProfileOutlined
} from '@ant-design/icons';

// eslint-disable-next-line import/prefer-default-export
export const iconList = {
  'right-square': RightSquareOutlined,
  'double-right': DoubleRightOutlined,
  'play-circle': PlayCircleOutlined,
  'pause-circle': PauseCircleOutlined,
  'question-circle': QuestionCircleOutlined,
  'check-square': CheckSquareOutlined,
  'clock-circle': ClockCircleOutlined,
  warning: WarningOutlined,
  'issues-close': IssuesCloseOutlined,
  stop: StopOutlined,
  form: FormOutlined,
  undo: UndoOutlined,
  fund: FundOutlined,
  lock: LockOutlined,
  delete: DeleteOutlined,
  search: SearchOutlined,
  setting: SettingOutlined,
  bulb: BulbOutlined,
  dashboard: DashboardOutlined,
  fire: FireOutlined,
  'file-done': FileDoneOutlined,
  profile: ProfileOutlined
};
