import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';
import { injectIntl } from 'react-intl';

import PersonForm from './PersonForm';

class PersonFormContainer extends Component {
  state = {
    form: {
      id: null,
      type: null,
      docNumber: null,
      name: null,

      physicalPerson: {
        gender: null,
        bornOn: null
      },

      legalPerson: {
        tradingName: null,
        initials: null,
        entityTypeId: null,
        admSphereId: null
      }
    },
    dataEntityType: [],
    dataAdmSphere: [],
    loading: false
  };

  componentDidMount() {
    const { match } = this.props;
    const { personId } = match.params;
    const { form } = this.state;

    this.loadEntityType();
    this.loadAdmSphere();

    if (personId) {
      this.setState({ form: { ...form, id: personId } });

      this.loadDataToUpdate(personId);
    }
  }

  handleStore = async () => {
    const { history, onFinished } = this.props;
    const { form } = this.state;

    this.setState({ loading: true });

    let response = null;

    if (!form.id) {
      response = await api('/shared/person', {
        method: 'POST',
        body: form
      });
    } else {
      response = await api(`/shared/person/${ form.id}`, {
        method: 'PUT',
        body: form
      });
    }

    const body = await response.json();
    this.setState({ loading: false });
    responseHandler(response, body);

    if (response.ok && !onFinished) {
      history.replace(
        `/shared/person/${body.data.id}/details/edit`
      );
    }

    if (response.ok && onFinished) {
      onFinished();
    }
  };

  loadDataToUpdate = async personId => {
    const { form } = this.state;

    this.setState({ loading: true });

    const body = await api('/shared/person', {
      body: { id: personId }
    });

    const {
      id, type, docNumber, name
    } = body[0];
    const obj = {
      id,
      type,
      docNumber,
      name,
      physicalPerson: form.physicalPerson,
      legalPerson: form.legalPerson
    };

    if (body[0].physicalPerson) {
      const {
        physicalPerson: { gender, bornOn }
      } = body[0];
      obj.physicalPerson = { gender, bornOn };
    }
    if (body[0].legalPerson) {
      const {
        legalPerson: {
          entityTypeId, admSphereId, tradingName, initials
        }
      } = body[0];

      obj.legalPerson = {
        entityTypeId,
        admSphereId,
        tradingName,
        initials
      };
    }

    this.setState({
      form: obj,
      loading: false
    });
  };

  loadEntityType = async () => {
    const body = await api('/shared/entity-type');

    this.setState({ dataEntityType: body });
  };

  loadAdmSphere = async () => {
    const body = await api('/shared/adm-sphere');

    this.setState({ dataAdmSphere: body });
  };

  render() {
    const {
      form, dataEntityType, dataAdmSphere, loading
    } = this.state;

    return (
      <PersonForm
        onStore={this.handleStore}
        setStateForm={value => this.setState({ form: { ...form, ...value } })}
        getStateForm={form}
        dataEntityType={dataEntityType}
        dataAdmSphere={dataAdmSphere}
        loading={loading}
      />
    );
  }
}

export default injectIntl(withRouter(PersonFormContainer));
