import React from 'react';
import { Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import ProjectSearchAutocomplete from 'modules/project/project/components/ProjectSearchAutocomplete';
import TotalsCards from './TotalsCards';
import TotalByDueDate from './TotalByDueDate';
import TotalsByYear from './TotalsByYear';

const Dashboard = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Row align="middle">
        <Col flex="68px">
          <SearchOutlined style={{ lineHeight: 'normal', color: '#CCCCCC', fontSize: '68px' }} />
        </Col>
        <Col flex="auto">
          <ProjectSearchAutocomplete selectedLink={id => `${id}/list`} size="large" />
        </Col>
      </Row>

      <TotalsCards />

      <h5>{formatMessage({ id: 'due_date' }, { plural: true })}</h5>
      <TotalByDueDate />

      <h5>{formatMessage({ id: 'annual_amounts' })}</h5>
      <TotalsByYear />
    </>
  );
};

export default Dashboard;
