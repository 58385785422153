import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Form, Input, InputNumber, DatePicker, Tooltip, Spin
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import { usePost } from 'common/api';
import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import CalculationSelectableTree from 'modules/debt/calculation/components/CalculationSelectableTree';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

import { DEBT_INSTALLMENT_LIST, DEBT_DEBT } from 'modules/debt/_constants/cacheKeys';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

const InstallmentAutoFormContainer = () => {
  const intl = useIntl();
  const history = useHistory();
  const { debtId } = useParams();
  const [form, setForm] = useState({
    debtId,
    startsOn: null,
    endsOn: null,
    initialNumber: null,
    closingDay: null,
    calculationFrequency: null,
    paymentFrequency: null,
    note: null,
    calculationList: []
  });

  const options = {
    fetch: {
      body: {
        ...form,
        startsOn: form.startsOn?.format('YYYY-MM-DD'),
        endsOn: form.endsOn?.format('YYYY-MM-DD')
      }
    },
    onSuccess: history.goBack,
    cacheInvalidate: [
      [DEBT_INSTALLMENT_LIST, debtId],
      [DEBT_DEBT, debtId]
    ]
  };
  const { mutate, isLoading } = usePost(() => '/debt/installment/create-auto', options);

  const setFormData = value => setForm({ ...form, ...value });

  return (
    <Spin spinning={isLoading}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'period' })}
        >
          <RangePicker
            showTime={false}
            format={intl.formatMessage({
              id: 'date_format'
            })}
            onChange={range => {
              setFormData({
                startsOn: range[0] ? range[0] : null,
                endsOn: range[1] ? range[1] : null
              });
            }}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({
            id: 'initial_number'
          })}
        >
          <InputNumber
            onChange={initialNumber => setFormData({ initialNumber })}
            min={1}
            max={9999}
            style={{ width: 80 }}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({
            id: 'closing_day'
          })}
        >
          <InputNumber
            onChange={closingDay => setFormData({ closingDay })}
            min={1}
            max={31}
            style={{ width: 80 }}
          />
          <Tooltip title={<FormattedMessage id="last_day_month" />}>
            <QuestionCircleOutlined
              className="icon-help"
              style={{ marginLeft: 4 }}
            />
          </Tooltip>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage(
            {
              id: 'calculation'
            },
            { plural: false }
          )}
        >
          <InputNumber
            onChange={calculationFrequency => setFormData({ calculationFrequency })}
            min={1}
            max={12}
            style={{ width: 80, marginRight: 4 }}
          />
          <small>
            (
            <FormattedMessage id="frequency" />
            .
            {' '}
            <FormattedMessage id="in_months" />
            )
          </small>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'payment' }, { plural: false })}
        >
          <InputNumber
            onChange={paymentFrequency => setFormData({ paymentFrequency })}
            min={1}
            max={12}
            style={{ width: 80, marginRight: 4 }}
          />
          <small>
            (
            <FormattedMessage id="frequency" />
            .
            {' '}
            <FormattedMessage id="in_months" />
            )
          </small>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'note' })}
        >
          <Input
            maxLength={120}
            onChange={e => setFormData({ note: e.target.value })}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage(
            {
              id: 'calculation'
            },
            { plural: true }
          )}
        >
          <div
            style={{
              backgroundColor: '#fff',
              borderRadius: '4px',
              marginBottom: '5px'
            }}
          >
            <CalculationSelectableTree
              debtId={debtId}
              onCheck={calculationList => setFormData({ calculationList })}
              checkedKeys={form.calculationList}
            />
          </div>
        </FormItem>

        <FormSubmitButtons
          onStore={mutate}
          onCancel={history.goBack}
        />
      </Form>
    </Spin>
  );
};

export default InstallmentAutoFormContainer;
