import React, { useState } from 'react';
import { Button, Space, Select } from 'antd';
import { useIntl } from 'react-intl';
import { DownloadOutlined } from '@ant-design/icons';

import { usePost } from 'common/api';
import { showCsvUrl } from 'config/app';

import DataExportFilters from '../components/DataExportFilters';

const DataExport = () => {
  const [form, setForm] = useState({
    personId: null,
    status: null,
    typeId: null,
    recordType: null,
    currencyId: null,
    period: [],
    collection: null
  });
  const { formatMessage } = useIntl();
  const [selected, setSelected] = useState({ description: null, filterDisabled: true });
  const [type, setType] = useState('-realized');

  const collectionsList = {
    '': ['Selecione...', null, true],
    full: ['Parcelamento Detalhado', 'Inclui todas as parcelas e informações correspondentes.', false],
    foundedDebt: [
      'Dívida Fundada',
      'Resumo das dívidas, considerando a evolução do Saldo Devedor ao final do exercício anterior, desembolsos realizados, amortizações e outras itens pagos no exercício e posição atual.',
      true
    ],
    monthlyDebt: [
      'Dívida Mensal',
      'Resumo mensal das dívidas, subdividido por amortização, juros, taxas, correção monetária e ajustes; e saldo devedor no referido mês.',
      true
    ],
    yearlyDebt: [
      'Dívida Anual',
      'Resumo anual das dívidas, a partir do exercício anterior, mais quatro exercícios posteriores e a soma dos demais exercícios, subdividido por amortização, juros, taxas, correção monetária e ajustes; e saldo devedor no referido ano, ou na soma dos exercícios futuros.',
      true
    ],
  };

  const handleSetForm = v => setForm({ ...form, ...v });

  const handleSelectedOption = collection => {
    setForm({ ...form, collection });

    const [, description, filterDisabled] = Object.entries(collectionsList)?.find(c => c[0] === collection)?.[1];
    setSelected({ description, filterDisabled });
  };

  const handleSelectedType = v => {
    setType(v === 'realized' ? '-realized' : '');
  };

  const optionsGetCSV = {
    onSuccess: fileName => {
      window.open(`${showCsvUrl}/${fileName}`, '_blank');
    },
    fetch: { body: { ...form } }
  };
  const { mutate, isLoading } = usePost(() => `/debt/data-export${type}/csv`, optionsGetCSV);

  const optionsTypeList = [
    { value: 'realized', label: formatMessage({ id: 'realized' }) },
    { value: 'planned', label: formatMessage({ id: 'planned' }) }
  ];

  const optionsList = Object.entries(collectionsList).map(([value, detail]) => ({ value, label: detail[0] }));

  const renderContent = () => {
    if (!form.collection) { return null; }

    return (
      <div className="white-box">
        <p>{selected.description}</p>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={() => mutate()}
          loading={isLoading}
        >
          Download
        </Button>
      </div>
    );
  };

  return (
    <Space direction="vertical" size={16} style={{ width: '100%' }}>
      <Select
        options={optionsTypeList}
        style={{ minWidth: '942px' }}
        onChange={handleSelectedType}
        defaultValue="realized"
        size="large"
      />
      <Select
        options={optionsList}
        style={{ minWidth: '942px' }}
        onChange={handleSelectedOption}
        defaultValue=""
        size="large"
      />

      <DataExportFilters setState={handleSetForm} disabled={selected.filterDisabled} />

      {renderContent()}
    </Space>
  );
};

export default DataExport;
