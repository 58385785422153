import React from 'react';
import { Descriptions } from 'antd';
import { useIntl } from 'react-intl';

import { useDebt } from 'modules/debt/_contexts/DebtContext';

import DebtStatusStatic from 'modules/debt/debt/static/DebtStatusStatic';
import DebtRecordTypeStatic from 'modules/debt/debt/static/DebtRecordTypeStatic';

import './styles.css';

const { Item } = Descriptions;

const ContractData = () => {
  const { formatMessage, formatNumber } = useIntl();
  const debt = useDebt();
  const currencySymbol = debt?.currency?.symbol;

  const formatCurrency = value => (currencySymbol ? (
    formatNumber(value || 0, {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: currencySymbol
    })
  ) : 0);

  return (
    <Descriptions
      bordered
      size="small"
      column={1}
      style={{ padding: 16, backgroundColor: 'white' }}
      labelStyle={{ textAlign: 'end', width: '40%' }}
    >
      <Item label={formatMessage({ id: 'amount' })}>
        {formatCurrency(debt?.value)}
      </Item>
      <Item label="Status">
        {formatMessage({ id: DebtStatusStatic[debt.status || 'p'] })}
      </Item>
      <Item label={formatMessage({ id: 'installment' }, { plural: true })}>
        {debt?.installmentQuant}
      </Item>
      <Item label={formatMessage({ id: 'due_day' })}>
        {debt?.dueDay}
      </Item>
      <Item label={formatMessage({ id: 'business_day_payment' })}>
        {formatMessage({ id: debt?.businessDay ? 'yes' : 'no' })}
      </Item>
      <Item label={formatMessage({ id: 'type' })}>
        {debt?.type?.name}
      </Item>
      <Item label={formatMessage({ id: 'record_type' })}>
        {debt.recordType && formatMessage({ id: DebtRecordTypeStatic[debt?.recordType] }, { plural: false })}
      </Item>
    </Descriptions>
  );
};

export default ContractData;
