import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';

import { useConfig } from 'core/_contexts/ConfigContext';

import ptBR from 'antd/lib/locale-provider/pt_BR';
import enUS from 'antd/lib/locale-provider/en_US';

import enTranslations from '../translations/en.json';
import ptTranslations from '../translations/pt-br.json';

import 'moment/locale/pt';

const messages = {
  en: enTranslations,
  pt: ptTranslations
};

const LocalizationProvider = ({ children }) => {
  const { locale } = useConfig();

  return (
    <ConfigProvider locale={locale === 'pt' ? ptBR : enUS}>
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  );
};

export default LocalizationProvider;
