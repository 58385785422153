import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Input, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, usePost, usePut } from 'common/api';

import { PROJECT_FOLDER, PROJECT_FOLDER_LIST } from 'modules/project/_constants/cacheKeys';

const FormItem = Form.Item;

const FolderForm = props => {
  const { folderId, parentId, projectId } = useParams();
  const { goBack } = useHistory();
  const [form, setForm] = useState({
    id: folderId,
    parentId,
    projectId,
    name: null
  });

  const { data: parentData, isLoading: isLoadingParent } = useGet([PROJECT_FOLDER, parentId], '/project/folder', {
    params: { id: parentId },
    query: { enabled: Boolean(parentId) }
  });

  const { data, isLoading } = useGet([PROJECT_FOLDER, folderId], '/project/folder', {
    params: { id: folderId },
    query: { enabled: Boolean(folderId) }
  });
  useEffect(() => {
    if (data) setForm(data[0]);
  }, [data]);

  const options = {
    fetch: { body: form },
    onSuccess: goBack,
    cacheInvalidate: [
      [PROJECT_FOLDER_LIST, projectId],
      folderId && [PROJECT_FOLDER, folderId]
    ]
  };
  const { mutate, isLoading: isLoadingMutate } = usePost(() => '/project/folder', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/project/folder/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const renderParent = () => {
    if (parentId) {
      return (
        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="parent" />}
        >
          <b>{parentData && parentData[0].name}</b>
        </FormItem>
      );
    }

    return null;
  };

  return (
    <Spin spinning={isLoading || isLoadingParent || isLoadingMutate || isLoadingUpdate}>
      <Form className="soft-box">
        {renderParent(props)}

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="name" />}
        >
          <Input
            onChange={e => setFormData({ name: e.target.value })}
            value={form.name}
            maxLength={50}
            className="uppercase"
            autoFocus
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!folderId ? mutate() : mutateUpdate(folderId))}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default FolderForm;
