import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form, Input, DatePicker, Select, Spin, Switch
} from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';

import { useGet, usePost, usePut } from 'common/api';
import { normalize, formatInput } from 'common/utils/number';
import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useDebt } from 'modules/debt/_contexts/DebtContext';

import CurrencyAmountField from 'modules/shared/currency/components/CurrencyAmountField';

import {
  DEBT_OPERATION,
  DEBT_OPERATION_LIST,
  DEBT_DEBT_DASHBOARD
} from 'modules/debt/_constants/cacheKeys';
import IncludeStatic from '../static/IncludeStatic';

const FormItem = Form.Item;
const { Option } = Select;

const OperationFormContainer = () => {
  const { debtId, operationId } = useParams();
  const { goBack } = useHistory();
  const intl = useIntl();
  const debt = useDebt();
  const [form, setForm] = useState({
    id: null,
    debtId,
    currencyId: null,
    executedOn: null,
    value: null,
    valueExecuted: null,
    include: '',
    description: '',
    otherCurrency: false,
    realized: false
  });

  const cacheKey = [DEBT_OPERATION, operationId];
  const { data, isLoading: isLoadingData } = useGet(cacheKey, '/debt/operation', {
    params: { id: operationId },
    query: { enabled: Boolean(operationId) }
  });
  useEffect(() => {
    if (data) {
      const d = data[0];
      setForm({
        ...d,
        value: formatInput(d.value, { decimals: 2, toNormalize: true }),
        valueExecuted: formatInput(d.valueExecuted, { decimals: 2, toNormalize: true }),
        executedOn: moment(d.executedOn),
        otherCurrency: d.currencyId !== debt.currencyId
      });
    }
  }, [data]);

  const options = {
    fetch: {
      body: {
        ...form,
        executedOn: form.executedOn?.format('YYYY-MM-DD'), // remove timezone
        currencyId: form.otherCurrency ? form.currencyId : debt.currencyId,
        value: normalize(form.value, { decimals: 2 }),
        valueExecuted: form.otherCurrency ? normalize(form.valueExecuted, { decimals: 2 }) : normalize(form.value, { decimals: 2 })
      }
    },
    onSuccess: goBack,
    cacheInvalidate: [
      [DEBT_OPERATION_LIST, debtId],
      [DEBT_DEBT_DASHBOARD, debtId],
      operationId && [DEBT_OPERATION, operationId]
    ]
  };
  const { mutate, isLoading } = usePost(() => '/debt/operation', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/debt/operation/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const includeOptions = () => Object.entries(IncludeStatic.list).map(([key, value]) => (
    <Option key={key} value={`${key}`}>
      <FormattedMessage id={value} values={{ plural: false }} />
    </Option>
  ));

  return (
    <Spin spinning={isLoading || isLoadingData || isLoadingUpdate}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'execution' })}
        >
          <DatePicker
            format={intl.formatMessage({
              id: 'date_format'
            })}
            onChange={date => setFormData({
              executedOn: date || null
            })}
            value={form.executedOn || null}
            placeholder={intl.formatMessage({
              id: 'date_placeholder'
            })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'value' }, { plural: false })}
        >
          <Input
            maxLength={20}
            onChange={e => setFormData({ value: formatInput(e.target.value, { decimals: 2 }) })}
            value={form.value}
            style={{ width: 270 }}
            addonBefore={<div style={{ width: 68 }}>{debt?.currency?.symbol}</div>}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'executed_other_currency' })}
        >
          <Switch
            checked={form.otherCurrency}
            onChange={otherCurrency => setFormData({ otherCurrency })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'value' }, { plural: false })}
          hidden={!form.otherCurrency}
        >
          <CurrencyAmountField
            maxLength={20}
            onChange={e => setFormData({ valueExecuted: formatInput(e.target.value, { decimals: 2 }) })}
            value={form.valueExecuted}
            selectedCurrency={form.currencyId}
            style={{ width: 270 }}
            onSelect={v => setFormData({ currencyId: v })}
            removeId={debt?.currencyId}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'include' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={include => setFormData({ include })}
            value={form.include ? `${form.include}` : ''}
          >
            {includeOptions()}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <Input
            maxLength={120}
            onChange={e => setFormData({ description: e.target.value })}
            value={form.description}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'realized' })}
        >
          <Switch
            checked={form.realized}
            onChange={realized => setFormData({ realized })}
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!operationId ? mutate() : mutateUpdate(operationId))}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default OperationFormContainer;
