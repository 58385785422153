import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Button, Tooltip, Menu, Dropdown, Modal
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

const { confirm } = Modal;

const AddressList = props => {
  const {
    onGoToEdit, onDestroy, intl, onGoToAdd, data, loading
  } = props;

  const menuActions = record => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => onGoToEdit(record.id)}
          >
            <FormattedMessage id="edit" />
          </Menu.Item>

          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.inactivate_item' }),
                onOk: () => onDestroy(record.id)
              });
            }}
          >
            <FormattedMessage id="inactivate" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      dataIndex: 'title',
      key: 'title',
      width: '15%'
    },
    {
      dataIndex: 'zip_code',
      key: 'zip_code',
      width: '10%'
    },
    {
      render: (text, record) => `${record.address}${
          record.number !== null ? `, ${ record.number}` : ''
        }. ${record.complement !== null ? `${record.complement }. ` : ''} ${
          record.district !== null ? record.district : ''
        }`,
      key: 'address',
      width: '57%'
    },
    {
      render: (text, record) => `${record.city} - ${record.state}`,
      key: 'city',
      width: '15%'
    },
    {
      key: 'actions',
      width: '3%',
      align: 'right',
      render: (text, record) => menuActions(record)
    }
  ];

  return (
    <div>
      <Tooltip title={<FormattedMessage id="add" />}>
        <Button
          onClick={onGoToAdd}
          icon={<PlusOutlined />}
          style={{ paddingTop: '1px', marginBottom: '3px' }}
        />
      </Tooltip>

      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        size="middle"
        pagination={false}
        showHeader={false}
        loading={loading}
      />
    </div>
  );
};

AddressList.propTypes = {
  data: PropTypes.shape([]),
  onDestroy: PropTypes.func.isRequired,
  onGoToAdd: PropTypes.func.isRequired,
  onGoToEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

AddressList.defaultProps = {
  data: []
};

export default injectIntl(AddressList);
