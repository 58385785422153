import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result, Button } from 'antd';
import { useIntl } from 'react-intl';

const PageNotFound = () => {
  const intl = useIntl();
  const { replace } = useHistory();

  return (
    <Result
      status="404"
      title="404"
      subTitle={intl.formatMessage({ id: 'page_not_found' })}
      extra={(
        <Button
          type="primary"
          onClick={() => replace('/core/module/list')}
        >
          {intl.formatMessage({ id: 'back' })}
        </Button>
      )}
    />
  );
};

export default PageNotFound;
