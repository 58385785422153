import React, { useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Row, Col, Tabs } from 'antd';
import { DollarOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import { useDebt } from 'modules/debt/_contexts/DebtContext';
import { useInstallment, useInstallmentUpdate } from 'modules/debt/_contexts/InstallmentContext';
import CardIcon from 'common/components/CardIcon';
import { dateToLocale } from 'common/utils/misc';

import CompositionRoutes from 'modules/debt/_routes/CompositionRoutes';
import PaymentRoutes from 'modules/debt/_routes/PaymentRoutes';

const { TabPane } = Tabs;

const styles = {
  navigatorStyle: {
    fontSize: 22,
    color: '#999999'
  },
  detailsCardLabel: {
    marginBottom: 4,
    display: 'inline-block',
    width: '80px',
    textAlign: 'right',
    marginRight: 12
  }
};

const InstallmentDetails = () => {
  const { formatMessage } = useIntl();
  const { push } = useHistory();
  const { installmentId } = useParams();

  const installmentUpdate = useInstallmentUpdate();
  const currentDebt = useDebt();
  const data = useInstallment();

  useEffect(() => {
    installmentUpdate(installmentId);
  }, [installmentId]);

  const renderCurrency = (value, symbol) => {
    if (!symbol) {
      return null;
    }

    return (
      <FormattedNumber
        value={value || 0}
        minimumFractionDigits={0}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency={symbol}
      />
    );
  };

  const navigate = useCallback(option => () => {
    const id = option === 'next' ? data.idNext : data.idPrevious;

    if (!id) { return; }

    push(`../${id}/details`);
  }, [data]);

  return (
    <>
      <Row gutter={22}>
        <Col xs={24} lg={6}>
          <div className="stat">
            <div>
              <small className="title uppercase">
                <FormattedMessage id="number" />
              </small>
              <div className="content">
                <LeftOutlined style={styles.navigatorStyle} onClick={navigate('previous')} />
                {data ? data.number : '#'}
                <RightOutlined style={styles.navigatorStyle} onClick={navigate('next')} />
                <small className="description">
                  /
                  {currentDebt.installmentQuant}
                </small>
              </div>

              <div />
            </div>
          </div>
        </Col>

        <Col xs={24} lg={9}>
          <CardIcon
            icon={<DollarOutlined />}
            title={formatMessage({ id: 'total_value' })}
          >
            {renderCurrency(data?.compositionTotal, currentDebt.currency?.symbol)}
          </CardIcon>
        </Col>

        <Col xs={24} lg={9}>
          <div className="stat" style={{ flexDirection: 'column' }}>
            <div style={{ marginBottom: 10 }}>
              <small className="title uppercase" style={styles.detailsCardLabel}>
                {formatMessage({ id: 'period' })}
              </small>
              <span className="content" style={{ fontSize: 24 }}>
                {`${dateToLocale(data?.startsOn)} - ${dateToLocale(data?.endsOn)}`}
              </span>
            </div>
            <div>
              <small className="title uppercase" style={styles.detailsCardLabel}>
                {formatMessage({ id: 'due_date' }, { plural: false })}
              </small>
              <span className="content" style={{ fontSize: 24 }}>
                {dateToLocale(data?.dueOn)}
              </span>
            </div>
          </div>
        </Col>
      </Row>

      <Tabs defaultActiveKey="1" style={{ marginTop: 20 }}>
        <TabPane tab={<FormattedMessage id="composition" />} key="1">
          <CompositionRoutes />
        </TabPane>
        <TabPane tab={<FormattedMessage id="payment" values={{ plural: true }} />} key="2">
          <PaymentRoutes />
        </TabPane>
      </Tabs>
    </>
  );
};

export default InstallmentDetails;
