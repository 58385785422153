import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import ProfileForm from './ProfileForm';

class ProfileFormPage extends Component {
  state = {
    form: {
      id: null,
      name: null,
      blocked: false
    },

    loading: false
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.loadDataToUpdate(parseInt(id, 10));
    }
  }

  handleStore = async () => {
    const { form } = this.state;
    const { history } = this.props;

    this.setState({ loading: true });

    let response = null;

    if (!form.id) {
      response = await api('/access/profile', {
        method: 'POST',
        body: form
      });
    } else {
      response = await api(`/access/profile/${ form.id}`, {
        method: 'PUT',
        body: form
      });
    }

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      history.push('/access/profile');
    }
  };

  loadDataToUpdate = async profileId => {
    this.setState({ loading: true });

    const body = await api('/access/profile', {
      body: { id: profileId }
    });

    this.setState({
      form: { ...body[0] },
      loading: false
    });
  };

  render() {
    const { form, loading } = this.state;
    const { history } = this.props;

    return (
      <ProfileForm
        onCancel={history.goBack}
        onStore={this.handleStore}
        setStateForm={value => this.setState({
          form: { ...form, ...value }
        })}
        getStateForm={form}
        getLoading={loading}
      />
    );
  }
}

export default ProfileFormPage;
