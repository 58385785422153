import React, { Component } from 'react';
import {
  Form, DatePicker, Spin, Input
} from 'antd';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';
import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { normalize, formatInput } from 'common/utils/number';

/**
 * It needs to ignore the timezone on dates fields.
 */
class IndexValueForm extends Component {
  state = {
    form: {
      id: null,
      indexId: null,
      date: '',
      value: null
    },
    loading: false
  };

  componentDidMount() {
    const { match } = this.props;
    const { indexValueId, indexId } = match.params;
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        id: indexValueId,
        indexId,
      }
    });

    if (indexValueId) {
      this.loadDataToUpdate(indexValueId);
    }
  }

  onStore = async () => {
    const { history } = this.props;

    this.setState({ loading: true });

    const { form } = this.state;
    let response = null;

    const data = {
      ...form,
      date: form.date?.format('YYYY-MM-DD'),
      value: normalize(form.value, { decimals: 9 })
    };

    if (!form.id) {
      response = await api('/shared/index-value', {
        method: 'POST',
        body: data
      });
    } else {
      response = await api(`/shared/index-value/${form.id}`, {
        method: 'PUT',
        body: data
      });
    }

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      history.goBack();
    }
  };

  loadDataToUpdate = async indexValueId => {
    this.setState({ loading: true });

    const body = (
      await api('/shared/index-value', {
        body: { id: indexValueId }
      })
    )[0];

    this.setState({
      form: {
        ...body,
        date: moment.utc(body.date),
        value: formatInput(body.value, { toNormalize: true, decimals: 9 })
      },
      loading: false
    });
  };

  setStateForm = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, ...value } });
  };

  render() {
    const { form, loading } = this.state;
    const { intl, history } = this.props;
    const FormItem = Form.Item;

    return (
      <Spin spinning={loading}>
        <Form className="soft-box">
          <FormItem
            {...defaultFormItemLayout}
            label={intl.formatMessage({ id: 'date' })}
          >
            <DatePicker
              format={`${intl.formatMessage({
                id: 'date_format'
              })}`}
              onChange={date => this.setStateForm({
                date: date || null
              })}
              value={form.date ? form.date : null}
            />
          </FormItem>

          <FormItem
            {...defaultFormItemLayout}
            label={intl.formatMessage({ id: 'value' }, { plural: false })}
          >
            <Input
              maxLength={20}
              onChange={e => this.setStateForm({ value: formatInput(e.target.value, { decimals: 9 }) })}
              value={form.value}
              style={{ width: 200 }}
            />
          </FormItem>

          <FormSubmitButtons
            onStore={this.onStore}
            onCancel={history.goBack}
          />
        </Form>
      </Spin>
    );
  }
}

export default injectIntl(IndexValueForm);
