import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, useDelete } from 'common/api';

import { PROJECT_FOLDER, PROJECT_FOLDER_LIST } from 'modules/project/_constants/cacheKeys';

import FolderList from './FolderList';

const FormItem = Form.Item;

const FolderDestroy = () => {
  const { folderId, projectId } = useParams();
  const { goBack } = useHistory();
  const [destFolderId, setDestFolderId] = useState('');

  const { data, isLoading } = useGet([PROJECT_FOLDER, folderId], '/project/folder', { params: { id: folderId } });

  const options = {
    onSuccess: goBack,
    cacheInvalidate: [
      [PROJECT_FOLDER_LIST, projectId],
    ],
    cacheRemove: [
      [PROJECT_FOLDER, folderId]
    ]
  };
  const { mutate, isLoading: isLoadingDelete } = useDelete(() => `/project/folder/${folderId}/${destFolderId}`, options);

  return (
    <Spin spinning={isLoading || isLoadingDelete}>
      <Form className="soft-box">
        <p>
          <FormattedMessage id="message.delete_move" />
          :
        </p>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="delete" />}
        >
          <b>{data && data[0].name}</b>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="destination" />}
        >
          <FolderList
            onSelect={setDestFolderId}
            hideButtons
            removeId={folderId}
          />
        </FormItem>

        <FormSubmitButtons
          onStore={mutate}
          onCancel={goBack}
          storeMessage="delete"
        />
      </Form>
    </Spin>
  );
};

export default FolderDestroy;
