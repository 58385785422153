import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Tooltip, Avatar, Button, Menu, Dropdown, Spin
} from 'antd';
import { MoreOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { showFileUrl } from 'config/app';
import { useGet } from 'common/api';

import { PROJECT_PROJECT_STATUS_LIST } from 'modules/project/_constants/cacheKeys';

import { iconList } from '../../status/static/IconListStatic';

const ProjectStatusList = () => {
  const { projectId } = useParams();
  const { push } = useHistory();

  const { data, isLoading } = useGet([PROJECT_PROJECT_STATUS_LIST, projectId], '/project/project-status', { params: { projectId } });

  const menuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => { push(`project-status/${id}/edit`); }}
          >
            <FormattedMessage id="edit" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  return (
    <Spin spinning={isLoading}>
      <Tooltip title={<FormattedMessage id="add" />}>
        <Button
          onClick={() => push('project-status/add')}
          icon={<PlusOutlined />}
          style={{ marginBottom: 10 }}
        />
      </Tooltip>

      <ul
        className="card-with-avatar"
        style={{ marginTop: 0, backgroundColor: 'transparent' }}
      >
        {data?.map(r => {
          const StatusIcon = iconList[r.status.icon];

          return (
            <li key={r.id}>
              <Tooltip title={r.user.person.name}>
                <Avatar
                  icon={<UserOutlined />}
                  shape="square"
                  style={{
                    float: 'left',
                    marginTop: 10
                  }}
                  src={
                    r.user.file
                      ? `${showFileUrl}/${r.user.file.recordedName}`
                      : null
                  }
                />
              </Tooltip>

              <div className="card-with-avatar-content">
                <div style={{ float: 'right', top: 0 }}>
                  {menuActions(r.id)}
                </div>

                <div style={{ lineHeight: 1, marginBottom: 4 }}>
                  <StatusIcon
                    style={{
                      color: r.status.color,
                      opacity: 0.4,
                      fontSize: 26
                    }}
                  />
                  <span
                    style={{
                      fontSize: 26,
                      fontWeight: 200,
                      color: r.status.color,
                      marginLeft: 4
                    }}
                  >
                    {r.status.name}
                  </span>
                </div>

                <div>{r.note}</div>

                <small style={{ color: '#999' }}>
                  {moment.utc(r.createdAt).fromNow()}
                </small>
              </div>
            </li>
          );
        })}
      </ul>
    </Spin>
  );
};

export default ProjectStatusList;
