import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import { api } from 'config/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

const { Option } = Select;

const UserSelect = props => {
  const {
    onSelect, style, initialLabel, placeholder
  } = props;
  const { id: organizationId } = useOrganization();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  let debounceTimeout = 0;

  const searchUser = async inputValue => {
    if (inputValue.length <= 2) {
      return;
    }

    setLoading(true);
    const result = await api(`/access/rule/find-user-by-organization/${organizationId}`);

    setData(result);
    setLoading(false);
  };

  const searchDebounce = inputValue => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(searchUser, 500, inputValue);
  };

  return (
    <>
      <Select
        placeholder={
          placeholder
          || (
            <span>
              <FormattedMessage id="find" />
              {' '}
              <FormattedMessage id="user" values={{ plural: false }} />
            </span>
          )
        }
        notFoundContent={loading ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={searchDebounce}
        onChange={onSelect}
        showSearch
        allowClear
        style={style}
        value={initialLabel || undefined}
      >
        {data.map(d => (
          <Option key={d.userId}>{d.personName}</Option>
        ))}
      </Select>
    </>
  );
};

UserSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  initialLabel: PropTypes.string,
  placeholder: PropTypes.string
};

UserSelect.defaultProps = {
  style: {},
  initialLabel: null,
  placeholder: null
};

export default UserSelect;
