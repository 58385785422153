import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CompositionList from 'modules/debt/composition/pages/CompositionList';
import CompositionForm from 'modules/debt/composition/pages/CompositionForm';

const CompositionRoutes = () => (
  <Switch>
    <Route
      path="/debt/:projectId/:debtId/installment/:installmentId/details/composition/add"
      component={CompositionForm}
    />
    <Route
      path="/debt/:projectId/:debtId/installment/:installmentId/details/composition/:compositionId/edit"
      component={CompositionForm}
    />
    <Route
      path="/debt/:projectId/:debtId/installment/:installmentId/details"
      component={CompositionList}
    />
  </Switch>
);

export default CompositionRoutes;
