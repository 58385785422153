import { useMutation, useQueryClient } from 'react-query';

import { useOrganization } from 'core/_contexts/OrganizationContext';
import { useConfig } from 'core/_contexts/ConfigContext';
import responseHandler from 'common/helpers/responseHandler';

import { getApiUrl, getDefaultFetchOptions } from '../apiUtils';

const fetchData = async options => {
  const {
    method, url, organization, fetchOptions, locale, notify
  } = options;

  const apiUrl = getApiUrl({ url, organization });

  const httpOptions = {
    ...getDefaultFetchOptions({ locale }),
    ...fetchOptions,
    body: fetchOptions?.body ? JSON.stringify(fetchOptions.body) : '',
    method,
    credentials: 'include',
  };

  const res = await fetch(apiUrl, httpOptions);
  const body = await res.json();

  return responseHandler(res, body, { notify });
};

export default method => (getUrl, options) => {
  const queryClient = useQueryClient();
  const organization = useOrganization();
  const { locale } = useConfig();

  return useMutation(id => fetchData({
    method,
    url: getUrl(id),
    organization,
    locale,
    fetchOptions: options?.fetch,
    notify: options?.notify ?? true
  }), {
    onSuccess: data => {
      if (data === 'error') return;

      const {
        cacheInvalidate,
        cacheInvalidateOptions = {},
        cacheUpdate,
        cacheRemove
      } = options;

      if (cacheInvalidate) {
        cacheInvalidate.forEach(key => {
          queryClient.invalidateQueries(key, { refetchInactive: true, ...cacheInvalidateOptions });
        });
      }

      if (cacheUpdate) {
        cacheUpdate.forEach(key => {
          queryClient.setQueryData(key, data);
        });
      }

      if (cacheRemove) {
        cacheRemove.forEach(key => {
          queryClient.removeQueries(key);
        });
      }

      if (options.onSuccess) {
        options.onSuccess(data);
      }
    }
  });
};
