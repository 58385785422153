import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Form, Input, DatePicker, Select, Spin
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, usePost, usePut } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import { PROJECT_LIST, PROJECT_PROJECT, PROJECT_TAG_LIST } from 'modules/project/_constants/cacheKeys';

const { Option } = Select;
const FormItem = Form.Item;
const { TextArea } = Input;

const ProjectForm = () => {
  const intl = useIntl();
  const { goBack, push } = useHistory();

  const { id: organizationId } = useOrganization();
  const { projectId } = useParams();

  const [form, setForm] = useState({
    id: null,
    name: null,
    shortName: null,
    description: null,
    startOn: null,
    endOn: null,
    tag: []
  });

  const { data: tagData, isLoading: isLoadingTag } = useGet(
    [PROJECT_TAG_LIST, organizationId, 'prt'],
    '/project/tag',
    {
      params: {
        organizationId,
        ref: 'prt'
      },
      query: { enabled: Boolean(organizationId) }
    }
  );

  const { data, isLoading } = useGet([PROJECT_PROJECT, projectId], '/project/project', {
    params: { id: projectId },
    query: { enabled: Boolean(projectId) }
  });
  useEffect(() => {
    if (data) {
      const tag = data.rows[0].tag.map(v => `${v.id}`);
      setForm({
        ...data.rows[0],
        tag
      });
    }
  }, [data]);

  const options = {
    fetch: { body: form },
    onSuccess: res => push(!projectId ? `/project/${res.id}/resume` : '/project/list'),
    cacheInvalidate: [
      [PROJECT_LIST], // FIXME: set organization id on the ProjectList.js
      projectId && [PROJECT_PROJECT, projectId]
    ]
  };
  const { mutate, isLoading: isLoadingMutate } = usePost(() => '/project/project', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/project/project/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const tagOptions = tagData?.map(v => (
    <Option key={`${v.id}`} value={`${v.id}`}>
      {v.name}
    </Option>
  ));

  return (
    <Spin spinning={isLoading || isLoadingMutate || isLoadingUpdate}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="name" />}
        >
          <TextArea
            autoFocus
            maxLength={749}
            rows="2"
            onChange={e => setFormData({ name: e.target.value })}
            value={form.name}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <TextArea
            rows="4"
            onChange={e => setFormData({ description: e.target.value })}
            value={form.description}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'initials' })}
        >
          <Input
            maxLength={14}
            onChange={e => setFormData({ shortName: e.target.value })}
            value={form.shortName}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="start_date" />}
        >
          <DatePicker
            format={intl.formatMessage({
              id: 'date_format'
            })}
            onChange={date => setFormData({
              startOn: date || null
            })}
            value={form.startOn ? moment(form.startOn) : null}
            placeholder={intl.formatMessage({ id: 'date_placeholder' })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="end_date" />}
        >
          <DatePicker
            format={intl.formatMessage({
              id: 'date_format'
            })}
            onChange={date => setFormData({ endOn: date || null })}
            value={form.endOn ? moment(form.endOn) : null}
            placeholder={intl.formatMessage({
              id: 'date_placeholder'
            })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="tag" values={{ plural: true }} />}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            onChange={tag => setFormData({ tag })}
            value={form.tag}
            loading={isLoadingTag}
          >
            {tagOptions}
          </Select>
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!projectId ? mutate() : mutateUpdate(projectId))}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default ProjectForm;
