import React, { createContext, useContext, useState } from 'react';

const ModuleContext = createContext();
const ModuleUpdateContext = createContext();

export const useModule = () => useContext(ModuleContext);
export const useModuleUpdate = () => useContext(ModuleUpdateContext);

export const ModuleProvider = ({ children }) => {
  const [data, setData] = useState(null);

  return (
    <ModuleContext.Provider value={data}>
      <ModuleUpdateContext.Provider value={setData}>
        { children }
      </ModuleUpdateContext.Provider>
    </ModuleContext.Provider>
  );
};
