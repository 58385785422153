import React, { useState, useEffect } from 'react';
import {
  Form, Input, Select, Spin
} from 'antd';
import { useParams, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, usePost, usePut } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import {
  PROJECT_PROJECT,
  PROJECT_STATUS_LIST,
  PROJECT_PROJECT_STATUS,
  PROJECT_PROJECT_STATUS_LIST
} from 'modules/project/_constants/cacheKeys';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

const ProjectStatusForm = () => {
  const intl = useIntl();
  const { projectId, projectStatusId } = useParams();
  const { goBack } = useHistory();

  const { id: organizationId } = useOrganization();

  const [form, setForm] = useState({
    id: null,
    projectId,
    statusId: null,
    note: null
  });

  const { data: statusData, isLoading: isLoadingStatus } = useGet([PROJECT_STATUS_LIST, organizationId], '/project/status', {
    params: { projectId }
  });

  const cacheKey = [PROJECT_PROJECT_STATUS, projectStatusId];
  const { data, isLoading } = useGet(cacheKey, '/project/project-status', {
    params: { id: projectStatusId },
    query: { enabled: Boolean(projectStatusId) }
  });
  useEffect(() => {
    if (data) setForm(data[0]);
  }, [data]);

  const options = {
    fetch: { body: form },
    onSuccess: goBack,
    cacheInvalidate: [
      [PROJECT_PROJECT_STATUS_LIST, projectId],
      [PROJECT_PROJECT, projectId],
      projectStatusId && [PROJECT_PROJECT_STATUS, projectStatusId]
    ]
  };
  const { mutate, isLoading: isLoadingMutate } = usePost(() => '/project/project-status', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/project/project-status/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const statusOptions = statusData?.map(v => (
    <Option key={`${v.id}`} value={`${v.id}`}>
      {v.name}
    </Option>
  ));

  return (
    <Spin spinning={isLoading || isLoadingMutate || isLoadingUpdate}>
      <Form className="soft-box">
        <FormItem {...defaultFormItemLayout} label="Status">
          <Select
            style={{ width: '100%' }}
            onChange={statusId => setFormData({ statusId })}
            value={form.statusId ? `${form.statusId}` : ''}
            loading={isLoadingStatus}
          >
            {statusOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'note' })}
        >
          <TextArea
            rows="2"
            onChange={e => setFormData({ note: e.target.value })}
            value={form.note}
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!projectStatusId ? mutate() : mutateUpdate(projectStatusId))}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default ProjectStatusForm;
