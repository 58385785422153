import React, {
  useState, useEffect, useRef, useCallback
} from 'react';
import {
  Button, Input, Modal, Avatar
} from 'antd';
import { UserOutlined, UploadOutlined, CameraOutlined } from '@ant-design/icons';
import Cropper from 'react-cropper';
import Webcam from 'react-webcam';
import { FormattedMessage } from 'react-intl';

import { showFileUrl } from 'config/app';

import 'core/vendor/cropper.css';

const UserImageProfile = props => {
  const { urlImgProfile, onCompleteCropImg } = props;

  const fileImgProfileRef = useRef(null);
  const webcamRef = useRef(null);
  const cropperRef = useRef(null);
  const [showCrop, setShowCrop] = useState(false);
  const [showWebcam, setShowWebcam] = useState(false);
  const [imgData, setImgData] = useState('');
  const [imgSrc, setImgSrc] = useState();

  const loadImage = async () => {
    if (urlImgProfile) {
      const xhr = new XMLHttpRequest();

      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImgSrc(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };

      xhr.open('GET', `${showFileUrl}/${urlImgProfile}`);
      xhr.responseType = 'blob';
      xhr.send();
    }
  };

  useEffect(() => {
    loadImage();
  }, [urlImgProfile]);

  const handleFileChange = event => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        setShowCrop(true);
        setImgData(e.target.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleCancelModalCrop = () => {
    setShowCrop(false);
    setImgData('');
  };

  const onOkModalCrop = () => {
    const imgCroppedDataUrl = cropperRef.current.cropper.getCroppedCanvas().toDataURL();
    onCompleteCropImg(imgCroppedDataUrl);

    setImgData('');
    setShowCrop(false);
    setImgSrc(imgCroppedDataUrl);
  };

  const onCancelModalWebcam = () => {
    setShowWebcam(false);
    setImgData('');
  };

  const onOkModalWebcam = useCallback(() => {
    setImgData(webcamRef.current.getScreenshot());
    setShowWebcam(false);
    setShowCrop(true);
  }, [webcamRef]);

  const renderWebcam = () => {
    if (showWebcam) {
      return <Webcam ref={webcamRef} audio={false} width={480} height={360} />;
    }

    return null;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Avatar
        size="large"
        icon={<UserOutlined />}
        shape="square"
        src={imgSrc}
      />
      <div style={{ marginLeft: '4px' }}>
        <Input
          type="file"
          style={{ display: 'none' }}
          ref={fileImgProfileRef}
          onChange={handleFileChange}
          accept="image/png,image/jpg,image/jpeg"
        />
        <Button
          icon={<UploadOutlined />}
          style={{ marginRight: '2px' }}
          onClick={() => fileImgProfileRef.current.input.click()}
        >
          <FormattedMessage id="select_file" />
        </Button>
        <Button
          icon={<CameraOutlined />}
          onClick={() => setShowWebcam(true)}
        >
          <FormattedMessage id="take_picture" />
        </Button>
      </div>

      <Modal
        title=""
        visible={showCrop}
        maskClosable={false}
        closable={false}
        okText={<FormattedMessage id="ready" />}
        cancelText={<FormattedMessage id="cancel" />}
        onOk={onOkModalCrop}
        onCancel={handleCancelModalCrop}
      >
        <Cropper
          src={imgData}
          style={{ maxHeight: 350, width: '100%' }}
          ref={cropperRef}
          initialAspectRatio={1}
          guides={false}
          minCropBoxWidth={100}
          minCropBoxHeight={100}
        />
      </Modal>

      <Modal
        title=""
        visible={showWebcam}
        width={512}
        maskClosable={false}
        closable={false}
        okText={<FormattedMessage id="take_picture" />}
        cancelText={<FormattedMessage id="cancel" />}
        onOk={onOkModalWebcam}
        onCancel={onCancelModalWebcam}
      >
        {renderWebcam()}
      </Modal>
    </div>
  );
};

export default UserImageProfile;
