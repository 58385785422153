import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form, Input, DatePicker, Spin, Switch
} from 'antd';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { normalize, formatInput } from 'common/utils/number';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import { useGet, usePost, usePut } from 'common/api';
import { useDebt } from 'modules/debt/_contexts/DebtContext';

import CurrencyAmountField from 'modules/shared/currency/components/CurrencyAmountField';

import {
  DEBT_PAYMENT,
  DEBT_PAYMENT_LIST,
  DEBT_INSTALLMENT_LIST,
  DEBT_DEBT_DASHBOARD
} from 'modules/debt/_constants/cacheKeys';

const FormItem = Form.Item;

const PaymentForm = () => {
  const intl = useIntl();
  const { goBack } = useHistory();
  const { installmentId, paymentId, debtId } = useParams();

  const [form, setForm] = useState({
    id: null,
    installmentId,
    currencyId: null,
    executedOn: null,
    value: null,
    valueExecuted: null,
    note: null,
    otherCurrency: false
  });

  const debt = useDebt();

  const cacheKey = [DEBT_PAYMENT, paymentId];
  const { data, isLoading: isLoadingData } = useGet(cacheKey, '/debt/payment', {
    params: { id: paymentId },
    query: { enabled: Boolean(paymentId) }
  });
  useEffect(() => {
    if (!data) return;

    const d = data[0];
    const otherCurrency = d.currencyId !== debt.currencyId;

    setForm({
      ...d,
      value: formatInput(d.value, { toNormalize: true }),
      valueExecuted: formatInput(d.valueExecuted, { toNormalize: true }),
      otherCurrency
    });
  }, [data]);

  const options = {
    fetch: {
      body: {
        ...form,
        currencyId: form.otherCurrency ? form.currencyId : debt.currencyId,
        value: normalize(form.value),
        valueExecuted: form.otherCurrency ? normalize(form.valueExecuted) : normalize(form.value)
      }
    },
    onSuccess: goBack,
    cacheInvalidate: [
      [DEBT_INSTALLMENT_LIST, debtId],
      [DEBT_PAYMENT_LIST, installmentId],
      [DEBT_DEBT_DASHBOARD, debtId],
      paymentId && [DEBT_PAYMENT, paymentId]
    ]
  };
  const { mutate, isLoading } = usePost(() => '/debt/payment', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/debt/payment/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  return (
    <Spin spinning={isLoading || isLoadingUpdate || isLoadingData}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'date' })}
        >
          <DatePicker
            showTime={false}
            format={intl.formatMessage({
              id: 'date_format'
            })}
            onChange={date => {
              setFormData({
                executedOn: date || null
              });
            }}
            value={
              form.executedOn
                ? moment(form.executedOn)
                : null
            }
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'value' }, { plural: false })}
        >
          <Input
            maxLength={20}
            onChange={e => setFormData({ value: formatInput(e.target.value) })}
            value={form.value}
            style={{ width: 230 }}
            addonBefore={<div style={{ width: 68 }}>{debt?.currency?.symbol}</div>}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'executed_other_currency' })}
        >
          <Switch
            checked={form.otherCurrency}
            onChange={otherCurrency => setFormData({ otherCurrency })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'value' }, { plural: false })}
          hidden={!form.otherCurrency}
        >
          <CurrencyAmountField
            maxLength={20}
            onChange={e => setFormData({ valueExecuted: formatInput(e.target.value) })}
            value={form.valueExecuted}
            selectedCurrency={form.currencyId}
            style={{ width: 230 }}
            onSelect={v => setFormData({ currencyId: v })}
            removeId={debt?.currencyId}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'note' })}
        >
          <Input
            maxLength={120}
            onChange={e => setFormData({ note: e.target.value })}
            value={form.note}
            className="uppercase"
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!paymentId ? mutate() : mutateUpdate(paymentId))}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default PaymentForm;
