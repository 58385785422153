import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Tree } from 'antd';
import { FormattedMessage } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

import SelectOrganization from '../../organization/components/SelectOrganization';

const FormItem = Form.Item;
const { Option } = Select;
const { TreeNode } = Tree;

const RuleForm = props => {
  const {
    dataUser,
    onChangeOrganization,
    onCheckProfile,
    onChangeUser,
    getStateForm,
    dataProfile,
    onStore,
    loadingStore
  } = props;

  const userOptions = dataUser.map(v => (
    <Option key={`${v.id}`} value={`${v.id}`}>
      {v.person.name}
    </Option>
  ));

  return (
    <Form className="soft-box">
      <FormItem
        {...defaultFormItemLayout}
        label={
          <FormattedMessage id="organization" values={{ plural: false }} />
        }
      >
        <SelectOrganization onChange={onChangeOrganization} />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="user" values={{ plural: false }} />}
      >
        <Select
          onChange={userId => onChangeUser(parseInt(userId, 10))}
          size="default"
          style={{ width: '100%' }}
          showSearch
          filterOption={
            (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase())
            >= 0
          }
        >
          {userOptions}
        </Select>
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="access_profiles" />}
      >
        <div
          style={{
            backgroundColor: '#fff',
            borderRadius: '4px',
            marginBottom: '5px'
          }}
        >
          <Tree
            onCheck={onCheckProfile}
            checkedKeys={getStateForm.profileList}
            checkable
          >
            {dataProfile.map(item => (
              <TreeNode {...item} />
            ))}
          </Tree>
        </div>
      </FormItem>

      <FormSubmitButtons
        onStore={onStore}
        loading={loadingStore}
        hideCancel
      />
    </Form>
  );
};

RuleForm.propTypes = {
  onStore: PropTypes.func.isRequired,
  onCheckProfile: PropTypes.func.isRequired,
  onChangeOrganization: PropTypes.func.isRequired,
  onChangeUser: PropTypes.func.isRequired,
  setStateForm: PropTypes.func.isRequired,
  getStateForm: PropTypes.shape({}).isRequired,
  dataUser: PropTypes.shape([]).isRequired,
  dataProfile: PropTypes.shape([]).isRequired,
  loading: PropTypes.bool.isRequired,
  loadingStore: PropTypes.bool.isRequired
};

export default RuleForm;
