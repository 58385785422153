import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import PermissionForm from './PermissionForm';

class PermissionFormPage extends Component {
  state = {
    form: {
      profileId: null,
      resourceList: []
    },

    dataProfile: [],
    dataResource: [],
    loading: false,
    loadingStore: false
  };

  componentDidMount() {
    this.loadDataResource();
  }

  loadDataResource = async () => {
    this.setState({ loading: true });

    const result = await api('/access/resource/tree');

    this.setState({
      dataResource: result,
      loading: false
    });
  };

  /**
   * Seleciona os recursos atribuídos para um determinado perfil.
   *
   * @returns {Promise.<void>}
   */
  loadDataPermission = async profileId => {
    const { form } = this.state;
    this.setState({ loading: true });

    const result = await api(`/access/profile/resource/${profileId}`);

    const resourceList = result.map(({ id }) => id);

    this.setState({
      form: { ...form, resourceList },
      loading: false
    });
  };

  handleCheckResource = checkedKeys => {
    const { form } = this.state;
    this.setState({
      form: { ...form, resourceList: checkedKeys }
    });
  };

  handleChangeProfile = profileId => {
    const { form } = this.state;
    this.setState({ form: { ...form, profileId } });

    if (profileId) {
      this.loadDataPermission(profileId);
    }
  };

  handleStore = async () => {
    const { form } = this.state;
    this.setState({ loadingStore: true });

    const response = await api('/access/profile/resource', {
      method: 'POST',
      body: form
    });

    this.setState({ loadingStore: false });
    responseHandler(response);
  };

  render() {
    const {
      form, dataProfile, dataResource, loading, loadingStore
    } = this.state;

    return (
      <PermissionForm
        onStore={this.handleStore}
        onCheckResource={this.handleCheckResource}
        onChangeProfile={this.handleChangeProfile}
        setStateForm={value => this.setState({
          form: { ...form, ...value }
        })}
        getStateForm={form}
        getDataProfile={dataProfile}
        getDataResource={dataResource}
        getLoading={loading}
        getLoadingStore={loadingStore}
      />
    );
  }
}

export default PermissionFormPage;
