import React, { useState } from 'react';
import {
  Select, Input, Switch, Button, Drawer
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGet } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import UserSelect from 'modules/access/user/components/UserSelect';
import { PROJECT_TAG_LIST } from 'modules/project/_constants/cacheKeys';

const { Option } = Select;

const TaskListFilter = props => {
  const { show, hide, onFilter } = props;
  const intl = useIntl();
  const { id: organizationId } = useOrganization();

  const [form, setForm] = useState({
    tagIdIn: [],
    responsibleId: null,
    name: null,
    overdue: false,
    finished: false
  });

  const { data: tagData, isLoading: isLoadingTag } = useGet([PROJECT_TAG_LIST, organizationId], '/project/tag', { params: { ref: 'tsk' } });

  const setFormData = value => setForm({ ...form, ...value });

  const tagsOptions = tagData?.map(v => <Option key={`${v.id}`}>{v.name}</Option>);

  return (
    <Drawer
      title={<FormattedMessage id="filter" values={{ plural: true }} />}
      width={400}
      placement="left"
      visible={show}
      onClose={hide}
    >
      <Select
        mode="multiple"
        placeholder={<FormattedMessage id="tag" values={{ plural: true }} />}
        style={{ width: '100%', marginBottom: 4 }}
        allowClear
        maxTagCount={4}
        loading={isLoadingTag}
        onChange={tagIdIn => setFormData({ tagIdIn })}
      >
        {tagsOptions}
      </Select>

      <UserSelect
        placeholder={intl.formatMessage({ id: 'responsible' })}
        style={{ width: '100%', marginBottom: 4 }}
        onSelect={responsibleId => setFormData({ responsibleId })}
      />

      <Input
        placeholder={intl.formatMessage({ id: 'title' })}
        onChange={e => setFormData({ name: e.target.value })}
      />

      <div style={{ marginTop: 6, marginBottom: 6 }}>
        <Switch onChange={overdue => setFormData({ overdue })} />
        {' '}
        <FormattedMessage id="just_overdue" />
      </div>

      <div style={{ marginBottom: 6 }}>
        <Switch onChange={finished => setFormData({ finished })} />
        {' '}
        <FormattedMessage id="include_finished" />
      </div>

      <div style={{ marginTop: 4, textAlign: 'right' }}>
        <Button onClick={() => onFilter(form)}>
          <FormattedMessage id="apply_filters" />
        </Button>
      </div>
    </Drawer>
  );
};

export default TaskListFilter;
