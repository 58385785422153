import React, { useState } from 'react';
import { useHistory } from 'react-router';
import {
  Layout, Row, Col
} from 'antd';
import {
  MenuUnfoldOutlined, MenuFoldOutlined
} from '@ant-design/icons';

import { useAuth } from 'core/_contexts/AuthContext';
import { useOrganization, useOrganizationUpdate } from 'core/_contexts/OrganizationContext';
import { useConfig, useConfigUpdate } from 'core/_contexts/ConfigContext';
import HeaderOptions from './HeaderOptions';
import SystemsMenu from './SystemsMenu';

const { Header, Sider, Content } = Layout;

const style = {
  height: '38px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center'
};

const MainLayout = ({ children }) => {
  const { push } = useHistory();

  const auth = useAuth();
  const organization = useOrganization();
  const organizationUpdate = useOrganizationUpdate();
  const { locale } = useConfig();
  const configUpdate = useConfigUpdate();

  const [collapsed, setCollapsed] = useState(true);
  const [breakpoint, setBreakpoint] = useState(false);

  const handleOnChangeOrganization = org => {
    organizationUpdate(org);
    push('/core/module/list');
  };

  const renderCollapsedIcon = () => {
    const ToggleIcon = collapsed ? MenuUnfoldOutlined : MenuFoldOutlined;
    return !breakpoint && (
      <div style={{ width: collapsed ? 80 : 230, transition: 'all 0.3s', textAlign: 'center' }}>
        <ToggleIcon
          onClick={() => setCollapsed(!collapsed)}
          className="trigger"
        />
      </div>
    );
  };

  return (
    <Layout id="complete-layout" style={{ minHeight: '100vh' }}>
      <Header style={{ height: 38, lineHeight: '22px', padding: 0 }}>
        <Row align="middle">
          <Col span={6}>
            {renderCollapsedIcon()}
          </Col>

          <Col span={18} style={style}>
            <HeaderOptions
              user={auth}
              organizationInitials={organization.initials}
              locale={locale}
              onChangeLocale={loc => configUpdate({ locale: loc })}
              onChangeOrganization={handleOnChangeOrganization}
            />
          </Col>
        </Row>
      </Header>

      <Layout>
        <Sider
          width={230}
          breakpoint="lg"
          onBreakpoint={broken => setBreakpoint(broken)}
          collapsedWidth={breakpoint ? 0 : 80}
          onCollapse={(coll, type) => { if (type !== 'responsive') setCollapsed(coll); }}
          trigger={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          collapsed={collapsed}
        >
          <SystemsMenu collapsed={collapsed} />
        </Sider>

        <Layout>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
