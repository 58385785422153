export const normalize = (value, options = {}) => {
  const { decimals = 2 } = options;

  if (!value) return null;

  const match = `${value}`.match(/(.+(?:[,.]))(\d*)/);
  if (!match) {
    return `${value}.${'0'.repeat(decimals)}`;
  }

  return `${match[1].replace(/[,.]/g, '')}.${match[2].padEnd(decimals, '0')}`;
};

export const formatInput = (value, options = {}) => {
  const {
    locale,
    decimals = 2,
    toNormalize = false
  } = options;

  const normalized = toNormalize ? normalize(value, { decimals }) : value;

  const isNegative = /^-|-$/.test(value);
  const toRemoveNegative = /^-.*-$/.test(value);
  const intValue = +`${normalized}`.replace(/[-.,]/g, '');

  if (Number.isNaN(intValue)) { return null; }

  const setFormat = v => {
    const valueStrLength = `${v}`.length;

    if (valueStrLength <= decimals) {
      return `${v / (10 ** decimals)}`;
    }

    const regex = new RegExp(`(^[0-9]*)([0-9]{${decimals}}$)`);
    return `${v}`.replace(regex, '$1.$2');
  };

  const formatter = new Intl.NumberFormat(locale || 'pt', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });

  const formattedNumber = formatter.format(setFormat(intValue));
  return isNegative && !toRemoveNegative ? `-${formattedNumber}` : formattedNumber;
};

export const normalizeFormat = (v, options) => formatInput(normalize(v, options), options);
