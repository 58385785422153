import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';
import RuleForm from './RuleForm';

class RuleFormPage extends Component {
  state = {
    form: {
      organizationId: null,
      userId: null,
      profileList: []
    },

    dataProfile: [],
    dataUser: [],

    loading: false,
    loadingStore: false
  };

  componentDidMount() {
    this.loadDataProfile();
    this.loadDataUser();
  }

  loadDataUser = async () => {
    this.setState({ loading: true });

    const body = await api('/access/user');

    this.setState({
      dataUser: body,
      loading: false
    });
  };

  loadDataProfile = async () => {
    this.setState({ loading: true });

    let body = await api('/access/profile');

    body = body.map(v => ({
      key: v.id,
      title: v.name
    }));

    this.setState({
      dataProfile: body,
      loading: false
    });
  };

  loadDataRule = async () => {
    const { form } = this.state;

    if (!form.organizationId || !form.userId) {
      return;
    }

    this.setState({ loading: true });

    const body = await api('/access/rule', {
      body: {
        organizationId: form.organizationId,
        userId: form.userId
      }
    });

    let profileList = [];
    profileList = body.map(data => `${data.profileId}`);

    this.setState({
      form: { ...form, profileList },
      loading: false
    });
  };

  handleCheckProfile = checkedKeys => {
    const { form } = this.state;

    this.setState({
      form: { ...form, profileList: checkedKeys }
    });
  };

  handleChangeOrganization = async organizationId => {
    const { form } = this.state;

    await this.setState({ form: { ...form, organizationId } });

    this.loadDataRule();
  };

  handleChangeUser = async userId => {
    const { form } = this.state;

    await this.setState({ form: { ...form, userId } });

    this.loadDataRule();
  };

  handleStore = async () => {
    const { form } = this.state;
    this.setState({ loadingStore: true });

    const response = await api('/access/rule', {
      method: 'POST',
      body: form
    });

    this.setState({ loadingStore: false });
    responseHandler(response);
  };

  render() {
    const {
      form, dataUser, dataProfile, loading, loadingStore
    } = this.state;

    return (
      <RuleForm
        onStore={this.handleStore}
        onCheckProfile={this.handleCheckProfile}
        onChangeOrganization={this.handleChangeOrganization}
        onChangeUser={this.handleChangeUser}
        setStateForm={value => this.setState({
          form: { ...form, ...value }
        })}
        getStateForm={form}
        dataUser={dataUser}
        dataProfile={dataProfile}
        loading={loading}
        loadingStore={loadingStore}
      />
    );
  }
}

export default RuleFormPage;
