import React from 'react';
import { Button, Form } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import {
  defaultTailFormItemLayout
} from 'common/layout/formItemGrid';

const FormSubmitButtons = props => {
  const {
    noTail, onStore, onCancel, icon, loading, disabledStore, hideCancel, storeMessage
  } = props;

  const tail = !noTail ? defaultTailFormItemLayout : {};

  return (
    <Form.Item {...tail} className="form-footer-buttons">
      <Button
        onClick={onStore}
        type="primary"
        icon={icon || <SaveOutlined />}
        loading={loading}
        disabled={disabledStore}
      >
        <span><FormattedMessage id={storeMessage} /></span>
      </Button>
      { !hideCancel
          && (
            <Button onClick={onCancel} style={{ marginLeft: 4 }}>
              <FormattedMessage id="cancel" />
            </Button>
          )}
    </Form.Item>
  );
};

FormSubmitButtons.defaultProps = {
  storeMessage: 'save'
};

export default FormSubmitButtons;
