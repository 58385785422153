import React from 'react';
import { Input, Select } from 'antd';

import { useGet } from 'common/api';

const CurrencyAmountField = props => {
  const {
    onSelect,
    onChange,
    value,
    removeId,
    selectedCurrency,
    ...rest
  } = props;

  const { data, isLoading: isLoadingCurrency } = useGet('shared.currency', '/shared/currency');

  const currencySelect = (
    <Select
      loading={isLoadingCurrency}
      style={{ width: 90 }}
      onSelect={onSelect}
      value={selectedCurrency === removeId ? null : selectedCurrency}
      options={data?.filter(e => e.id !== removeId).map(({ id, symbol }) => ({ value: id, label: symbol }))}
    />
  );

  return (
    <Input
      onChange={onChange}
      value={value}
      addonBefore={currencySelect}
      {...rest}
    />
  );
};

export default CurrencyAmountField;
