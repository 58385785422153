import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'antd';
import { FallOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { useGet } from 'common/api';
import { useDebt } from 'modules/debt/_contexts/DebtContext';
import { DEBT_DEBT_DASHBOARD } from 'modules/debt/_constants/cacheKeys';
import CardPercent from 'common/components/CardPercent';
import CardIcon from 'common/components/CardIcon';

const TotalsCards = () => {
  const { formatMessage, formatNumber } = useIntl();
  const { debtId } = useParams();
  const debt = useDebt();
  const currencySymbol = debt?.currency?.symbol;

  const { data } = useGet([DEBT_DEBT_DASHBOARD, debtId, 'totals'], '/debt/debt-dashboard/totals', {
    params: { id: debtId }
  });

  const formatCurrency = value => (currencySymbol ? (
    formatNumber(value || 0, {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: currencySymbol
    })
  ) : 0);

  return (
    <Row gutter={20}>
      <Col xs={24} lg={12} xl={8}>
        <CardPercent
          percent={data?.operationTotal ? Math.floor((data.operationTotal / debt.value) * 100) : 0}
          title={formatMessage({ id: 'used' })}
        >
          {formatCurrency(data?.operationTotal)}
        </CardPercent>
      </Col>

      <Col xs={24} lg={12} xl={8}>
        <CardIcon
          icon={<FallOutlined />}
          title={formatMessage({ id: 'due' })}
        >
          {formatCurrency(data?.dueTotal)}
        </CardIcon>
      </Col>

      <Col xs={24} lg={12} xl={8}>
        <CardPercent
          percent={data?.paymentTotal ? Math.floor((data.paymentTotal / data.dueTotal) * 100) : 0}
          title={formatMessage({ id: 'paid' })}
        >
          {formatCurrency(data?.paymentTotal)}
        </CardPercent>
      </Col>
    </Row>
  );
};

export default TotalsCards;
