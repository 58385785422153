import React, { Component } from 'react';
import { Spin, Button, Form } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

class IndexValueImport extends Component {
  inputFileRef = {};

  state = {
    form: {
      fileData: []
    },
    indexId: null,
    recordsCount: 0,
    loading: false
  };

  componentDidMount() {
    const { match } = this.props;
    const { indexId } = match.params;

    this.setState({ indexId });
  }

  onStore = async () => {
    const { form } = this.state;
    const { history } = this.props;

    this.setState({ loading: true });

    const response = await api('/shared/index-value/bulk-create', {
      method: 'POST',
      body: form
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      history.goBack();
    }
  };

  onLoadFile = e => {
    const { form, indexId } = this.state;

    const fileReader = new FileReader();

    fileReader.onloadstart = () => {
      this.setState({ loading: true });
    };

    fileReader.onloadend = () => {
      this.setState({ loading: false });
    };

    fileReader.onerror = () => {
      fileReader.abort();
    };

    fileReader.onload = event => {
      const fileData = [];
      const lines = event.target.result.split('\n');

      // eslint-disable-next-line no-restricted-syntax
      for (const line of lines) {
        const indexValue = line.split(';');

        if (indexValue[1]) {
          fileData.push({
            indexId,
            date: moment(indexValue[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
            value: parseFloat(
              indexValue[1]
                .trim()
                .replace('.', '')
                .replace(',', '.')
            )
          });
        }
      }

      this.setState({
        recordsCount: fileData.length,
        form: {
          ...form,
          fileData
        }
      });
    };

    const file = e.target.files[0];
    setTimeout(() => fileReader.readAsText(file), 400);
  };

  setStateForm = value => {
    const { form } = this.state;
    this.setState({ form: { ...form, ...value } });
  };

  onClickImportFile = () => {
    this.inputFileRef.click();
  };

  render() {
    const { history } = this.props;
    const { loading, recordsCount } = this.state;

    return (
      <Spin spinning={loading}>
        <Form className="soft-box">
          <input
            type="file"
            ref={input => { this.inputFileRef = input; }}
            onChange={this.onLoadFile}
            style={{ display: 'none' }}
          />
          <Button
            icon={<UploadOutlined />}
            onClick={this.onClickImportFile}
            style={{ marginBottom: 10 }}
          >
            <FormattedMessage id="select_file" />
          </Button>

          <p>
            {recordsCount}
            {' '}
            <FormattedMessage
              id="record"
              values={{ count: recordsCount }}
            />
          </p>

          <FormSubmitButtons
            onStore={this.onStore}
            onCancel={history.goBack}
            disabledStore={!recordsCount}
            noTail
          />
        </Form>
      </Spin>
    );
  }
}

export default IndexValueImport;
