import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Form, Input, Select, Spin
} from 'antd';
import { useIntl } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, usePost, usePut } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';
import { useAuth } from 'core/_contexts/AuthContext';

import { SHARED_INDEX, SHARED_INDEX_LIST } from 'modules/shared/_constants/cacheKeys';

const FormItem = Form.Item;
const { Option } = Select;

const IndexForm = () => {
  const intl = useIntl();
  const { goBack } = useHistory();
  const { indexId } = useParams();
  const { id: organizationId } = useOrganization();
  const { organizationList } = useAuth();

  const [form, setForm] = useState({
    id: null,
    organizationId: null,
    name: null,
    description: null,
    alias: null
  });

  const cacheKey = [SHARED_INDEX, indexId];
  const { data, isLoading } = useGet(cacheKey, '/shared/index', {
    params: { id: indexId },
    query: { enabled: Boolean(indexId) }
  });
  useEffect(() => {
    if (data) setForm(data[0]);
  }, [data]);

  const options = {
    fetch: { body: form },
    onSuccess: goBack,
    cacheInvalidate: [
      [SHARED_INDEX_LIST, organizationId],
      indexId && [SHARED_INDEX, indexId]
    ]
  };
  const { mutate, isLoadingMutate } = usePost(() => '/shared/index', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(() => `/shared/index/${indexId}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const organizationOptions = organizationList.map(
    ({ id, name }) => <Option key={id} value={`${id}`}>{name}</Option>
  );

  return (
    <Spin spinning={isLoadingMutate || isLoadingUpdate || isLoading}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'organization' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={id => setFormData({ organizationId: id || null })}
            allowClear
            value={
              form.organizationId
                ? `${form.organizationId}`
                : ''
            }
          >
            {organizationOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'name' })}
        >
          <Input
            maxLength={80}
            onChange={e => setFormData({ name: e.target.value })}
            value={form.name}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'alias' })}
        >
          <Input
            maxLength={10}
            onChange={e => setFormData({ alias: e.target.value })}
            value={form.alias}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <Input
            maxLength={200}
            onChange={e => setFormData({
              description: e.target.value
            })}
            value={form.description}
            className="uppercase"
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!indexId ? mutate() : mutateUpdate())}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default IndexForm;
