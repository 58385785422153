import React from 'react';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { iconList } from '../../status/static/IconListStatic';

const renderStatusIcon = data => {
  if (data) {
    const StatusIcon = iconList[data.status.icon];

    return (StatusIcon
      ? (
        <StatusIcon
          style={{
            color: data.status.color,
            opacity: 0.4
          }}
        />
      ) : null
    );
  }

  return null;
};

const ProjectStatusCard = props => {
  const { data } = props;

  return (
    <div className="stat">
      <div className="big-icon">{renderStatusIcon(data)}</div>
      <div>
        <small className="title uppercase">
          <FormattedMessage id="project_status" />
        </small>
        <div className="content">
          <Link
            to="project-status"
            style={{ color: data ? data.status.color : null }}
          >
            {data ? data.status.name : null}
          </Link>
        </div>
        <div>
          <small className="description">
            {moment.utc(data ? data.createdAt : null).fromNow()}
          </small>
          <Link to="project-status">
            <EditOutlined
              style={{
                cursor: 'pointer',
                color: '#a5a5a5',
                marginLeft: 6
              }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProjectStatusCard;
