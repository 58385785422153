import React, { useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import {
  Tooltip, Button, Modal, Select
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGet, useDelete } from 'common/api';

import { useDebt } from 'modules/debt/_contexts/DebtContext';
import { useInstallmentUpdate } from 'modules/debt/_contexts/InstallmentContext';

import {
  DEBT_COMPOSITION_LIST,
  DEBT_INSTALLMENT_LIST,
  DEBT_INSTALLMENT,
  DEBT_DEBT_DASHBOARD
} from 'modules/debt/_constants/cacheKeys';

import CompositionTypeStatic from '../static/CompositionTypeStatic';
import CompositionListDetailed from '../components/CompositionListDetailed';
import CompositionListGrouped from '../components/CompositionListGrouped';

const ButtonGroup = Button.Group;
const { Option } = Select;
const { confirm } = Modal;

const CompositionList = () => {
  const intl = useIntl();
  const history = useHistory();
  const { url } = useRouteMatch();

  const { installmentId, debtId } = useParams();

  const installmentUpdate = useInstallmentUpdate();
  const debt = useDebt();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [listType, setListType] = useState('');
  const [showRealized, setShowRealized] = useState(true);

  const cacheKey = [DEBT_COMPOSITION_LIST, debtId, installmentId];

  const { data, isLoading } = useGet(cacheKey, '/debt/composition', {
    params: { installmentId }
  });

  const { mutate } = useDelete(id => {
    const listId = id || selectedRowKeys.join();
    return `/debt/composition/${listId}`;
  }, {
    cacheInvalidate: [
      cacheKey,
      [DEBT_INSTALLMENT, installmentId],
      [DEBT_INSTALLMENT_LIST, debtId],
      [DEBT_DEBT_DASHBOARD, debtId],
    ],
    onSuccess: () => {
      setSelectedRowKeys([]);
      installmentUpdate(installmentId);
    }
  });

  const groupBy = (list, index) => {
    const groupedData = list?.reduce((acc, cur) => {
      acc[cur[index]] = {
        items: acc[cur[index]] ? acc[cur[index]].items + 1 : 1,
        amount: acc[cur[index]] ? acc[cur[index]].amount + cur.value : cur.value
      };

      return acc;
    }, {});

    return groupedData ? Object.entries(groupedData).map(([k, v]) => {
      if (index === 'calculationId') {
        const calculationDescription = list?.find(({ calculationId }) => calculationId?.toString() === k)?.description
        || `[${intl.formatMessage({ id: 'manual_data_entry' })}]`;

        return {
          group: calculationDescription,
          items: v.items,
          amount: v.amount
        };
      }

      switch (index) {
        case 'type':
          return {
            group: intl.formatMessage({ id: CompositionTypeStatic.list[k] }, { plural: false }),
            items: v.items,
            amount: v.amount
          };

        case 'amortize':
          return {
            group: intl.formatMessage({ id: k === 'false' ? 'no' : 'yes' }, { plural: false }),
            items: v.items,
            amount: v.amount
          };

        default:
          return {};
      }
    }) : [];
  };

  const rowSelection = {
    columnWidth: '3%',
    selectedRowKeys,
    onChange: setSelectedRowKeys
  };

  const renderScopeList = scope => {
    const dataFiltered = data
      ?.filter(v => v.scope === scope)
      ?.filter(v => v.realized === showRealized);

    if (!listType) {
      return (
        <CompositionListDetailed
          dataSource={dataFiltered}
          loading={isLoading}
          rowSelection={rowSelection}
          debtCurrency={debt.currency}
          onDestroy={mutate}
        />
      );
    }

    const dataGrouped = groupBy(dataFiltered, listType);

    return (
      <CompositionListGrouped
        dataSource={dataGrouped}
        loading={isLoading}
        debtCurrency={debt.currency}
      />
    );
  };

  const hasRowSelected = selectedRowKeys.length > 0;

  return (
    <>
      <ButtonGroup>
        <Tooltip title={<FormattedMessage id="add" />}>
          <Button
            onClick={() => { history.push(`${url}/composition/add`); }}
            icon={<PlusOutlined />}
          />
        </Tooltip>

        <Tooltip
          title={(
            <span>
              <FormattedMessage id="delete" />
            </span>
          )}
        >
          <Button
            icon={<DeleteOutlined />}
            disabled={!hasRowSelected}
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.delete_item' }),
                onOk: () => { if (hasRowSelected) mutate(null); }
              });
            }}
          />
        </Tooltip>
      </ButtonGroup>

      <Select
        style={{ marginLeft: 10, width: 240 }}
        defaultValue=""
        onChange={setListType}
      >
        <Option key="0" value=""><FormattedMessage id="detailed" /></Option>
        <Option key="1" value="type"><FormattedMessage id="group_by_type" /></Option>
        <Option key="2" value="amortize"><FormattedMessage id="group_by_amortize" /></Option>
        <Option key="3" value="calculationId"><FormattedMessage id="group_by_calculation" /></Option>
      </Select>

      <Select
        style={{ marginLeft: 10, width: 160 }}
        defaultValue="realized"
        onChange={v => setShowRealized(v === 'realized')}
      >
        <Option key="0" value="realized"><FormattedMessage id="realized" /></Option>
        <Option key="1" value="planned"><FormattedMessage id="planned" /></Option>
      </Select>

      <h5 style={{ marginTop: 20 }}>
        <FormattedMessage id="installment" values={{ plural: false }} />
      </h5>
      {renderScopeList('i')}

      <h5 style={{ marginTop: 20 }}>
        <FormattedMessage id="balance" />
      </h5>
      {renderScopeList('b')}
    </>
  );
};

export default CompositionList;
