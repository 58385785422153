export const PROJECT_FILE = 'project.file';
export const PROJECT_FILE_LIST = 'project.fileList';
export const PROJECT_FOLDER = 'project.folder';
export const PROJECT_FOLDER_LIST = 'project.folderList';
export const PROJECT_HISTORY = 'project.history';
export const PROJECT_HISTORY_LIST = 'project.historyList';
export const PROJECT_LIST = 'project.list';
export const PROJECT_PROJECT = 'project.project';
export const PROJECT_PROJECT_STATUS = 'project.projectStatus';
export const PROJECT_PROJECT_STATUS_LIST = 'project.projectStatusList';
export const PROJECT_STATUS = 'project.status';
export const PROJECT_STATUS_LIST = 'project.statusList';
export const PROJECT_TAG = 'project.tag';
export const PROJECT_TAG_LIST = 'project.tagList';
export const PROJECT_TASK = 'project.task';
export const PROJECT_TASK_LIST = 'project.taskList';
