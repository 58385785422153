import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Tree, Button, Tooltip, Empty, Spin
} from 'antd';
import {
  DeleteOutlined,
  FolderAddOutlined,
  EditOutlined,
  RetweetOutlined
} from '@ant-design/icons';
import { useIntl } from 'react-intl';

import removeFromTree from 'common/utils/removeFromTree';
import { useGet } from 'common/api';

import { PROJECT_FOLDER_LIST } from 'modules/project/_constants/cacheKeys';

const FolderList = props => {
  const { removeId, onSelect, hideButtons } = props;
  const intl = useIntl();
  const { push } = useHistory();
  const { projectId, folderId } = useParams();
  const [data, setData] = useState([]);

  const { data: loadedData, isLoading } = useGet([PROJECT_FOLDER_LIST, projectId], '/project/folder/tree', {
    params: {
      root: true,
      projectId
    }
  });
  useEffect(() => {
    if (loadedData && removeId) {
      const dataClone = JSON.parse(JSON.stringify(loadedData));
      setData(removeFromTree(dataClone, removeId, 'key'));
    } else {
      setData(loadedData);
    }
  }, [loadedData]);

  const renderTree = () => {
    if (data?.length) {
      return (
        <Tree
          onSelect={id => onSelect(id[0] ? id[0] : null)}
          defaultExpandAll
          defaultSelectedKeys={[+folderId]}
          treeData={data}
          showLine={{ showLeafIcon: false }}
          style={{
            paddingTop: 4,
            background: '#fff'
          }}
        />
      );
    }

    if (!isLoading) {
      return (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      );
    }

    return null;
  };

  const renderButtons = () => (
    <div style={{ marginBottom: 4 }}>
      <Tooltip title={intl.formatMessage({ id: 'add' })}>
        <Button
          icon={<FolderAddOutlined />}
          onClick={() => push(`/project/${projectId}/folder/add${folderId ? `/${folderId}` : ''}`)}
          style={{ marginRight: 8 }}
        />
      </Tooltip>
      <Button.Group>
        <Tooltip title={intl.formatMessage({ id: 'edit' })}>
          <Button
            icon={<EditOutlined />}
            disabled={!folderId}
            onClick={() => push(`../folder/${folderId}/edit`)}
          />
        </Tooltip>

        <Tooltip title={intl.formatMessage({ id: 'move' })}>
          <Button
            icon={<RetweetOutlined />}
            disabled={!folderId}
            onClick={() => push(`../folder/${folderId}/move`)}
          />
        </Tooltip>

        <Tooltip
          title={intl.formatMessage({
            id: 'delete'
          })}
        >
          <Button
            icon={<DeleteOutlined />}
            disabled={!folderId}
            onClick={() => push(`../folder/${folderId}/destroy`)}
          />
        </Tooltip>
      </Button.Group>
    </div>
  );

  return (
    <Spin spinning={isLoading}>
      {!hideButtons && renderButtons()}
      {renderTree()}
    </Spin>
  );
};

export default FolderList;
