import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form, Input, Button, Slider, Upload, Spin
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, usePost, usePut } from 'common/api';
import { showFileUrl } from 'config/app';
import uploadConfig from 'config/upload';

import UserSelect from 'modules/access/user/components/UserSelect';

import { PROJECT_HISTORY_LIST, PROJECT_HISTORY, PROJECT_TASK } from 'modules/project/_constants/cacheKeys';

const FormItem = Form.Item;
const { TextArea } = Input;

const HistoryForm = () => {
  const intl = useIntl();
  const { historyId, taskId } = useParams();
  const { goBack } = useHistory();

  const [form, setForm] = useState({
    id: null,
    taskId,
    responsibleId: null,
    description: null,
    progress: 0,
    fileIdList: []
  });

  const [fileList, setFileList] = useState([]);

  const cacheKey = [PROJECT_HISTORY, historyId];
  const { data, isLoading: isLoadingData } = useGet(cacheKey, '/project/history', {
    params: { id: historyId },
    query: { enabled: Boolean(historyId) }
  });
  useEffect(() => {
    if (data) {
      const loaded = data.rows[0];

      const list = loaded.file.map(f => ({
        id: f.id,
        uid: f.recordedName,
        name: f.name,
        status: 'done',
        url: `${showFileUrl}/${f.recordedName}`
      }));
      setFileList(list);

      setForm(loaded);
    }
  }, [data]);

  const options = {
    fetch: { body: { ...form, fileIdList: fileList.map(f => f.id) } },
    onSuccess: goBack,
    cacheInvalidate: [
      [PROJECT_TASK, taskId],
      [PROJECT_HISTORY_LIST, taskId],
      historyId && [PROJECT_HISTORY, historyId]
    ]
  };
  const { mutate, isLoading } = usePost(() => '/project/history', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/project/history/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const onChangeFileUpload = ({ fileList: list }) => {
    const updatedList = list.map(f => {
      const file = f;
      if (file.response) {
        const r = file.response.fileList[0];
        file.id = r.id;
        file.url = `${showFileUrl}/${r.recordedName}`;
      }

      return file;
    });

    setFileList(updatedList);
  };

  return (
    <Spin spinning={isLoading || isLoadingUpdate || isLoadingData}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <TextArea
            rows="3"
            onChange={e => setFormData({ description: e.target.value })}
            value={form.description}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="responsible" />}
        >
          <UserSelect
            onSelect={responsibleId => setFormData({ responsibleId })}
            initialLabel={form?.responsible?.person.name}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="progress" />}
        >
          <Slider
            onChange={progress => setFormData({ progress })}
            value={form.progress}
            step={5}
            marks={{
              0: '0%',
              50: '50%',
              100: '100%'
            }}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="file" values={{ plural: true }} />}
        >
          <Upload
            {...uploadConfig()}
            onChange={onChangeFileUpload}
            fileList={fileList}
            accept=".jpg,.png,.pdf,.jpeg"
            multiple
          >
            <Button
              size="default"
              icon={<UploadOutlined />}
              style={{ width: '180px' }}
            >
              <FormattedMessage id="select_file" />
            </Button>
          </Upload>
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!historyId ? mutate() : mutateUpdate(historyId))}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default HistoryForm;
