import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import OrganizationList from './OrganizationList';

class OrganizationListPage extends Component {
  state = {
    data: [],
    inactive: false, // false=ativos / true=inativos
    loading: false
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { inactive } = this.state;
    this.setState({ loading: true });

    const body = await api('/access/organization', {
      body: { deleted: inactive }
    });

    this.setState({
      data: body,
      loading: false
    });
  };

  handleDestroy = async id => {
    this.setState({ loading: true });

    const response = await api(`/access/organization/${id}`, {
      method: 'DELETE'
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      this.loadData();
    }
  };

  handleRestore = async id => {
    this.setState({ loading: true });

    const response = await api(`/access/organization/restore/${id}`, {
      method: 'PUT'
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      this.loadData();
    }
  };

  handleChangeSelectStatus = async value => {
    await this.setState({ inactive: value === 'true' });

    this.loadData();
  };

  render() {
    const { data, inactive, loading } = this.state;
    const { history } = this.props;

    return (
      <OrganizationList
        data={data}
        onChangeSelectStatus={this.handleChangeSelectStatus}
        onDestroy={this.handleDestroy}
        onRestore={this.handleRestore}
        onGoToForm={() => history.push('/access/organization/add')}
        getInactive={inactive}
        getLoading={loading}
      />
    );
  }
}
export default OrganizationListPage;
