import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Form, Input, DatePicker, Select, Switch, Spin
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';

import { normalize, formatInput } from 'common/utils/number';
import { useGet, usePost, usePut } from 'common/api';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import {
  defaultFormItemLayout
} from 'common/layout/formItemGrid';

import { useInstallmentUpdate } from 'modules/debt/_contexts/InstallmentContext';

import {
  DEBT_COMPOSITION,
  DEBT_COMPOSITION_LIST,
  DEBT_INSTALLMENT_LIST,
  DEBT_INSTALLMENT,
  DEBT_DEBT_DASHBOARD
} from 'modules/debt/_constants/cacheKeys';

import CompositionScopeStatic from '../static/CompositionScopeStatic';
import CompositionTypeStatic from '../static/CompositionTypeStatic';

const FormItem = Form.Item;
const { Option } = Select;

const CompositionForm = () => {
  const intl = useIntl();
  const history = useHistory();
  const installmentUpdate = useInstallmentUpdate();
  const { installmentId, compositionId, debtId } = useParams();
  const [form, setForm] = useState({
    id: null,
    installmentId,
    scope: null,
    type: null,
    date: null,
    value: null,
    description: null,
    amortize: false,
    realized: true
  });

  const cacheKey = [DEBT_COMPOSITION, compositionId];

  const { data, isLoading: isLoadingData } = useGet(cacheKey, '/debt/composition', {
    params: { id: compositionId },
    query: { enabled: Boolean(compositionId) }
  });
  useEffect(() => {
    if (data) {
      setForm({
        ...data[0],
        date: moment(data[0]?.date),
        value: formatInput(data[0].value, { toNormalize: true })
      });
    }
  }, [data]);

  const options = {
    fetch: {
      body: {
        ...form,
        value: normalize(form.value),
        date: form.date?.format('YYYY-MM-DD')
      }
    },
    onSuccess: () => {
      installmentUpdate(installmentId);
      history.goBack();
    },
    cacheInvalidate: [
      [DEBT_COMPOSITION_LIST, debtId, installmentId],
      [DEBT_INSTALLMENT_LIST, debtId],
      [DEBT_INSTALLMENT, installmentId],
      [DEBT_DEBT_DASHBOARD, debtId],
      compositionId && [DEBT_COMPOSITION, compositionId]
    ]
  };
  const { mutate, isLoading } = usePost(() => '/debt/composition', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/debt/composition/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const scopeOptions = Object.entries(CompositionScopeStatic.list).map(
    ([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} values={{ plural: false }} />
      </Option>
    )
  );

  const typeOptions = Object.entries(CompositionTypeStatic.list).map(
    ([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} values={{ plural: false }} />
      </Option>
    )
  );

  return (
    <Spin spinning={isLoading || isLoadingUpdate || isLoadingData}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'date' })}
        >
          <DatePicker
            showTime={false}
            format={intl.formatMessage({
              id: 'date_format'
            })}
            onChange={date => {
              setFormData({ date: date || null });
            }}
            value={form.date ? form.date : null}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'scope' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={scope => setFormData({ scope })}
            value={form.scope ? `${form.scope}` : ''}
          >
            {scopeOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'type' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={type => setFormData({ type })}
            value={form.type ? `${form.type}` : ''}
          >
            {typeOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'value' }, { plural: false })}
        >
          <Input
            maxLength={20}
            onChange={e => setFormData({ value: formatInput(e.target.value) })}
            value={form.value}
            style={{ width: 200 }}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <Input
            maxLength={120}
            onChange={e => setFormData({ description: e.target.value })}
            value={form.description}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={`${intl.formatMessage({
            id: 'realized'
          })}`}
        >
          <Switch
            checked={form.realized}
            onChange={realized => setFormData({ realized })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={`${intl.formatMessage({
            id: 'amortize'
          })}`}
        >
          <Switch
            checked={form.amortize}
            onChange={amortize => setFormData({ amortize })}
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!compositionId ? mutate() : mutateUpdate(compositionId))}
          onCancel={history.goBack}
        />
      </Form>
    </Spin>
  );
};

export default CompositionForm;
