import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';
import { clearEmptyChildren } from 'common/utils/misc';

import ResourceList from './ResourceList';

class ResourceListPage extends Component {
  state = {
    data: [],
    loading: true
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });

    const result = await api('/access/resource/tree');

    const data = result.map(elem => clearEmptyChildren(elem));

    this.setState({
      data,
      loading: false
    });
  };

  handleDestroy = async id => {
    this.setState({ loading: true });

    const response = await api(`/access/resource/${id}`, {
      method: 'DELETE'
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      this.loadData();
    }
  };

  render() {
    const { data, loading } = this.state;
    const { history } = this.props;

    return (
      <ResourceList
        data={data}
        onDestroy={this.handleDestroy}
        onGoToForm={() => history.push('/access/resource/add')}
        loading={loading}
      />
    );
  }
}
export default ResourceListPage;
