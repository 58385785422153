/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';
import { clearPunctuation } from 'common/utils/misc';
import { apiUrl } from 'config/app';

import AddressForm from './AddressForm';

class AddressFormContainer extends Component {
  state = {
    form: {
      id: null,
      personId: null,
      title: null,
      zipCode: null,
      address: null,
      number: null,
      complement: null,
      district: null,
      city: null,
      state: null,
      cityCode: null,
      latitude: null,
      longitude: null
    },

    loading: false,
    loadingZipCode: false
  };

  componentDidMount() {
    const { match } = this.props;
    const { addressId, personId } = match.params;
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        id: addressId,
        personId
      }
    });

    if (addressId) {
      this.loadDataToUpdate(addressId, personId);
    }
  }

  handleStore = async () => {
    const { history } = this.props;
    const { form } = this.state;

    this.setState({ loading: true });

    let response = null;

    if (!form.id) {
      response = await api('/shared/address-person', {
        method: 'POST',
        body: this.getData(form)
      });
    } else {
      response = await api(`/shared/address-person/${form.id}`, {
        method: 'PUT',
        body: this.getData(form)
      });
    }

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      history.goBack();
    }
  };

  loadDataToUpdate = async (addressId, personId) => {
    const { form } = this.state;

    this.setState({ loading: true });

    const [body] = await api('/shared/address-person', {
      body: { id: addressId }
    });

    const { geo } = body;

    let [latitude, longitude] = [null, null];
    if (geo) {
      [longitude, latitude] = geo;
    }

    this.setState({
      form: {
        ...form,
        ...body,
        personId,
        latitude,
        longitude
      },
      loading: false
    });
  };

  handleLoadCep = async () => {
    const { form } = this.state;

    this.setState({ loadingZipCode: true });

    const response = await fetch(
      `${apiUrl}/shared/cep-aberto/${form.zipCode}`
    );
    if (response.ok) {
      const body = await response.json();

      const {
        logradouro: address,
        bairro: district,
        cidade: city,
        estado: state,
        ibge: ibgeCod,
        latitude,
        longitude
      } = body;

      this.setState({
        form: {
          ...form,
          address,
          district,
          city,
          state,
          ibgeCod,
          latitude,
          longitude
        },
        loading: false
      });
    }
  };

  getData = data => ({
    ...data,
    cep: clearPunctuation(data.cep)
  });

  render() {
    const { history } = this.props;
    const { form, loading, loadingZipCode } = this.state;

    return (
      <AddressForm
        onStore={this.handleStore}
        onCancel={history.goBack}
        onLoadZipCode={this.handleLoadCep}
        setStateForm={value => this.setState({
          form: { ...form, ...value }
        })}
        getStateForm={form}
        loading={loading}
        loadingZipCode={loadingZipCode}
      />
    );
  }
}

export default AddressFormContainer;
