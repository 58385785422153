import React from 'react';
import { Row, Col } from 'antd';
import { useIntl } from 'react-intl';

import TotalsCards from './TotalsCards';
import TotalsByYear from './TotalsByYear';
import TotalsByComposition from './TotalsByComposition';
import ContractData from './ContractData';

const DebtDashboard = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <TotalsCards />

      <Row style={{ marginTop: 28 }} gutter={20}>
        <Col xs={24} lg={14} xl={16}>
          <h5>{formatMessage({ id: 'annual_amounts' })}</h5>
          <TotalsByYear />
        </Col>
        <Col xs={24} lg={10} xl={8}>
          <h5>{formatMessage({ id: 'composition' })}</h5>
          <TotalsByComposition />

          <h5>{formatMessage({ id: 'contract_data' })}</h5>
          <ContractData />
        </Col>
      </Row>
    </>
  );
};

export default DebtDashboard;
