import React from 'react';
import { Row, Col } from 'antd';
import { useIntl } from 'react-intl';
import { DollarCircleOutlined, FallOutlined } from '@ant-design/icons';

import { useGet } from 'common/api';
import { DEBT_DASHBOARD } from 'modules/debt/_constants/cacheKeys';
import CardIcon from 'common/components/CardIcon';

import styles from './styles.module.css';

const TotalsCards = () => {
  const { formatMessage, formatNumber } = useIntl();

  const { data: totalBalance } = useGet([DEBT_DASHBOARD, 'totalBalance'], '/debt/dashboard/total-balance');
  // const { data: totals } = useGet([DEBT_DASHBOARD, 'totals'], '/debt/dashboard/totals');

  const formattedCurrency = (value, symbol) => {
    if (!symbol) { return []; }

    const formatted = formatNumber(value || 0, {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: symbol
    }).split(/\s/);

    return (
      <>
        <span className={styles.currency}>{formatted[0]}</span>
        {formatted[1]}
      </>
    );
  };

  return (
    <Row gutter={20} style={{ marginTop: 20, marginBottom: 28 }}>
      <Col span={12}>
        <CardIcon
          icon={<DollarCircleOutlined />}
          title={formatMessage({ id: 'balance' })}
        >
          <ul className={styles.amountList}>
            {totalBalance?.map(({ balanceDueCurrent, currency }) => (
              <li key={currency}>
                {formattedCurrency(balanceDueCurrent, currency)}
              </li>
            ))}
          </ul>
        </CardIcon>
      </Col>

      <Col span={12}>
        <CardIcon
          icon={<FallOutlined />}
          title={`${formatMessage({ id: 'balance' })} (31/12)`}
        >
          <ul className={styles.amountList}>
            {totalBalance?.map(({ balanceDue3112, currency }) => (
              <li key={currency}>
                {formattedCurrency(balanceDue3112, currency)}
              </li>
            ))}
          </ul>
        </CardIcon>
      </Col>
    </Row>
  );
};

export default TotalsCards;
