import React, { useState } from 'react';
import { Table, DatePicker } from 'antd';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { useGet } from 'common/api';
import { DEBT_DASHBOARD } from 'modules/debt/_constants/cacheKeys';

const { RangePicker } = DatePicker;

const TotalsByYear = () => {
  const { formatMessage, formatNumber } = useIntl();
  const currentYear = new Date().getFullYear();

  const [dateRange, setDateRange] = useState([currentYear, currentYear + 10]);

  const { data, isLoading } = useGet([DEBT_DASHBOARD, 'totalsByYear', dateRange], '/debt/dashboard/totals-by-year', {
    params: {
      yearStart: dateRange[0],
      yearEnd: dateRange[1]
    }
  });

  const formatData = totals => {
    if (!totals) return null;

    const sum = totals.reduce((acc, {
      year, amortize, total, currency
    }) => {
      const key = `${year}-${currency}`;

      const prev = acc[key] || { amortization: 0, other: 0, currency };

      return {
        ...acc,
        [key]: {
          year,
          amortization: amortize ? prev.amortization + total : prev.amortization,
          other: amortize ? prev.other : prev.other + total,
          currency
        }
      };
    }, {});

    return Object.entries(sum).map(([year, amounts]) => ({ year, ...amounts }));
  };

  const formatCurrency = (amount, currency) => (currency ? (
    formatNumber(amount || 0, {
      maximumFractionDigits: 2,
      style: 'currency',
      currency
    })
  ) : 0);

  const tableColumns = () => [
    {
      title: formatMessage({ id: 'year' }),
      dataIndex: 'year',
      key: 'year'
    },
    {
      title: formatMessage({ id: 'amortization' }),
      render: ({ amortization, currency }) => formatCurrency(amortization, currency),
      key: 'amortization',
      width: '25%',
      align: 'right',
    },
    {
      title: formatMessage({ id: 'other' }, { plural: true }),
      render: ({ other, currency }) => formatCurrency(other, currency),
      key: 'other',
      width: '25%',
      align: 'right',
    },
    {
      title: 'Total',
      render: ({ amortization, other, currency }) => formatCurrency(amortization + other, currency),
      key: 'total',
      width: '25%',
      align: 'right',
    }
  ];

  return (
    <>
      <RangePicker
        picker="year"
        defaultValue={[moment(currentYear, 'YYYY'), moment(currentYear + 10, 'YYYY')]}
        onChange={(_, dates) => setDateRange(dates)}
      />
      <Table
        style={{ marginTop: 6 }}
        dataSource={formatData(data)}
        rowKey="year"
        columns={tableColumns()}
        size="middle"
        loading={isLoading}
        pagination={false}
      />
    </>
  );
};

export default TotalsByYear;
