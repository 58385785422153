import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form, Input, Button, Layout, Row, Col
} from 'antd';
import { LockOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons';
import { useIntl, FormattedMessage } from 'react-intl';

import { initialUrl, apiUrl } from 'config/app';
import { usePost } from 'common/api';

import { useAuthUpdate } from 'core/_contexts/AuthContext';
import { useOrganizationUpdate } from 'core/_contexts/OrganizationContext';
import { useConfigUpdate, defaultConfig } from 'core/_contexts/ConfigContext';

const FormItem = Form.Item;
const { Content } = Layout;

const LoginForm = () => {
  const intl = useIntl();
  const { push } = useHistory();

  const authUpdate = useAuthUpdate();
  const organizationUpdate = useOrganizationUpdate();
  const configUpdate = useConfigUpdate();

  const [form, setForm] = useState({
    username: '',
    password: ''
  });

  const options = {
    fetch: {
      body: form,
      credentials: 'include'
    },
    onSuccess: data => {
      authUpdate(data);
      organizationUpdate(data.organizationList[0]);
      configUpdate(defaultConfig);

      if (!data.changePassword) {
        push(initialUrl);
      } else {
        push('/access/user/index');
      }
    },
  };
  const { mutate, isLoading } = usePost(() => '/access/auth/signin', options);

  useEffect(() => {
    localStorage.clear();
    fetch(`${apiUrl}/access/auth/signout`, { credentials: 'include' });

    if (process.env.REACT_APP_ENV === 'development') {
      setForm({ username: 'admin', password: '11111111' });
      setTimeout(() => mutate(), 200);
    }
  }, []);

  const setFormData = value => setForm({ ...form, ...value });

  return (
    <Layout style={{ backgroundColor: '#F0F2F5' }}>
      <Content>
        <Row
          type="flex"
          align="middle"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Col xs={24} sm={16} md={12} lg={6} xl={6}>
            <div style={{ textAlign: 'center' }}>
              <UnlockOutlined
                style={{ fontSize: '150px', color: '#fff' }}
              />
            </div>
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                borderRadius: '4px'
              }}
            >
              <Form layout="vertical">
                <FormItem>
                  <Input
                    addonBefore={<UserOutlined />}
                    size="large"
                    placeholder={intl.formatMessage(
                      { id: 'user' },
                      { plural: false }
                    )}
                    onChange={e => setFormData({
                      username: e.target.value
                    })}
                    autoFocus
                    onPressEnter={mutate}
                  />
                </FormItem>

                <FormItem>
                  <Input
                    addonBefore={<LockOutlined />}
                    size="large"
                    placeholder={intl.formatMessage({
                      id: 'password'
                    })}
                    type="password"
                    onChange={e => setFormData({
                      password: e.target.value
                    })}
                    onPressEnter={mutate}
                  />
                </FormItem>

                <Button
                  size="large"
                  type="primary"
                  loading={isLoading}
                  onClick={mutate}
                  style={{ width: '100%' }}
                >
                  <FormattedMessage id="enter" />
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default LoginForm;
