import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Form, Input, Switch, Row, Col, Spin
} from 'antd';
import { useIntl } from 'react-intl';
import { CompactPicker } from 'react-color';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, usePost, usePut } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import { PROJECT_STATUS, PROJECT_STATUS_LIST } from 'modules/project/_constants/cacheKeys';
import { iconList } from '../static/IconListStatic';

const FormItem = Form.Item;

const StatusForm = () => {
  const intl = useIntl();
  const { goBack } = useHistory();
  const { statusId } = useParams();
  const { id: organizationId } = useOrganization();

  const [form, setForm] = useState({
    id: null,
    organizationId,
    name: null,
    icon: null,
    color: '#999999',
    finished: false
  });

  const cacheKey = [PROJECT_STATUS, statusId];
  const { data, isLoading } = useGet(cacheKey, '/project/status', {
    params: { id: statusId },
    query: { enabled: Boolean(statusId) }
  });
  useEffect(() => {
    if (data) setForm(data[0]);
  }, [data]);

  const options = {
    fetch: { body: form },
    onSuccess: goBack,
    cacheInvalidate: [
      [PROJECT_STATUS_LIST, organizationId],
      statusId && [PROJECT_STATUS, statusId]
    ]
  };
  const { mutate, isLoading: isLoadingMutate } = usePost(() => '/project/status', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/project/status/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const renderIconList = () => Object.entries(iconList).map(([key, Icon]) => (
    <Col key={key} xs={6} md={3} lg={2} xl={1}>
      <Icon
        style={{
          fontSize: '22px',
          cursor: 'pointer',
          color:
                form.icon === key
                  ? form.color
                  : '#B3B3B3'
        }}
        onClick={() => setFormData({ icon: key })}
      />
    </Col>
  ));

  return (
    <Spin spinning={isLoading || isLoadingMutate || isLoadingUpdate}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'name' })}
        >
          <Input
            className="uppercase"
            onChange={e => setFormData({ name: e.target.value })}
            value={form.name}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'icon' })}
        >
          <div
            style={{
              padding: 5,
              backgroundColor: '#fff',
              borderRadius: 4
            }}
          >
            <Row gutter={6}>{renderIconList()}</Row>
          </div>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'color' })}
        >
          <CompactPicker
            color={form.color}
            onChangeComplete={color => setFormData({ color: color.hex })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'finished' })}
        >
          <Switch
            checked={form.finished}
            onChange={finished => setFormData({ finished })}
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!statusId ? mutate() : mutateUpdate(statusId))}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default StatusForm;
