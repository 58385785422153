import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, TreeSelect } from 'antd';
import { injectIntl } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

const FormItem = Form.Item;

const ResourceForm = props => {
  const {
    getStateForm, setStateForm, intl, getDataGrouper, onStore, onCancel, getLoading
  } = props;

  return (
    <Form className="soft-box">
      <FormItem
        {...defaultFormItemLayout}
        label={intl.formatMessage({ id: 'grouper' })}
      >
        <TreeSelect
          dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
          treeData={getDataGrouper}
          onChange={value => setStateForm({ parentId: value })}
          value={getStateForm.parentId}
          allowClear
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={intl.formatMessage({ id: 'name' })}
      >
        <Input
          className="uppercase"
          maxLength={50}
          onChange={e => setStateForm({ name: e.target.value })}
          value={getStateForm.name}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={intl.formatMessage({ id: 'route' })}
      >
        <Input
          maxLength={100}
          onChange={e => setStateForm({ route: e.target.value })}
          value={getStateForm.route}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={intl.formatMessage({ id: 'method' })}
      >
        <Input
          className="uppercase"
          maxLength={100}
          onChange={e => setStateForm({ method: e.target.value })}
          value={getStateForm.method}
        />
      </FormItem>

      <FormSubmitButtons
        onStore={onStore}
        onCancel={onCancel}
        loading={getLoading}
      />
    </Form>
  );
};

ResourceForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onStore: PropTypes.func.isRequired,
  setStateForm: PropTypes.func.isRequired,
  getStateForm: PropTypes.shape({}).isRequired,
  getDataGrouper: PropTypes.shape([]).isRequired,
  getLoading: PropTypes.bool.isRequired
};

export default injectIntl(ResourceForm);
