import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Menu, Dropdown, Table, Modal, Tag, Select
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import { useGet, useDelete } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import { PROJECT_TAG_LIST } from 'modules/project/_constants/cacheKeys';
import tagRefStatic from '../static/tagRefStatic';

const { confirm } = Modal;
const { Option } = Select;

const TagList = () => {
  const intl = useIntl();
  const { push } = useHistory();
  const [ref, setRef] = useState('tsk');
  const { id: organizationId } = useOrganization();

  const { data, isLoading } = useGet([PROJECT_TAG_LIST, organizationId, ref], '/project/tag', { params: { ref } });

  const { mutate } = useDelete(id => `/project/tag/${id}`, { cacheInvalidate: [[PROJECT_TAG_LIST, organizationId]] });

  const refOptions = Object.entries(tagRefStatic).map(
    ([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} values={{ plural: false }} />
      </Option>
    )
  );

  const renderMenuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => push(`tag/${id}/edit`)}
          >
            <FormattedMessage id="edit" />
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.delete_item' }),
                onOk: () => mutate(id)
              });
            }}
          >
            <FormattedMessage id="delete" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      key: 'status',
      render: ({ id, name, color }) => <Tag key={`${id}`} color={color}>{name}</Tag>
    },
    {
      key: 'actions',
      width: '3%',
      align: 'right',
      render: (text, record) => renderMenuActions(record.id)
    }
  ];

  return (
    <>
      <Select
        style={{ width: '180px', marginBottom: 5 }}
        onChange={r => setRef(r)}
        value={ref}
      >
        {refOptions}
      </Select>

      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        showHeader={false}
        size="middle"
        loading={isLoading}
        pagination={false}
      />
    </>
  );
};

export default TagList;
