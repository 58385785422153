import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Input, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, usePut } from 'common/api';

// FIXME: project domain logic on a common component
import { PROJECT_FILE_LIST } from 'modules/project/_constants/cacheKeys';

const FormItem = Form.Item;
const { TextArea } = Input;

const FileForm = () => {
  const { goBack } = useHistory();
  const { fileId } = useParams();
  const [form, setForm] = useState({
    id: fileId,
    name: null,
    extension: null,
    description: null
  });

  const { data, isLoading } = useGet(['FILE', fileId], `/shared/file/${fileId}`);
  useEffect(() => {
    if (data) setForm(data);
  }, [data]);

  const options = {
    fetch: { body: form },
    onSuccess: goBack,
    cacheInvalidate: [
      ['FILE', fileId],
      [PROJECT_FILE_LIST] // FIXME: project domain logic on a common component
    ]
  };
  const { mutate, isLoading: isLoadingMutate } = usePut(id => `/shared/file/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  return (
    <Spin spinning={isLoading || isLoadingMutate}>
      <Form>
        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="name" />}
        >
          <Input
            onChange={e => setFormData({ name: e.target.value })}
            value={form.name}
            maxLength={120}
            autoFocus
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="extension" />}
        >
          <b>{form.extension}</b>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="description" />}
        >
          <TextArea
            onChange={e => setFormData({ description: e.target.value })}
            value={form.description}
            maxLength={250}
            rows={2}
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => mutate(fileId)}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default FileForm;
