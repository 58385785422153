import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Spin } from 'antd';
import { useIntl } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { usePost } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import { DEBT_CALCULATION, DEBT_CALCULATION_LIST } from 'modules/debt/_constants/cacheKeys';
import CalculationSelectableTree from './CalculationSelectableTree';

const FormItem = Form.Item;

const CalculationForm = () => {
  const history = useHistory();
  const intl = useIntl();
  const { debtId, calculationId } = useParams();
  const { id: organizationId } = useOrganization();

  const [form, setForm] = useState({
    debtId,
    calculationList: []
  });

  const options = {
    fetch: { body: form },
    onSuccess: history.goBack,
    cacheInvalidate: [
      [DEBT_CALCULATION_LIST, organizationId],
      calculationId ? [DEBT_CALCULATION, calculationId] : null
    ]
  };
  const { mutate, isLoading } = usePost(() => '/debt/debt-calculation', options);

  const setFormData = value => setForm({ ...form, ...value });

  return (
    <Spin spinning={isLoading}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'shared_calculations' })}
        >
          <CalculationSelectableTree
            onCheck={calculationList => setFormData({ calculationList })}
            shared
          />
        </FormItem>

        <FormSubmitButtons
          onStore={mutate}
          onCancel={history.goBack}
          storeMessage={intl.formatMessage({ id: 'add' })}
        />
      </Form>
    </Spin>
  );
};

export default CalculationForm;
