import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, usePatch } from 'common/api';

import { PROJECT_FILE, PROJECT_FILE_LIST } from 'modules/project/_constants/cacheKeys';

import FolderList from 'modules/project/folder/components/FolderList';

const FormItem = Form.Item;

const FileMoveForm = () => {
  const { goBack } = useHistory();
  const { fileId } = useParams();
  const [folderId, setFolderId] = useState('');

  const { data, isLoading } = useGet([PROJECT_FILE, fileId], `/shared/file/${fileId}`);

  const options = {
    fetch: { body: { fileId, folderId } },
    onSuccess: goBack,
    cacheInvalidate: [[PROJECT_FILE_LIST]]
  };
  const { mutate, isLoading: isLoadingMutate } = usePatch(() => '/project/folder/file/move', options);

  return (
    <Spin spinning={isLoading || isLoadingMutate}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="file" values={{ plural: false }} />}
        >
          <b>{`${data?.name}${data?.extension}`}</b>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="destination" />}
        >

          <FolderList
            onSelect={setFolderId}
            hideButtons
          />
        </FormItem>

        <FormSubmitButtons
          onStore={mutate}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default FileMoveForm;
