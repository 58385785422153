/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';
import { changeKeyName } from 'common/utils/misc';

import dataUrlToBlob from 'common/utils/dataUrlToBlob';
import UserForm from './UserForm';

class UserFormPage extends Component {
  state = {
    form: {
      id: null,
      username: null,
      name: null,
      email: null,
      password: null,
      passwordConfirmation: null,
      ldap: false,
      superAdmin: false,
      change: false,
      // id do contato de e-mail que está sendo alterado, para alteração de cadastro.
      contactId: null,

      personId: null
    },

    autoCompleteData: [],

    urlImgProfile: '', // utilizado para mostrar a imagem na alteração de usuário.
    dataImgProfile: '', // Base64 da imagem capturada.
    loading: false,
    personFormVisible: false
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    if (id) {
      this.loadDataToUpdate(parseInt(id, 10));
    }
  }

  handleStore = async () => {
    const { history } = this.props;
    const { dataImgProfile } = this.state;
    let { form } = this.state;
    let response = null;

    this.setState({ loading: true });

    if (dataImgProfile) {
      form = {
        ...form,
        fileId: await this.uploadImage()
      };
    }

    if (!form.id) {
      response = await api('/access/user', {
        method: 'POST',
        body: this.getData(form)
      });
    } else {
      response = await api(`/access/user/${form.id}`, {
        method: 'PUT',
        body: this.getData(form)
      });
    }

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      history.goBack();
    }
  };

  handleAutoComplete = async value => {
    if (value.length > 2) {
      let body = await api('/shared/person', {
        body: { search: value }
      });

      body = body.map(data => {
        let newData = {};
        newData = changeKeyName(data, 'id', 'value');
        newData = changeKeyName(data, 'name', 'text');

        return newData;
      });

      this.setState({ autoCompleteData: body });
    }
  };

  uploadImage = async () => {
    const { dataImgProfile } = this.state;

    if (!dataImgProfile) {
      return null;
    }

    const formData = new FormData();
    formData.append(
      'file',
      dataUrlToBlob(dataImgProfile),
      'profile_image.png'
    );

    const response = await api('/shared/file/upload', {
      method: 'POST',
      body: formData,
      headers: {}
    });

    const content = await response.json();
    return content.fileList[0].id;
  };

  handleOnCompleteCropImg = imgData => {
    this.setState({ dataImgProfile: imgData });
  };

  loadDataToUpdate = async id => {
    this.setState({ loading: true });

    const body = await api('/access/user', {
      body: { id }
    });

    const {
      id: personId,
      person: { name },
      contact: {
        0: { id: contactId, description: email }
      }
    } = body[0];

    this.setState({
      form: {
        ...body[0],
        personId,
        name,
        email,
        contactId
      },
      loading: false
    });

    if (body[0].fileId) {
      this.setState({
        urlImgProfile: body[0].file.recordedName
      });
    }
  };

  getData = data => {
    const { form } = this.state;
    const newData = { ...data };

    if (form.id) {
      if (!data.password) {
        delete newData.password;
        delete newData.passwordConfirmation;
      }
    }

    return newData;
  };

  render() {
    const { history } = this.props;
    const { form, loading } = this.state;

    return (
      <UserForm
        onStore={this.handleStore}
        onAutoComplete={this.handleAutoComplete}
        onCompleteCropImg={this.handleOnCompleteCropImg}
        onCancel={() => history.goBack()}
        onShowPersonForm={() => this.setState({ personFormVisible: true })}
        onClosePersonForm={() => this.setState({ personFormVisible: false })}
        setStateForm={value => this.setState({
          form: { ...form, ...value }
        })}
        getStateForm={form}
        getState={this.state}
        loading={loading}
      />
    );
  }
}

export default UserFormPage;
