import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Tooltip, Button, Menu, Dropdown, Spin, Modal, Row, Col, Empty
} from 'antd';
import { MoreOutlined, UploadOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import { showFileUrl } from 'config/app';
import FileUpload from 'common/components/file/FileUpload';
import { useGet, usePost, useDelete } from 'common/api';
import FileIcon from 'common/components/file/FileIcon';

import FolderList from 'modules/project/folder/components/FolderList';

import { PROJECT_FILE_LIST } from 'modules/project/_constants/cacheKeys';

import '../css/file.css';

const { confirm } = Modal;

const FileList = props => {
  const { folderId, projectId } = useParams();
  const { push, replace } = useHistory();
  const intl = useIntl();
  const [fileList, setFileList] = useState([]);
  const [showUpload, setShowUpload] = useState(false);

  const cleanClose = () => {
    setFileList([]);
    setShowUpload(false);
  };

  const cacheKey = [PROJECT_FILE_LIST, folderId];
  const { data, isLoading } = useGet(cacheKey, `/project/folder/file/${folderId}`, { query: { enabled: Boolean(folderId) } });

  const options = {
    fetch: { body: { folderId, fileList: fileList?.map(f => f.response?.fileList[0].id) } },
    onSuccess: cleanClose,
    cacheInvalidate: [cacheKey]
  };
  const { mutate, isLoading: isLoadingMutate } = usePost(() => '/project/folder/file', options);

  const { mutate: mutateDelete } = useDelete(id => `/project/folder/file/${folderId}/${id}`, { cacheInvalidate: [cacheKey] });

  const onFolderSelect = id => {
    const url = `/project/${projectId}/file`;

    if (id) {
      replace(`${url}/${id}`);
    } else {
      replace(url);
    }
  };

  const menuActions = fileId => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => push(`${fileId}/move`)}
          >
            <FormattedMessage id="move" />
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => push(`${fileId}/edit`)}
          >
            <FormattedMessage id="edit" />
          </Menu.Item>

          <Menu.Item
            key="2"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.delete_item' }),
                onOk: () => mutateDelete(fileId)
              });
            }}
          >
            <FormattedMessage id="delete" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined
        className="more-options"
        style={{ float: 'right' }}
      />
    </Dropdown>
  );

  const renderList = () => {
    const list = data?.map(item => (
      <li key={item.id}>
        <FileIcon
          extension={item.extension}
          className="file-icon"
          size={20}
        />
        <a href={`${showFileUrl}/${item.recordedName}`} target="_blank" rel="noopener noreferrer">
          {`${item.name}${item.extension}`}
        </a>
        {menuActions(item.id)}
        {item.description && <small>{item.description}</small>}
      </li>
    ));

    if (!folderId) {
      return (
        <div style={{ marginTop: 10 }}>
          <FormattedMessage id="message.select_folder" />
          ...
        </div>
      );
    }

    if (data?.length > 0) {
      return (
        <ul className="file-list">
          <Spin spinning={isLoading}>{list}</Spin>
        </ul>
      );
    }

    return (
      <div style={{ marginTop: 10 }}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  };

  return (
    <Row gutter={20} className="soft-box">
      <Col xs={24} md={12} lg={10}>
        <FolderList onSelect={onFolderSelect} />
      </Col>
      <Col xs={24} md={12} lg={14}>
        <Tooltip title={<FormattedMessage id="add" />}>
          <Button
            icon={<UploadOutlined />}
            onClick={() => setShowUpload(true)}
            type="primary"
            disabled={!folderId}
          >
            <FormattedMessage id="send_file" />
          </Button>
        </Tooltip>
        {renderList(props)}

        <Modal
          visible={showUpload}
          title={false}
          onOk={mutate}
          onCancel={cleanClose}
          closable={false}
          style={{ top: 20 }}
        >
          <Spin spinning={isLoadingMutate}>
            <FileUpload
              onChange={setFileList}
              fileList={fileList}
            />
          </Spin>
        </Modal>
      </Col>
    </Row>
  );
};

export default FileList;
