import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Row, Col, Avatar, Spin, Empty
} from 'antd';
import moment from 'moment';

const NotificationMenuList = ({ loading, data }) => {
  const list = data.map(value => {
    const description = value.link ? (
      <Link to={value.link}>{value.description}</Link>
    ) : (
      value.description
    );
    const icon = value.icon ? value.icon : 'bell';
    const cssRead = value.read ? '' : 'not-read';

    return (
      <li key={value.id} className={cssRead}>
        <Row type="flex" align="middle">
          <Col span={4}>
            <Avatar
              src={value.img_url}
              icon={icon}
              size="large"
              shape="square"
            />
          </Col>
          <Col span={20} className="description">
            <span>
              {description}
              <br />
              <small>{moment.utc(value.created_at).fromNow()}</small>
            </span>
          </Col>
        </Row>
      </li>
    );
  });

  if (!data.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  return (
    <div className="notification-list">
      <Spin spinning={loading}>
        <ul
          style={{
            overflowY: 'auto',
            listStyleType: 'none',
            padding: 0,
            fontSize: '12px'
          }}
        >
          {list}
        </ul>
      </Spin>
    </div>
  );
};

NotificationMenuList.propTypes = {
  data: PropTypes.shape([]).isRequired,
  loading: PropTypes.bool.isRequired
};

export default NotificationMenuList;
