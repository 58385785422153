import React from 'react';
import { Progress } from 'antd';

import './styles.css';

const CardPercent = ({
  percent, title, children, color
}) => (
  <div className="card-percent">
    <div className="progress">
      <Progress type="circle" percent={percent} width={60} strokeColor={color} />
    </div>
    <div style={{ width: '100%' }}>
      <small>
        {title}
      </small>
      <div className="content">
        {children}
      </div>
    </div>
  </div>
);

export default CardPercent;
