import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Spin, Select } from 'antd';
import { useIntl } from 'react-intl';

import { api } from 'config/api';

import '../css/project.css';

const ProjectSearchAutocomplete = ({ selectedLink, size }) => {
  const definedLink = selectedLink || (() => {});
  const intl = useIntl();
  const { push } = useHistory();

  const [data, setData] = useState([]);
  const [loadingAutoComplete, setLoadingAutoComplete] = useState(false);

  let debounceTimeout = {};

  const searchAutoComplete = async value => {
    if (value.length === 0) { return; }

    setLoadingAutoComplete(true);

    const result = await api('/project/project', {
      body: {
        search: value,
        orderBy: 'name'
      }
    });

    setData(result.rows);
    setLoadingAutoComplete(false);
  };

  const onAutoCompleteDebounce = inputValue => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(searchAutoComplete, 300, inputValue);
  };

  const options = data.map(d => ({
    value: d.id,
    label: `${d.shortName ? `${d.shortName} | ` : ''}${d.name}`
  }));

  return (
    <Select
      placeholder={intl.formatMessage({ id: 'find' })}
      notFoundContent={loadingAutoComplete ? <Spin size="small" /> : null}
      filterOption={false}
      onSearch={onAutoCompleteDebounce}
      onChange={id => push(`${definedLink(id) || `${id}/resume`}`)}
      showSearch
      style={{ width: '100%', marginBottom: 20 }}
      options={options}
      size={size || 'default'}
    />
  );
};

export default ProjectSearchAutocomplete;
