import React, { Component } from 'react';
import { api } from 'config/api';

import NotificationMenuList from './NotificationMenuList';

class NotificationMenuListContainer extends Component {
  state = {
    data: [],
    loading: false
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });

    const body = await api('/shared/notification', {
      body: { limit: 5 }
    });

    this.setState({
      data: body,
      loading: false
    });

    if (body.length > 0) {
      this.makeAllRead();
    }
  };

  makeAllRead = async () => {
    await api('/shared/notification/make-all-read', {
      method: 'PUT'
    });
  };

  render() {
    const { data, loading } = this.state;

    return (
      <NotificationMenuList
        data={data}
        loading={loading}
      />
    );
  }
}

export default NotificationMenuListContainer;
