import React from 'react';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';

import '../css/calculation.css';

const CalculationFormHelp = () => (
  <Collapse
    style={{
      marginTop: 6,
      borderRadius: 4
    }}
    bordered={false}
  >
    <Collapse.Panel
      header={(
        <span>
          <FormattedMessage id="help" />
          {' - Funções'}
        </span>
      )}
      key="1"
      style={{ borderBottom: 0 }}
    >
      <p>
        Todas as funções do ECMAScript (Javascript) estão disponíveis, porém,
        também há disponível outras funções matemáticas e financeiras de
        módulos adicionais. Abaixo relação das funções mais utilizadas:
      </p>
      <ul className="help-list functions">
        <li>
          <h4>diff360(data, data)</h4>
          Retorna a quantidade de dias entre duas datas, considerando o ano
          comercial de 360 dias.
        </li>
        <li>
          <h4>Math.pow(base, expoente)</h4>
          Retorna a base elevada ao expoente.
        </li>
        <li>
          <h4>pmt(tx, parcelasRestantes, vp, vf, tipo)</h4>
          Calcula o valor de uma parcela (similar a fórmula PGTO do Excel).
        </li>
        <li>
          <h4>roundNumber(valor, casasDecimais)</h4>
          Retorna o valor decimal arredondado.
        </li>
        <li>
          <h4>momentjs(data)</h4>
          Biblioteca Moment.JS para trabalhar com datas.
        </li>
        <li>
          <h4>verificarFeriado(data)</h4>
          Verifica se uma determinada data é feriado com base nos feriados cadastrados no sistema.
          <br />
          <i>ex: verificarFeriado($dataInicio)</i>
        </li>
      </ul>
    </Collapse.Panel>
  </Collapse>
);

export default CalculationFormHelp;
