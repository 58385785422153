import qs from 'qs';
import { useQuery } from 'react-query';

import { useOrganization } from 'core/_contexts/OrganizationContext';
import { useConfig } from 'core/_contexts/ConfigContext';
import responseHandler from '../../helpers/responseHandler';

import { getApiUrl, getDefaultFetchOptions } from '../apiUtils';

const fetchGet = options => async () => {
  const {
    url,
    params,
    fetch: fetchOptions,
    organization,
    locale
  } = options;

  const apiUrl = getApiUrl({ url, organization });

  const otherParams = params ? qs.stringify(params) : '';
  const op = apiUrl.match(/.\?+/) ? '&' : '?';
  const completeUrl = `${apiUrl}${otherParams && `${op}${otherParams}`}`;

  const httpOptions = {
    ...getDefaultFetchOptions({ locale }),
    ...fetchOptions,
    credentials: 'include',
    method: 'GET'
  };

  const res = await fetch(completeUrl, httpOptions);
  const body = await res.json();

  return responseHandler(res, body, { notify: false, emptyIfError: true });
};

const defaultOptions = {
  staleTime: 1000 * 60 * 1 // 1 min
};

export default (cacheKey, url, options) => {
  const organization = useOrganization();
  const { locale } = useConfig();

  const queryOptions = {
    ...defaultOptions,
    ...options?.query
  };

  return useQuery(cacheKey, fetchGet({
    url, ...options, organization, locale
  }), queryOptions);
};
