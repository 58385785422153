import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Tooltip, Button, Menu, Dropdown, Table, Modal
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { useGet, useDelete } from 'common/api';
import { useOrganization } from 'core/_contexts/OrganizationContext';

import { DEBT_INSTALLMENT, DEBT_CALCULATION_LIST } from 'modules/debt/_constants/cacheKeys';

import CalculationTypeStatic from '../static/CalculationTypeStatic';

const { confirm } = Modal;

const CalculationListContainer = props => {
  const { onlyShared, baseUrl, hideButtonAddShared } = props;
  const intl = useIntl();
  const { push } = useHistory();
  const { debtId } = useParams();
  const { id: organizationId } = useOrganization();

  const cacheKey = [DEBT_CALCULATION_LIST, organizationId, debtId || 'shared'];

  const { data, isLoading } = useGet(cacheKey, '/debt/calculation', {
    params: {
      organizationId,
      debtId,
      shared: onlyShared
    }
  });

  const { mutate: mutateDelete } = useDelete(id => `/debt/calculation/${id}`, {
    cacheInvalidate: [[DEBT_CALCULATION_LIST, organizationId]]
  });

  const { mutate: mutateRemove } = useDelete(id => `/debt/debt-calculation/${debtId}/${id}`, {
    cacheInvalidate: [
      [DEBT_CALCULATION_LIST, organizationId, debtId],
      [DEBT_INSTALLMENT, debtId]
    ]
  });

  const menuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => push(`${baseUrl}${id}/edit`)}
          >
            {intl.formatMessage({ id: 'edit' })}
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.inactivate_item' }),
                onOk: () => mutateDelete(id)
              });
            }}
          >
            {intl.formatMessage({ id: 'inactivate' })}
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const sharedMenuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.remove_item' }),
                onOk: () => mutateRemove(id)
              });
            }}
          >
            {intl.formatMessage({ id: 'remove' })}
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderButtonAddShared = () => {
    if (!hideButtonAddShared) {
      return (
        <Menu.Item
          key="1"
          onClick={() => push(`${baseUrl}add-shared`)}
        >
          {intl.formatMessage({ id: 'shared' })}
        </Menu.Item>
      );
    }

    return null;
  };

  const renderTableColumns = () => [
    {
      title: intl.formatMessage({ id: 'calculation' }, { plural: true }),
      dataIndex: 'name',
      key: 'name',
      width: '30%'
    },
    {
      title: intl.formatMessage({ id: 'description' }),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: intl.formatMessage({ id: 'type' }),
      dataIndex: 'type',
      render: type => intl.formatMessage({ id: CalculationTypeStatic[type] }, { plural: false }),
      key: 'type',
      width: '15%'
    },
    {
      key: 'actions',
      width: '3%',
      align: 'right',
      render: (text, { id, shared }) => (
        (!shared || (shared && !debtId)) ? menuActions(id) : sharedMenuActions(id)
      )
    }
  ];

  return (
    <div className="list-with-buttons">
      <span className="buttons">
        <Tooltip title={intl.formatMessage({ id: 'add' })}>

          <Dropdown
            trigger={['click']}
            overlay={(
              <Menu>
                <Menu.Item
                  key="0"
                  onClick={() => push(`${baseUrl}add`)}
                >
                  {intl.formatMessage({ id: 'new' })}
                </Menu.Item>
                {renderButtonAddShared()}
              </Menu>
            )}
          >
            <Button icon={<PlusOutlined />} />
          </Dropdown>
        </Tooltip>
      </span>

      <div className="list-container">
        <Table
          dataSource={data}
          rowKey="id"
          columns={renderTableColumns()}
          size="middle"
          loading={isLoading}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default CalculationListContainer;
