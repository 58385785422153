import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Table, Dropdown, Menu, Tag, Modal
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { useGet, useDelete } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import { PROJECT_LIST } from 'modules/project/_constants/cacheKeys';
import ProjectSearchAutocomplete from './ProjectSearchAutocomplete';

import '../css/project.css';

const { confirm } = Modal;

const renderTags = tags => tags.map(({ id, name, color }) => <Tag key={`${id}`} color={color}>{name}</Tag>);

const ProjectList = props => {
  const { selectedLink, hideMenuActions, hideAutoComplete } = props;
  const { formatMessage } = useIntl();
  const definedLink = selectedLink || (() => {});

  const { id: organizationId } = useOrganization();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 30,
    total: null
  });

  const {
    data,
    isLoading
  } = useGet([PROJECT_LIST, organizationId, pagination.current],
    '/project/project', {
      params: {
        filters: { orderBy: 'name' },
        page: pagination.current,
        pageSize: pagination.pageSize,
      },
      query: { keepPreviousData: true }
    });

  useEffect(() => {
    if (data?.rows) setPagination({ ...pagination, total: data.count });
  }, [data]);

  const destroy = useDelete(id => `/project/project/${id}`, { cacheInvalidate: [PROJECT_LIST] });

  const menuActions = ({ id }) => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`${id}/edit`}>
              {formatMessage({ id: 'edit' })}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: formatMessage({ id: 'message.inactivate_item' }),
                onOk: () => destroy.mutate(id)
              });
            }}
          >
            {formatMessage({ id: 'inactivate' })}
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [{
    key: 'name',
    render: ({
      id, name, shortName, tag
    }) => (
      <>
        {shortName && <small>{shortName}</small>}
        <Link to={`${definedLink(id) || `${id}/resume`}`}>{name}</Link>
        <div style={{ marginTop: 4 }}>
          {renderTags(tag)}
        </div>
      </>
    )
  }, {
    key: 'actions',
    width: '3%',
    align: 'right',
    render: (text, record) => {
      if (hideMenuActions) return null;
      return menuActions(record);
    }
  }];

  const onTableChange = curPage => {
    setPagination(curPage);
  };

  return (
    <>
      {!hideAutoComplete && <ProjectSearchAutocomplete selectedLink={selectedLink} />}

      <Table
        dataSource={data?.rows}
        rowKey="id"
        columns={renderTableColumns(props)}
        showHeader={false}
        size="middle"
        pagination={{ ...pagination }}
        onChange={onTableChange}
        loading={isLoading}
        className="project-list"
      />
    </>
  );
};

export default ProjectList;
