import React from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { AuthProvider } from 'core/_contexts/AuthContext';
import { OrganizationProvider } from 'core/_contexts/OrganizationContext';
import { ModuleProvider } from 'core/_contexts/ModuleContext';
import { ConfigProvider } from 'core/_contexts/ConfigContext';
import LocalizationProvider from 'core/providers/LocalizationProvider';

import Routes from './Routes';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <OrganizationProvider>
        <ModuleProvider>
          <ConfigProvider>
            <LocalizationProvider>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </LocalizationProvider>
          </ConfigProvider>
        </ModuleProvider>
      </OrganizationProvider>
    </AuthProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>
);

export default App;
