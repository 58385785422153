import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ReportList from 'common/components/report/ReportList';
import DebtDashboard from 'modules/debt/debtDashboard/components';
import InstallmentList from 'modules/debt/installment/pages/InstallmentList';
import InstallmentForm from 'modules/debt/installment/pages/InstallmentlForm';
import InstallmentGenerateForm from 'modules/debt/installment/pages/InstallmentGenerateForm';
import InstallmentDetails from 'modules/debt/installment/pages/InstallmentDetails';
import OperationList from 'modules/debt/operation/pages/OperationList';
import OperationForm from 'modules/debt/operation/pages/OperationForm';
import CalculationList from 'modules/debt/calculation/components/CalculationList';
import CalculationForm from 'modules/debt/calculation/components/CalculationForm';
import CalculationAddSharedForm from 'modules/debt/calculation/components/CalculationAddSharedForm';
import { DebtYearProvider } from 'modules/debt/_contexts/DebtYearContext';
import { InstallmentProvider } from 'modules/debt/_contexts/InstallmentContext';

const DebtDetailsRoutes = () => {
  const url = '/debt/:projectId/:debtId';

  return (
    <Switch>
      <DebtYearProvider>
        <Route
          path={`${url}/dashboard`}
          component={DebtDashboard}
          exact
        />
        <Route
          path={`${url}/operation`}
          component={OperationList}
          exact
        />
        <Route
          path={`${url}/operation/add`}
          component={OperationForm}
        />
        <Route
          path={`${url}/operation/:operationId/edit`}
          component={OperationForm}
        />
        <Route
          path={`${url}/calculation`}
          exact
          component={() => <CalculationList baseUrl="calculation/" />}
        />
        <Route
          path={`${url}/calculation/add`}
          component={CalculationForm}
        />
        <Route
          path={`${url}/calculation/:calculationId/edit`}
          component={CalculationForm}
        />
        <Route
          path={`${url}/calculation/add-shared`}
          component={CalculationAddSharedForm}
        />
        <Route
          path={`${url}/report`}
          component={() => <ReportList domain="debt-debt" />}
          exact
        />
        <InstallmentProvider>
          <Switch>
            <Route
              path={`${url}/installment`}
              component={InstallmentList}
              exact
            />
            <Route
              path={`${url}/installment/:installmentId/details`}
              component={InstallmentDetails}
            />
            <Route
              path={`${url}/installment/add-manual`}
              component={InstallmentForm}
            />
            <Route
              path={`${url}/installment/:installmentId/edit`}
              component={InstallmentForm}
            />
            <Route
              path={`${url}/installment/add-auto`}
              component={InstallmentGenerateForm}
            />
          </Switch>
        </InstallmentProvider>
      </DebtYearProvider>
    </Switch>
  );
};

export default DebtDetailsRoutes;
