import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Table, Button, Tooltip, Dropdown, Menu, Modal
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

import ContentWithLeftButtons from 'common/components/ContentWithLeftButtons';

const { confirm } = Modal;

const ResourceList = props => {
  const {
    intl, onGoToForm, onDestroy, data, loading
  } = props;

  const renderButtons = () => (
    <Tooltip title={intl.formatMessage({ id: 'add' })}>
      <Button
        onClick={onGoToForm}
        icon={<PlusOutlined />}
        style={{ paddingTop: '1px' }}
      />
    </Tooltip>
  );

  const menuActions = record => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`/access/resource/edit/${record.key}`}>
              <FormattedMessage id="edit" />
            </Link>
          </Menu.Item>

          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.delete_item' }),
                onOk: () => onDestroy(record.key)
              });
            }}
          >
            <FormattedMessage id="delete" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      key: 'title',
      render: (text, record) => `${record.title}`,
      width: '50%'
    },
    {
      key: 'route',
      render: (text, record) => (record.route
        ? `${record.method ? record.method : 'all'}: ${record.route}`
        : ''),
      width: '47%'
    },
    {
      key: 'actions',
      title: '',
      width: '3%',
      align: 'right',
      render: (text, record) => menuActions(record)
    }
  ];

  return (
    <ContentWithLeftButtons buttons={renderButtons()}>
      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        size="middle"
        pagination={false}
        showHeader={false}
        loading={loading}
      />
    </ContentWithLeftButtons>
  );
};
ResourceList.propTypes = {
  data: PropTypes.shape([]).isRequired,
  onDestroy: PropTypes.func.isRequired,
  onGoToForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default injectIntl(ResourceList);
