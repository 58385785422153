import React from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import {
  Tooltip, Button, Menu, Dropdown, Table, Modal,
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { useGet, useDelete } from 'common/api';
import ContentWithLeftButtons from 'common/components/ContentWithLeftButtons';

import { DEBT_LIST } from 'modules/debt/_constants/cacheKeys';

import DebtStatusStatic from '../static/DebtStatusStatic';

const { confirm } = Modal;

const DebtList = () => {
  const intl = useIntl();
  const history = useHistory();
  const { projectId } = useParams();

  const cacheKey = [DEBT_LIST, projectId];

  const { data, isLoading } = useGet(cacheKey, '/debt/debt', { params: { projectId } });

  const { mutate } = useDelete(id => `/debt/debt/${id}`, { cacheInvalidate: [cacheKey] });

  const menuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`${id}/edit`}>
              {intl.formatMessage({ id: 'edit' })}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.inactivate_item' }),
                onOk: () => mutate(id)
              });
            }}
          >
            {intl.formatMessage({ id: 'inactivate' })}
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [{
    title: intl.formatMessage({ id: 'debt' }, { plural: true }),
    render: ({ id, name }) => <Link to={`../${projectId}/${id}/dashboard`}>{name}</Link>,
    key: 'name',
  }, {
    title: 'Status',
    render: ({ status }) => intl.formatMessage({ id: DebtStatusStatic[status] }),
    key: 'status',
    width: '20%',
  }, {
    title: intl.formatMessage({ id: 'amount' }),
    render: ({ value, currency }) => (
      intl.formatNumber(value, { minimumFractionDigits: 2, currency: currency.symbol, style: 'currency' })
    ),
    key: 'amount',
    width: '25%',
    align: 'right',
  }, {
    key: 'actions',
    width: '3%',
    align: 'right',
    render: ({ id }) => menuActions(id),
  }];

  const renderButtons = () => (
    <Tooltip title={intl.formatMessage({ id: 'add' })}>
      <Button
        onClick={() => history.push('add')}
        icon={<PlusOutlined />}
      />
    </Tooltip>
  );

  return (
    <ContentWithLeftButtons buttons={renderButtons()}>
      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        size="middle"
        loading={isLoading}
        pagination={false}
        style={{ display: 'inline-block', width: '100%' }}
      />
    </ContentWithLeftButtons>
  );
};

export default DebtList;
