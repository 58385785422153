import React from 'react';
import {
  Form, Input, Button, Select, Upload, DatePicker
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';
import uploadConfig from 'config/upload';
import moment from 'moment';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

const FormItem = Form.Item;
const { Option } = Select;

const DocForm = props => {
  const {
    getDataDocType,
    getFileList,
    getStateForm,
    setStateForm,
    onChangeFileUpload,
    onRemoveFileUpload,
    onStore,
    onCancel,
    loading,
    intl
  } = props;
  const docTypeOptions = getDataDocType.map(v => (
    <Option key={v.id.toString()} value={v.id.toString()}>
      {v.name}
    </Option>
  ));

  return (
    <Form className="soft-box">
      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="type" />}
      >
        <Select
          value={`${getStateForm.docTypeId}`}
          onChange={value => setStateForm({
            ...getStateForm,
            docTypeId: value
          })}
          style={{ display: 'inline-block' }}
        >
          {docTypeOptions}
        </Select>
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="number" />}
      >
        <Input
          onChange={e => setStateForm({
            ...getStateForm,
            number: e.target.value
          })}
          value={getStateForm.number}
          maxLength={50}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="expiration" />}
      >
        <DatePicker
          format={intl.formatMessage({
            id: 'date_format'
          })}
          onChange={date => setStateForm({
            dueOn: date || null
          })}
          value={getStateForm.dueOn ? moment(getStateForm.dueOn) : null}
          placeholder={intl.formatMessage({
            id: 'date_placeholder'
          })}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="note" />}
      >
        <Input
          onChange={e => setStateForm({
            ...getStateForm,
            note: e.target.value
          })}
          value={getStateForm.note}
          maxLength={120}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="image" />}
      >
        <div style={{ backgroundColor: '#ffffff', padding: '3px' }}>
          <Upload
            {...uploadConfig()}
            onChange={onChangeFileUpload}
            onRemove={onRemoveFileUpload}
            fileList={getFileList}
            accept=".jpg,.png,.pdf,.jpeg"
          >
            <Button
              size="default"
              icon={<UploadOutlined />}
              style={{ width: '180px' }}
            >
              <FormattedMessage id="select_file" />
            </Button>
          </Upload>
        </div>
      </FormItem>

      <FormSubmitButtons
        onStore={onStore}
        onCancel={onCancel}
        loading={loading}
      />
    </Form>
  );
};

export default injectIntl(DocForm);
