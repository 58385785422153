import React, {
  createContext, useContext, useState, useEffect
} from 'react';

const OrganizationContext = createContext();
const OrganizationUpdateContext = createContext();

export const useOrganization = () => useContext(OrganizationContext);
export const useOrganizationUpdate = () => useContext(OrganizationUpdateContext);

export const OrganizationProvider = ({ children }) => {
  const [data, setData] = useState(JSON.parse(localStorage.getItem('organization')));

  useEffect(() => {
    localStorage.setItem('organization', JSON.stringify(data));
  }, [data]);

  return (
    <OrganizationContext.Provider value={data}>
      <OrganizationUpdateContext.Provider value={setData}>
        { children }
      </OrganizationUpdateContext.Provider>
    </OrganizationContext.Provider>
  );
};
