import React, { useEffect } from 'react';
import {
  useParams, useLocation, useHistory, useRouteMatch
} from 'react-router-dom';
import {
  Row, Col, Menu, Divider
} from 'antd';
import { LineChartOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import DebtDetailsRoutes from 'modules/debt/_routes/DebtDetailsRoutes';
import { useDebt, useDebtUpdate } from '../../_contexts/DebtContext';

const DebtDetails = () => {
  const { debtId, projectId } = useParams();
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const updateDebtData = useDebtUpdate();
  const debtData = useDebt();

  const currentMenu = pathname.substr(pathname.lastIndexOf('/') + 1);

  useEffect(() => {
    updateDebtData(debtId);
  }, []);

  return (
    <>
      <h2 style={{ marginBottom: 10 }}>{debtData.name}</h2>

      <Row gutter={20}>
        <Col xs={24} sm={10} md={6} lg={5} xl={3}>
          <Menu mode="inline" defaultSelectedKeys={[currentMenu]}>
            <Menu.Item
              key="dashboard"
              onClick={() => push(`${url}/dashboard`)}
            >
              <FormattedMessage id="dashboard" />
            </Menu.Item>
            <Menu.Item
              key="installment"
              onClick={() => push(`${url}/installment`)}
            >
              <FormattedMessage
                id="installment"
                values={{ plural: true }}
              />
            </Menu.Item>
            <Menu.Item
              key="operation"
              onClick={() => push(`${url}/operation`)}
            >
              <FormattedMessage
                id="disbursement"
                values={{ plural: true }}
              />
            </Menu.Item>
            <Menu.Item
              key="calculation"
              onClick={() => push(`${url}/calculation`)}
            >
              <FormattedMessage
                id="calculation"
                values={{ plural: true }}
              />
            </Menu.Item>
            {/* <Menu.Item
              key="report"
              onClick={() => push(`${url}/report`)}
            >
              <FormattedMessage id="report" values={{ plural: true }} />
            </Menu.Item> */}
          </Menu>

          <Divider orientation="left" plain style={{ marginTop: 30 }}>
            <FormattedMessage id="module" values={{ plural: true }} />
          </Divider>

          <Menu mode="inline">
            <Menu.Item
              key="project"
              icon={<LineChartOutlined />}
              onClick={() => push(`/project/${projectId}/resume`)}
            >
              <FormattedMessage id="project" values={{ plural: false }} />
            </Menu.Item>
          </Menu>
        </Col>
        <Col xs={24} sm={14} md={18} lg={19} xl={21}>
          <DebtDetailsRoutes />
        </Col>
      </Row>
    </>
  );
};

export default DebtDetails;
