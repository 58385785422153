import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Progress,
  Tag,
  Tooltip,
  Spin,
  Modal
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { useGet, useDelete, usePatch } from 'common/api';

import { PROJECT_TASK, PROJECT_TASK_LIST, PROJECT_HISTORY_LIST } from 'modules/project/_constants/cacheKeys';

import '../css/task.css';

const ButtonGroup = Button.Group;
const { confirm } = Modal;

const itemLayout = {
  labelCol: {
    sm: { span: 24 },
    md: { span: 6 },
    xl: { span: 3 }
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 18 },
    xl: { span: 21 }
  }
};

const TaskDetail = () => {
  const { formatMessage } = useIntl();
  const { push, goBack } = useHistory();
  const { projectId, taskId } = useParams();

  const [taskData, setTaskData] = useState({
    lastHistory: {
      progress: 0,
      responsible: {
        name: null
      }
    },
    tag: []
  });

  const cacheKey = [PROJECT_TASK, taskId];

  const { data, isLoading } = useGet(cacheKey, '/project/task', {
    params: {
      id: taskId,
      finished: true
    }
  });

  useEffect(() => {
    if (data) setTaskData(data.rows[0]);
  }, [data]);

  const cacheInvalidate = [
    cacheKey,
    [PROJECT_TASK_LIST, projectId],
    [PROJECT_HISTORY_LIST, taskId]
  ];

  const { mutate: mutateDelete } = useDelete(() => `/project/task/${taskId}`, {
    cacheInvalidate,
    onSuccess: goBack
  });

  const { mutate: mutateReopen } = usePatch(() => `/project/task/reopen/${taskId}`, {
    cacheInvalidate
  });

  const { mutate: mutateFinish } = usePatch(() => `/project/task/finish/${taskId}`, {
    cacheInvalidate
  });

  const tags = taskData?.tag.map(v => <Tag key={v.id} color={v.color}>{v.name}</Tag>);

  const renderActionsButtons = () => {
    if (!taskData.finishedAt) {
      return (
        <ButtonGroup>
          <Tooltip title={formatMessage({ id: 'finish' })}>
            <Button
              icon={<CheckOutlined />}
              onClick={() => {
                confirm({
                  title: formatMessage({ id: 'message.finish_task' }),
                  onOk: () => mutateFinish()
                });
              }}
            />
          </Tooltip>
          <Tooltip title={formatMessage({ id: 'edit' })}>
            <Button
              icon={<EditOutlined />}
              onClick={() => push('edit')}
            />
          </Tooltip>
          <Tooltip title={formatMessage({ id: 'inactivate' })}>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                confirm({
                  title: formatMessage({ id: 'message.inactivate_item' }),
                  onOk: () => mutateDelete()
                });
              }}
            />
          </Tooltip>
        </ButtonGroup>
      );
    }
    return (
      <Tooltip title={formatMessage({ id: 'reopen' })}>
        <Button
          icon={<ReloadOutlined />}
          onClick={mutateReopen}
        />
      </Tooltip>
    );
  };

  return (
    <div className="task-detail">
      <Spin spinning={isLoading}>
        <h3
          style={{
            fontSize: '18px',
            marginBottom: 0
          }}
        >
          {taskData.name}
        </h3>
        <p>{taskData.description}</p>

        <Row gutter={16}>
          <Col xs={24} md={8} xl={4} style={{ textAlign: 'center' }}>
            <Progress type="circle" percent={taskData.lastHistory?.progress} />
          </Col>

          <Col xs={24} md={16} xl={20}>
            <div
              style={{
                padding: 10,
                backgroundColor: '#fff',
                borderRadius: 4
              }}
            >
              <Row>
                <Col {...itemLayout.labelCol} style={{ textAlign: 'right' }}>
                  <h4>
                    {formatMessage({ id: 'due_date' }, { plural: false })}
                    :
                  </h4>
                </Col>
                <Col {...itemLayout.wrapperCol}>
                  {taskData.dueAt ? moment(taskData.dueAt).format('L') : '-'}
                </Col>
              </Row>

              <Row>
                <Col {...itemLayout.labelCol} style={{ textAlign: 'right' }}>
                  <h4>
                    {formatMessage({ id: 'finished' })}
                    :
                  </h4>
                </Col>
                <Col {...itemLayout.wrapperCol}>
                  {!taskData.finishedAt
                    ? formatMessage({ id: 'no' })
                    : (
                      <span>
                        {moment(taskData.finishedAt).format('L H:mm')}
                        ,
                        {' '}
                        {formatMessage({ id: 'by' })}
                        {' '}
                        {taskData.lastHistory?.responsible.name}
                      </span>
                    )}
                </Col>
              </Row>

              <Row>
                <Col {...itemLayout.labelCol} style={{ textAlign: 'right' }}>
                  <h4>
                    {formatMessage({ id: 'tag' }, { plural: true })}
                    :
                  </h4>
                </Col>
                <Col {...itemLayout.wrapperCol}>
                  {tags.length > 0 ? tags : '-'}
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: 4 }}>{renderActionsButtons()}</div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default TaskDetail;
