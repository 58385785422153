import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Input, Table, Button, Tooltip, Menu, Dropdown, Alert
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

const { Search } = Input;

const PersonList = props => {
  const {
    getFound, data, getLoading, setSearchContent, onGoToForm, onSearch, intl
  } = props;

  const menuActions = record => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`person/${record.id}/details/edit`}>
              <FormattedMessage id="edit" />
            </Link>
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      dataIndex: 'name',
      key: 'name',
      width: '67%'
    },
    {
      dataIndex: 'doc_number',
      key: 'docs',
      width: '30%'
    },
    {
      key: 'actions',
      title: ' ',
      width: '3%',
      align: 'right',
      render: (text, record) => menuActions(record)
    }
  ];

  const renderTable = () => {
    if (getFound) {
      return (
        <Table
          dataSource={data}
          rowKey="id"
          columns={renderTableColumns()}
          size="middle"
          pagination={false}
          loading={getLoading}
          showHeader={false}
          style={{ marginTop: '10px' }}
        />
      );
    }
    return (
      <Alert
        message={<FormattedMessage id="search_person" />}
        type="info"
        style={{ marginTop: '10px' }}
      />
    );
  };

  return (
    <div>
      <Tooltip title={<FormattedMessage id="add" />}>
        <Button
          onClick={onGoToForm}
          icon={<PlusOutlined />}
          style={{ paddingTop: '1px' }}
        />
      </Tooltip>

      <Search
        enterButton
        autoFocus
        onSearch={onSearch}
        onChange={e => setSearchContent(e.target.value)}
        style={{ width: '240px', marginLeft: '8px' }}
        placeholder={`${intl.formatMessage({
          id: 'person_doc'
        })} ${intl.formatMessage({
          id: 'or'
        })} ${intl.formatMessage({
          id: 'name'
        })}`}
      />

      {renderTable()}
    </div>
  );
};

PersonList.propTypes = {
  data: PropTypes.shape([]).isRequired,
  onGoToForm: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  setSearchContent: PropTypes.func.isRequired,
  getLoading: PropTypes.bool.isRequired
};

export default injectIntl(PersonList);
