import React from 'react';
import { Link } from 'react-router-dom';
import {
  Menu, Dropdown, Table, Modal, Tooltip
} from 'antd';
import { MoreOutlined, CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import { dateToLocale } from 'common/utils/misc';

import CompositionTypeStatic from '../static/CompositionTypeStatic';

const { confirm } = Modal;

const CompositionListDetailed = props => {
  const {
    dataSource, loading, rowSelection, onDestroy, debtCurrency
  } = props;
  const { formatMessage } = useIntl();

  const menuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`details/composition/${id}/edit`}>
              <FormattedMessage id="edit" />
            </Link>
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: formatMessage({ id: 'message.delete_item' }),
                onOk: () => onDestroy(id)
              });
            }}
          >
            <FormattedMessage id="delete" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      title: <FormattedMessage id="date" />,
      render: ({ date, realized, checkOnly }) => (
        <>
          {dateToLocale(date)}
          {realized && (
            <Tooltip title={formatMessage({ id: 'realized' })}>
              <CheckCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          )}
          {checkOnly && (
            <Tooltip title={formatMessage({ id: 'check_only' })}>
              <MinusCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          )}
        </>
      ),
      key: 'period',
      width: '16%'
    },
    {
      title: <FormattedMessage id="type" />,
      render: ({ type }) => (
        <FormattedMessage
          id={CompositionTypeStatic.list[type]}
          values={{ plural: false }}
        />
      ),
      key: 'type',
      width: '10%',
      align: 'center'
    },
    {
      title: <FormattedMessage id="amortize" />,
      render: ({ amortize }) => (
        <FormattedMessage id={amortize ? 'yes' : 'no'} />
      ),
      key: 'amortize',
      width: '6%',
      align: 'center'
    },
    {
      title: <FormattedMessage id="calculation" values={{ plural: false }} />,
      render: ({ manual }) => (!manual ? 'Auto' : <span style={{ color: 'red' }}>Manual</span>),
      key: 'manual',
      width: '8%',
      align: 'center'
    },
    {
      title: <FormattedMessage id="description" />,
      render: ({ manual, description }) => (!manual ? (
        description
      ) : (
        <span style={{ color: 'red' }}>{description}</span>
      )),
      key: 'description'
    },
    {
      title: <FormattedMessage id="value" values={{ plural: false }} />,
      render: ({ value }) => (
        debtCurrency && (
          <FormattedNumber
            value={value}
            maximumFractionDigits={2}
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={debtCurrency.symbol}
          />
        )
      ),
      key: 'value',
      width: '14%',
      align: 'right'
    },
    {
      render: ({ id }) => menuActions(id),
      key: '7',
      width: '3%',
      align: 'right'
    }
  ];

  return (
    <Table
      dataSource={dataSource}
      rowKey="id"
      columns={renderTableColumns()}
      showHeader
      size="middle"
      loading={loading}
      pagination={false}
      rowSelection={rowSelection}
    />
  );
};

export default CompositionListDetailed;
