import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Form, Input, Select, Spin, InputNumber, DatePicker, Tooltip
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, usePost, usePut } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';
import { useConfig } from 'core/_contexts/ConfigContext';

import { SHARED_HOLIDAY, SHARED_HOLIDAY_LIST } from 'modules/shared/_constants/cacheKeys';

const FormItem = Form.Item;

const HolidayForm = () => {
  const intl = useIntl();
  const { goBack } = useHistory();
  const { holidayId } = useParams();
  const { id: organizationId } = useOrganization();
  const { locale } = useConfig();

  const [form, setForm] = useState({
    id: null,
    organizationId,
    country: 'BR', // fixme add field at the form
    description: null,
    day: null,
    month: null,
    year: null
  });

  const cacheKey = [SHARED_HOLIDAY, holidayId];
  const { data, isLoading } = useGet(cacheKey, '/shared/holiday', {
    params: { id: holidayId },
    query: { enabled: Boolean(holidayId) }
  });
  useEffect(() => {
    if (data) setForm(data[0]);
  }, [data]);

  const options = {
    fetch: { body: form },
    onSuccess: goBack,
    cacheInvalidate: [
      [SHARED_HOLIDAY_LIST],
      holidayId && [SHARED_HOLIDAY, holidayId]
    ]
  };
  const { mutate, isLoadingMutate } = usePost(() => '/shared/holiday', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(() => `/shared/holiday/${holidayId}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const monthList = [...Array(12).keys()].map(month => {
    const timestamp = Date.UTC(2021, month);
    return {
      value: `${month + 1}`.padStart(2, '0'),
      label: new Intl.DateTimeFormat(locale, { timeZone: 'UTC', month: 'long' }).format(timestamp)
    };
  });

  return (
    <Spin spinning={isLoadingMutate || isLoadingUpdate || isLoading}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <Input
            maxLength={60}
            onChange={e => setFormData({ description: e.target.value })}
            value={form.description}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'day' }, { plural: false })}
        >
          <InputNumber
            min={1}
            max={31}
            style={{ width: 160 }}
            formatter={n => n && n.padStart(2, '0')}
            defaultValue={null}
            stringMode
            onChange={day => setFormData({ day: day.padStart(2, '0') })}
            value={form.day}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'month' })}
        >
          <Select
            style={{ width: 160 }}
            onChange={month => setFormData({ month })}
            onClear={() => setFormData({ month: null })}
            allowClear
            value={form.month}
            options={monthList}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={(
            <>
              <Tooltip title="Deixe em branco para todos os anos">
                <QuestionCircleOutlined style={{ marginRight: 4 }} />
              </Tooltip>
              {intl.formatMessage({ id: 'year' })}
            </>
          )}
        >
          <DatePicker
            picker="year"
            style={{ width: 160 }}
            onChange={date => setFormData({ year: date ? date.format('YYYY') : null })}
            value={form.year ? moment(`${form.year}-01-01`) : null}
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!holidayId ? mutate() : mutateUpdate())}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default HolidayForm;
