import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Tooltip,
  Button,
  Menu,
  Dropdown,
  Modal,
  Table,
  DatePicker
} from 'antd';
import { MoreOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import moment from 'moment';

import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';
import { dateToLocale } from 'common/utils/misc';

const { confirm } = Modal;

class IndexValueList extends Component {
  state = {
    data: [],
    indexData: [],
    loading: true,
    month: moment()
  };

  componentDidMount() {
    const { match } = this.props;
    const { indexId } = match.params;

    this.setState({ indexId }, () => {
      this.loadIndexData();
      this.loadData();
    });
  }

  loadData = async () => {
    const { month, indexId } = this.state;

    this.setState({ loading: true });

    const lastDay = month.endOf('month').format('DD');
    const dateRange = [
      month.format('YYYY-MM-01 00:00:00'),
      month.format(`YYYY-MM-${lastDay} 23:59:59`)
    ]; // Ignore timestamp. Don't send date/moment object

    const body = await api('/shared/index-value', {
      body: {
        indexId,
        dateRange
      }
    });

    this.setState({
      data: body,
      loading: false
    });
  };

  loadIndexData = async () => {
    const { indexId } = this.state;

    this.setState({ loading: true });

    const body = await api('/shared/index', {
      body: { id: indexId }
    });

    this.setState({
      indexData: body,
      loading: false
    });
  };

  onDestroy = async id => {
    this.setState({ loading: true });

    const response = await api(`/shared/index-value/${id}`, {
      method: 'DELETE'
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      this.loadData();
    }
  };

  onChangeMonth = date => {
    this.setState(
      () => ({
        month: date
      }),
      () => {
        this.loadData();
      }
    );
  };

  menuActions = id => {
    const { intl } = this.props;

    return (
      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu>
            <Menu.Item key="0">
              <Link to={`value/${id}/edit`}>
                <FormattedMessage id="edit" />
              </Link>
            </Menu.Item>

            <Menu.Item
              key="1"
              onClick={() => {
                confirm({
                  title: intl.formatMessage({ id: 'message.delete_item' }),
                  onOk: () => this.onDestroy(id)
                });
              }}
            >
              <FormattedMessage id="delete" />
            </Menu.Item>
          </Menu>
        )}
      >
        <MoreOutlined className="more-options" />
      </Dropdown>
    );
  }

  renderTableColumns = () => [
    {
      render: ({ date }) => dateToLocale(date, 'L HH:mm', true),
      width: '10%',
      key: '1'
    },
    {
      render: ({ value }) => (
        <FormattedNumber
          value={value}
          maximumFractionDigits={9}
          minimumFractionDigits={9}
        />
      ),
      key: '2',
      width: '15%',
      align: 'right'
    },
    {
      render: ({ id }) => this.menuActions(id),
      key: '3',
      align: 'right'
    }
  ];

  render() {
    const { history } = this.props;
    const {
      indexData, month, data, loading
    } = this.state;
    const { MonthPicker } = DatePicker;

    return (
      <div>
        <div>{indexData[0] && indexData[0].name}</div>
        <MonthPicker
          style={{ marginBottom: 10 }}
          onChange={this.onChangeMonth}
          value={month}
        />
        <div className="list-with-buttons">
          <div className="buttons">
            <Tooltip title={<FormattedMessage id="add" />}>
              <Button
                onClick={() => history.push('value/add')}
                icon={<PlusOutlined />}
              />
            </Tooltip>

            <Tooltip title={<FormattedMessage id="import" />}>
              <Button
                onClick={() => history.push('value/import')}
                icon={<UploadOutlined />}
              />
            </Tooltip>
          </div>

          <div className="list-container">
            <Table
              dataSource={data}
              rowKey="id"
              columns={this.renderTableColumns()}
              showHeader={false}
              size="middle"
              loading={loading}
              pagination={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(IndexValueList);
