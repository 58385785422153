/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';

import '../css/calculation.css';

const CalculationFormHelp = () => (
  <Collapse
    style={{
      marginTop: 6,
      borderRadius: 4
    }}
    bordered={false}
  >
    <Collapse.Panel
      header={(
        <span>
          <FormattedMessage id="help" />
          {' - Variáveis'}
        </span>
      )}
      key="1"
      style={{ borderBottom: 0 }}
    >
      <p>
        Variáveis disponíveis que podem ser utilizadas no script do cálculo:
      </p>
      <ul className="help-list variables">
        <li>
          <h4>$valor</h4>
          Variável que deve receber o valor calculado. Todo o cálculo tem essa
          variável como resultado final.
        </li>
        <li>
          <h4>$emprestimoTotal</h4>
          Valor total contratado, o que inclui valores sacados e não sacados.
        </li>
        <li>
          <h4>$diaVencimento</h4>
          Dia do vencimento escolhido como padrão para a dívida.
        </li>
        <li>
          <h4>$diaVencimentoParcela</h4>
          Dia do vencimento da parcela que está sendo calculada - pode variar
          se ativado pagamentos somente em dias úteis.
        </li>
        <li>
          <h4>$prestacaoQuant</h4>
          Quantidade total de parcelas.
        </li>
        <li>
          <h4>$prestacaoDias</h4>
          Quantidade de dias entre as datas da prestação.
        </li>
        <li>
          <h4>$prestacaoNumero</h4>
          Número da parcela que está sendo calculada.
        </li>
        <li>
          <h4>$periodoDias</h4>
          Quantidade de dias entre a data inicial de um periodo (operação, por
          exemplo) e a data final da prestação.
        </li>
        <li>
          <h4>$saldoDevedor</h4>
          Saldo devedor no dia em que está sendo calculado. Saldo da dívida
          somado as operações ocorridas no período.
        </li>
        <li>
          <h4>$saldoDevedorAnterior</h4>
          Saldo devedor ao final da parcela anterior. Não sofre alteração de
          valor durante a execução dos cálculos na parcela corrente.
        </li>
        <li>
          <h4>$valorOperacao</h4>
          Valor da operação que está sendo calculada.
        </li>
        <li>
          <h4>$resultadoComposto</h4>
          Soma dos valores dos resultados anteriores na parcela para um mesmo
          cálculo.
        </li>

        <li>
          <h4>$dataInicio</h4>
          Data de início do período, ou data de execução da operação.
        </li>
        <li>
          <h4>$dataFim</h4>
          Data de término do período.
        </li>
        <li>
          <h4>$memorizador</h4>
          Objeto javascript utilizado para armazenar qualquer valor.
          Pode ser acessado também em outros cálculos.
          Os valores atribuídos na parcela corrente, só podem ser acessados na próxima parcela.
          <br />
          Exemplo:
          <i>{' $memorizador = { nomeIdentificador: valor }'}</i>
          <br />
          Caso haje necessida de somatório do valor do identificador:
          <br />
          <i><i>{' $memorizador = { nomeIdentificador: ($memorizador.nomeIdentificador || 0) + valor }'}</i></i>
        </li>
      </ul>

      <p>
        São disponibilizados os valores dos índices relacionados para todos os
        dias do mês corrente e do mês anterior, em relação à data final do
        período.
        <br />
        Variáveis para acesso aos índices:
      </p>
      <ul className="help-list">
        <li>
          <h4>$indiceMesCorrente</h4>
          Valores do índice para todos os dias do mês corrente.
        </li>
        <li>
          <h4>$indiceMesAnterior:</h4>
          Valores do índice para todos os dias do mês anterior.
        </li>
        <li>
          <h4>$indiceAnteriorValor</h4>
          Valor mais próximo de um índice, com data menor ou igual a data do
          dia do cálculo.
        </li>
      </ul>
      <p>
        Para acessar o valor do índice, você deve colocar entre cochetes (e
        aspas simples) o nome do índice e posteriormente, também entre
        cochetes, o dia requerido. Exemplos:
        <br />
        <i>$indiceMesCorrente['dolar-real']['09']</i>
        <br />
        <i>$indiceMesAnterior['dolar-real']['21']</i>
        <br />
        <i>$indiceAnteriorValor['dolar-real']</i>
      </p>
    </Collapse.Panel>
  </Collapse>
);

export default CalculationFormHelp;
