import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import DocList from './DocList';

class DocListContainer extends Component {
  state = {
    data: [],
    loading: false
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { match } = this.props;
    const { personId } = match.params;

    this.setState({ loading: true });

    const body = await api('/shared/doc', {
      body: { personId }
    });

    this.setState({
      data: body,
      loading: false
    });
  };

  handleDestroy = async docId => {
    this.setState({ loading: true });

    const response = await api(`/shared/doc/${docId}`, {
      method: 'DELETE'
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      this.loadData();
    }
  };

  render() {
    const { history } = this.props;
    const { data, loading } = this.state;

    return (
      <DocList
        data={data}
        onDestroy={this.handleDestroy}
        onGoToAdd={() => history.push('doc/add')}
        onGoToEdit={id => history.push(`doc/${id}/edit`)}
        loading={loading}
      />
    );
  }
}

export default withRouter(DocListContainer);
