import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { injectIntl } from 'react-intl';

import {
  defaultFormItemLayout
} from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

const FormItem = Form.Item;

const OrganizationForm = props => {
  const {
    getStateForm, setStateForm, intl, onStore, onCancel, getLoading
  } = props;

  return (
    <Form className="soft-box">
      <FormItem
        {...defaultFormItemLayout}
        label={intl.formatMessage({
          id: 'initials'
        })}
      >
        <Input
          autoFocus
          size="default"
          className="uppercase"
          maxLength={12}
          onChange={e => setStateForm({ initials: e.target.value })}
          value={getStateForm.initials}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={intl.formatMessage({ id: 'name' })}
      >
        <Input
          size="default"
          className="uppercase"
          maxLength={80}
          onChange={e => setStateForm({ name: e.target.value })}
          value={getStateForm.name}
        />
      </FormItem>

      <FormSubmitButtons
        onStore={onStore}
        onCancel={onCancel}
        loading={getLoading}
      />
    </Form>
  );
};

OrganizationForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onStore: PropTypes.func.isRequired,
  setStateForm: PropTypes.func.isRequired,
  getStateForm: PropTypes.shape({}).isRequired,
  getLoading: PropTypes.bool.isRequired
};

export default injectIntl(OrganizationForm);
