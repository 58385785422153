import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import moment from 'moment';
import {
  Form, Input, InputNumber, DatePicker, Switch, Spin
} from 'antd';

import { useGet, usePost, usePut } from 'common/api';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { defaultFormItemLayout } from 'common/layout/formItemGrid';

import CalculationSelectableTree from 'modules/debt/calculation/components/CalculationSelectableTree';

import { DEBT_INSTALLMENT_LIST, DEBT_INSTALLMENT } from 'modules/debt/_constants/cacheKeys';

const FormItem = Form.Item;
const { RangePicker } = DatePicker;

const InstallmentManualForm = () => {
  const intl = useIntl();
  const { goBack } = useHistory();
  const { debtId, installmentId } = useParams();
  const [form, setForm] = useState({
    id: null,
    debtId,
    startsOn: null,
    endsOn: null,
    dueOn: null,
    number: null,
    note: null,
    blocked: false,
    calculationList: []
  });

  const cacheKey = [DEBT_INSTALLMENT, debtId, installmentId];
  const { data, isLoading: isLoadingData } = useGet(cacheKey, '/debt/installment', {
    params: { id: installmentId },
    query: { enabled: Boolean(installmentId) }
  });
  useEffect(() => {
    if (data) {
      const calcData = data[0];
      const calculationList = calcData.calculation.map(v => v.id);

      setForm({
        ...calcData,
        calculationList,
        startsOn: moment(calcData.startsOn),
        endsOn: moment(calcData.endsOn),
        dueOn: moment(calcData.dueOn),
      });
    }
  }, [data]);

  const options = {
    fetch: {
      body: {
        ...form,
        startsOn: form.startsOn?.format('YYYY-MM-DD'),
        endsOn: form.endsOn?.format('YYYY-MM-DD'),
        dueOn: form.dueOn?.format('YYYY-MM-DD')
      }
    },
    onSuccess: goBack,
    cacheInvalidate: [
      [DEBT_INSTALLMENT_LIST, debtId],
      installmentId && [DEBT_INSTALLMENT, debtId, installmentId]
    ]
  };
  const { mutate, isLoading } = usePost(() => '/debt/installment', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/debt/installment/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  return (
    <Spin spinning={isLoadingData || isLoading || isLoadingUpdate}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'period' })}
        >
          <RangePicker
            showTime={false}
            format={intl.formatMessage({
              id: 'date_format'
            })}
            onChange={range => {
              setFormData({
                startsOn: range[0] ? range[0] : null,
                endsOn: range[1] ? range[1] : null
              });
            }}
            value={[
              form.startsOn ? form.startsOn : null,
              form.endsOn ? form.endsOn : null
            ]}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'due_date' }, { plural: false })}
        >
          <DatePicker
            format={intl.formatMessage({
              id: 'date_format'
            })}
            onChange={date => setFormData({
              dueOn: date || null
            })}
            value={form.dueOn ? form.dueOn : null}
            placeholder={intl.formatMessage({
              id: 'date_placeholder'
            })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({
            id: 'number'
          })}
        >
          <InputNumber
            onChange={number => setFormData({ number: number || null })}
            value={form.number}
            min={1}
            max={9999}
            style={{ width: 80 }}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({
            id: 'blocked'
          })}
        >
          <Switch
            checked={form.blocked}
            onChange={blocked => setFormData({ blocked })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'note' })}
        >
          <Input
            maxLength={120}
            onChange={e => setFormData({ note: e.target.value })}
            value={form.note}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'calculation' }, { plural: true })}
        >
          <div
            style={{
              backgroundColor: '#fff',
              borderRadius: '4px',
              marginBottom: '5px'
            }}
          >
            <CalculationSelectableTree
              debtId={debtId}
              onCheck={calculationList => setFormData({ calculationList })}
              checkedKeys={form.calculationList}
            />
          </div>
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!installmentId ? mutate() : mutateUpdate(installmentId))}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default InstallmentManualForm;
