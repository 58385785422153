import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { injectIntl } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

const FormItem = Form.Item;

const UserChangePasswordForm = props => {
  const {
    setStateForm, intl, loading, onStore, onCancel
  } = props;

  return (
    <Form className="soft-box">
      <FormItem
        {...defaultFormItemLayout}
        label={intl.formatMessage({
          id: 'current_password'
        })}
      >
        <Input
          size="default"
          className="uppercase"
          maxLength={35}
          type="password"
          onChange={e => setStateForm({ currentPassword: e.target.value })}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={intl.formatMessage({ id: 'new_password' })}
      >
        <Input
          size="default"
          className="uppercase"
          maxLength={35}
          type="password"
          onChange={e => setStateForm({ password: e.target.value })}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={intl.formatMessage({
          id: 'password_confirmation'
        })}
      >
        <Input
          size="default"
          className="uppercase"
          maxLength={35}
          type="password"
          onChange={e => setStateForm({
            passwordConfirmation: e.target.value
          })}
        />
      </FormItem>

      <FormSubmitButtons
        onStore={onStore}
        onCancel={onCancel}
        loading={loading}
      />
    </Form>
  );
};

UserChangePasswordForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onStore: PropTypes.func.isRequired,
  setStateForm: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default injectIntl(UserChangePasswordForm);
