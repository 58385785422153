import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import {
  UserAddOutlined,
  DollarOutlined,
  CheckOutlined,
  SolutionOutlined,
  FolderOutlined,
  SearchOutlined,
  PlusSquareOutlined,
  SettingOutlined,
  ProfileOutlined,
  FunctionOutlined,
  ExportOutlined,
  AppstoreOutlined,
  CoffeeOutlined,
  DashboardOutlined
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { useModule } from 'core/_contexts/ModuleContext';

const { SubMenu } = Menu;
const menuOptions = {
  theme: 'dark',
  mode: 'vertical',
  triggerSubMenuAction: 'click',
  selectable: false
};

const SystemsMenu = ({ collapsed }) => {
  const { push } = useHistory();
  const module = useModule();

  // Debt projectId
  const debtLocation = useLocation().pathname.match(/\/debt\/(\d+)\//);
  const projectId = debtLocation ? debtLocation[1] : null;

  const renderMenu = {
    shared: (
      <>
        <Menu.Item
          key="shared:1"
          icon={<UserAddOutlined />}
          onClick={() => push('/shared/person')}
        >
          <FormattedMessage id="person" values={{ plural: true }} />
        </Menu.Item>
        <Menu.Item
          key="shared:2"
          icon={<DollarOutlined />}
          onClick={() => push('/shared/index')}
        >
          <FormattedMessage id="index" values={{ plural: true }} />
        </Menu.Item>
        <Menu.Item
          key="shared:3"
          icon={<CoffeeOutlined />}
          onClick={() => push('/shared/holiday')}
        >
          <FormattedMessage id="holiday" values={{ plural: true }} />
        </Menu.Item>
      </>
    ),

    access: (
      <>
        <Menu.Item
          key="access:1"
          icon={<UserAddOutlined />}
          onClick={() => push('/access/user')}
        >
          <FormattedMessage id="user" values={{ plural: true }} />
        </Menu.Item>
        <SubMenu
          key="access:2"
          icon={<CheckOutlined />}
          title={<FormattedMessage id="rule" values={{ plural: true }} />}
        >
          <Menu.Item
            key="rule:1"
            onClick={() => push('/access/rule')}
          >
            <>
              <FormattedMessage id="define" />
              {' '}
              <FormattedMessage id="rule" values={{ plural: true }} />
            </>
          </Menu.Item>
          <Menu.Item
            key="rule:2"
            onClick={() => push('/access/rule/users-by-organization')}
          >
            <FormattedMessage id="users_by_organization" />
          </Menu.Item>
          <Menu.Item
            key="rule:3"
            onClick={() => push('/access/rule/users-by-profile')}
          >
            <FormattedMessage id="users_by_profile" />
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key="access:3"
          icon={<SolutionOutlined />}
          title={<FormattedMessage id="access_profiles" />}
        >
          <Menu.Item
            key="profile:1"
            onClick={() => push('/access/permission')}
          >
            <FormattedMessage id="permission" values={{ plural: true }} />
          </Menu.Item>
          <Menu.Item
            key="profile:2"
            onClick={() => push('/access/profile')}
          >
            <FormattedMessage id="profile" values={{ plural: true }} />
          </Menu.Item>
        </SubMenu>

        <Menu.Item
          key="access:4"
          icon={<ProfileOutlined />}
          onClick={() => push('/access/resource')}
        >
          <FormattedMessage id="resource" values={{ plural: true }} />
        </Menu.Item>
        <Menu.Item
          key="access:5"
          icon={<FolderOutlined />}
          onClick={() => push('/access/organization')}
        >
          <FormattedMessage
            id="work_organization"
            values={{ plural: true }}
          />
        </Menu.Item>
      </>
    ),

    project: (
      <>
        <Menu.Item
          key="project:1"
          icon={<SearchOutlined />}
          onClick={() => push('/project/list')}
        >
          <FormattedMessage id="find" />
        </Menu.Item>
        <Menu.Item
          key="project:2"
          icon={<PlusSquareOutlined />}
          onClick={() => push('/project/add')}
        >
          <FormattedMessage id="add_project" />
        </Menu.Item>
        <SubMenu
          key="project:3"
          icon={<SettingOutlined />}
          title={<FormattedMessage id="setting" />}
        >
          <Menu.Item
            key="project:4"
            onClick={() => push('/project/status')}
          >
            <FormattedMessage id="status" />
          </Menu.Item>
          <Menu.Item
            key="project:5"
            onClick={() => push('/project/tag')}
          >
            <FormattedMessage id="tag" values={{ plural: true }} />
          </Menu.Item>
        </SubMenu>
      </>
    ),

    debt: (
      <>
        <Menu.Item
          key="debt:1"
          icon={<DashboardOutlined />}
          onClick={() => push('/debt/dashboard')}
        >
          <FormattedMessage id="dashboard" />
        </Menu.Item>
        <Menu.Item
          key="debt:2"
          icon={<SearchOutlined />}
          onClick={() => push('/debt/project/list')}
        >
          <FormattedMessage id="project" values={{ plural: true }} />
        </Menu.Item>
        <Menu.Item
          key="debt:3"
          icon={<ProfileOutlined />}
          onClick={() => push(`/debt/${projectId}/list`)}
          disabled={!projectId}
        >
          <FormattedMessage id="debt" values={{ plural: true }} />
        </Menu.Item>
        <Menu.Item
          key="debt:4"
          icon={<FunctionOutlined />}
          onClick={() => push('/debt/calculation/list')}
        >
          <FormattedMessage id="shared_calculations" />
        </Menu.Item>
        <Menu.Item
          key="debt:5"
          icon={<ExportOutlined />}
          onClick={() => push('/debt/data-export')}
        >
          <FormattedMessage id="data_export" />
        </Menu.Item>
      </>
    )
  };

  const menuItems = renderMenu[module];

  if (!menuItems) return null;

  return (
    <Menu {...menuOptions}>
      <Menu.Item
        key="core:apps"
        icon={<AppstoreOutlined style={{ fontSize: 26, marginLeft: collapsed ? -6 : 10 }} />}
        onClick={() => push('/core/module/list')}
        style={{ marginBottom: 16, textAlign: 'center' }}
        title={<FormattedMessage id="system_modules" />}
      />
      {menuItems}
    </Menu>
  );
};

export default SystemsMenu;
