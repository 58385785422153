import React, { Component } from 'react';
import { BugOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    hasError: false
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (process.env.REACT_APP_ENV !== 'development') {
      Sentry.withScope(scope => {
        scope.setExtras(info);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div style={{
          marginLeft: 'auto', marginRight: 'auto', marginTop: 60, textAlign: 'center'
        }}
        >
          <BugOutlined style={{ fontSize: 102, color: 'red' }} />
          <h2 style={{ color: 'red' }}><FormattedMessage id="went_wrong" /></h2>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
