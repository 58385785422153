import React, { useState } from 'react';
import {
  Form, Select, Modal, Empty
} from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { usePost } from 'common/api';

const ReportForm = props => {
  const { reportId, parameters } = props;
  const intl = useIntl();
  const [form, setForm] = useState({});

  const setFormField = obj => {
    setForm({
      ...form,
      ...obj
    });
  };

  const showModal = data => {
    if (data !== 'error') {
      Modal.info({
        title: intl.formatMessage({ id: 'message.report_generate' }),
        content: (
          <p>
            {intl.formatMessage({ id: 'message.report_tray' })}
            <PrinterOutlined style={{ fontSize: 18, marginLeft: 4 }} />
          </p>
        ),
        onOk() {},
      });
    }
  };

  const options = {
    fetch: { body: form },
    onSuccess: showModal,
    notify: false
  };
  const { mutate, isLoading } = usePost(() => `/gateway/report/request/${reportId}`, options);

  const getItem = param => {
    const { type, values, field } = param;

    return {
      list: (
        <Select onSelect={value => setFormField({ [field]: value })}>
          {values.map(value => <Select.Option key={value}>{value}</Select.Option>) }
        </Select>
      )
    }[type];
  };

  const buildFormItem = param => {
    const { title, key } = param;

    return (
      <Form.Item
        {...defaultFormItemLayout}
        label={intl.formatMessage({ id: title })}
        key={key}
      >
        {getItem(param)}
      </Form.Item>
    );
  };

  const renderFormItems = () => (
    <>
      {parameters.map((param, key) => buildFormItem({ ...param, key }))}
      <FormSubmitButtons
        onStore={mutate}
        icon={<PrinterOutlined />}
        storeMessage="generate_report"
        loading={isLoading}
        hideCancel
      />
    </>
  );

  return (
    <Form className="soft-box">
      {
        reportId
          ? renderFormItems()
          : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={intl.formatMessage({ id: 'not_selected' })}
            />
          )
      }
    </Form>
  );
};

export default ReportForm;
