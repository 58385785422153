import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Switch,
  Tooltip,
  Drawer,
  Spin
} from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

import PersonSelect from 'modules/shared/person/components/PersonSelect';
import PersonFormContainer from 'modules/shared/person/components/PersonFormContainer';
import UserImageProfile from './UserImageProfile';

const FormItem = Form.Item;

const UserForm = props => {
  const {
    setStateForm,
    getStateForm,
    getState,
    loading,
    onShowPersonForm,
    onCompleteCropImg,
    onClosePersonForm,
    onStore,
    getLoading,
    onCancel
  } = props;

  return (
    <Spin spinning={loading}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="person" values={{ plural: false }} />}
        >
          <PersonSelect
            onSelect={personId => setStateForm({ personId })}
            style={{
              display: getStateForm.id ? 'none' : 'block'
            }}
          />
          <span
            style={{
              display: !getStateForm.id ? 'none' : 'block'
            }}
          >
            <b>{getStateForm.name}</b>
          </span>

          <Tooltip title={<FormattedMessage id="add_person" />}>
            <Button
              onClick={onShowPersonForm}
              icon={<UserAddOutlined />}
              style={{
                display: getStateForm.id ? 'none' : 'block',
                marginTop: 3
              }}
            />
          </Tooltip>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="username" />}
        >
          <Input
            onChange={e => setStateForm({ username: e.target.value })}
            value={getStateForm.username}
            maxLength={39}
          />
        </FormItem>

        <FormItem {...defaultFormItemLayout} label="E-mail">
          <Input
            onChange={e => setStateForm({ email: e.target.value })}
            value={getStateForm.email}
            maxLength={50}
          />
        </FormItem>

        <FormItem {...defaultFormItemLayout} label="Super Admin">
          <Switch
            checked={getStateForm.superAdmin}
            onChange={superAdmin => setStateForm({ superAdmin })}
          />
        </FormItem>

        <FormItem {...defaultFormItemLayout} label="LDAP?">
          <Switch
            checked={getStateForm.ldap}
            onChange={ldap => setStateForm({ ldap })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="password" />}
          style={{ display: getStateForm.ldap ? 'none' : null }}
        >
          <Input
            type="password"
            onChange={e => setStateForm({ password: e.target.value })}
            maxLength={25}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          style={{ display: getStateForm.ldap ? 'none' : null }}
          label={<FormattedMessage id="password_confirmation" />}
        >
          <Input
            type="password"
            onChange={e => setStateForm({
              passwordConfirmation: e.target.value
            })}
            maxLength={25}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={<FormattedMessage id="profile_image" />}
        >
          <UserImageProfile
            onCompleteCropImg={onCompleteCropImg}
            urlImgProfile={getState.urlImgProfile}
          />
        </FormItem>

        <FormSubmitButtons
          onStore={onStore}
          onCancel={onCancel}
          loading={getLoading}
          disabledStore={!getStateForm.personId}
        />
      </Form>

      <Drawer
        title={(
          <span>
            <FormattedMessage id="person" values={{ plural: false }} />
            {' '}
            /
            {' '}
            <FormattedMessage id="register" />
          </span>
        )}
        width={820}
        placement="right"
        onClose={onClosePersonForm}
        visible={getState.personFormVisible}
      >
        <PersonFormContainer onFinished={onClosePersonForm} />
      </Drawer>
    </Spin>
  );
};

UserForm.propTypes = {
  onStore: PropTypes.func.isRequired,
  onAutoComplete: PropTypes.func.isRequired,
  onCompleteCropImg: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onShowPersonForm: PropTypes.func.isRequired,
  onClosePersonForm: PropTypes.func.isRequired,
  setStateForm: PropTypes.func.isRequired,
  getStateForm: PropTypes.shape({}).isRequired,
  getState: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired
};

export default UserForm;
