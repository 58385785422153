/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
const removeFromTree = (data, keyValue, keyName = 'id') => {
  let filterFn;
  return data.filter((filterFn = item => {
    if (item.children.length > 0) {
      if (`${item[keyName]}` !== `${keyValue}`) {
        return item.children = item.children.filter(filterFn);
      }
    }
    return `${item[keyName]}` !== `${keyValue}`;
  }));
};

export default removeFromTree;
