import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PaymentList from 'modules/debt/payment/pages/PaymentList';
import PaymentForm from 'modules/debt/payment/pages/PaymentForm';

const PaymentRoutes = () => (
  <Switch>
    <Route
      path="/debt/:projectId/:debtId/installment/:installmentId/details/payment/add"
      component={PaymentForm}
    />
    <Route
      path="/debt/:projectId/:debtId/installment/:installmentId/details/payment/:paymentId/edit"
      component={PaymentForm}
    />
    <Route
      path="/debt/:projectId/:debtId/installment/:installmentId/details"
      component={PaymentList}
    />
  </Switch>
);

export default PaymentRoutes;
