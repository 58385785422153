import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Table,
  Button,
  Modal,
  Tooltip,
  Select,
  Menu,
  Dropdown
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

const { Option } = Select;
const { confirm } = Modal;

const UserList = props => {
  const {
    showInactive,
    intl,
    onDestroy,
    onRestore,
    onGoToForm,
    onChangeSelectStatus,
    data,
    loading
  } = props;

  const menuActions = record => {
    if (!showInactive) {
      return (
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu>
              <Menu.Item key="0">
                <Link to={`/access/user/edit/${record.id}`}>
                  <FormattedMessage id="edit" />
                </Link>
              </Menu.Item>

              <Menu.Item
                key="1"
                onClick={() => {
                  confirm({
                    title: intl.formatMessage({ id: 'message.inactivate_item' }),
                    onOk: () => onDestroy(record.id)
                  });
                }}
              >
                <FormattedMessage id="inactivate" />
              </Menu.Item>
            </Menu>
          )}
        >
          <MoreOutlined className="more-options" />
        </Dropdown>
      );
    }
    return (
      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu>
            <Menu.Item
              key="1"
              onClick={() => {
                confirm({
                  title: intl.formatMessage({ id: 'message.reactivate_item' }),
                  onOk: () => onRestore(record.id)
                });
              }}
            >
              <FormattedMessage id="reactivate" />
            </Menu.Item>
          </Menu>
        )}
      >
        <MoreOutlined className="more-options" />
      </Dropdown>
    );
  };

  const renderTableColumns = () => [
    {
      dataIndex: 'person',
      render: person => person.name,
      key: 'name',
      width: '50%'
    },
    {
      dataIndex: 'username',
      key: 'username',
      width: '15%'
    },
    {
      dataIndex: 'contact',
      render: contact => {
        if (contact[0]) {
          return `${contact[0].description}`;
        }

        return '';
      },
      key: 'email',
      width: '32%'
    },
    {
      key: 'actions',
      title: ' ',
      width: '3%',
      align: 'right',
      render: (text, record) => menuActions(record)
    }
  ];

  return (
    <div>
      <Tooltip title={<FormattedMessage id="add" />}>
        <Button
          onClick={onGoToForm}
          icon={<PlusOutlined />}
          style={{ paddingTop: '1px' }}
        />
      </Tooltip>

      <Select
        defaultValue="false"
        style={{ marginLeft: '6px' }}
        onChange={value => {
          onChangeSelectStatus(value);
        }}
      >
        <Option value="false">
          <FormattedMessage id="actives" />
        </Option>
        <Option value="true">
          <FormattedMessage id="inactive" />
        </Option>
      </Select>

      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        showHeader={false}
        size="middle"
        pagination={false}
        loading={loading}
        style={{ marginTop: '5px' }}
      />
    </div>
  );
};

UserList.propTypes = {
  data: PropTypes.shape([]).isRequired,
  onChangeSelectStatus: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  onGoToForm: PropTypes.func.isRequired,
  showInactive: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

export default injectIntl(UserList);
