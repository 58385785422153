import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Row, Col, Menu, Dropdown, Modal
} from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

import SelectOrganization from '../../organization/components/SelectOrganization';
import SelectProfile from '../../profile/microComponents/SelectProfile';

const { confirm } = Modal;

const RuleByProfile = props => {
  const {
    intl, onDestroy, onChangeOrganization, onChangeProfile, data, getLoading
  } = props;

  const menuActions = record => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.delete_item' }),
                onOk: () => onDestroy(record.userId)
              });
            }}
          >
            <FormattedMessage id="delete" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      key: 'name',
      render: (text, record) => `${record.personName} (${record.personDocNumber})`,
      width: '97%'
    },
    {
      key: 'actions',
      title: '',
      width: '3%',
      align: 'right',
      render: (text, record) => menuActions(record)
    }
  ];

  return (
    <div>
      <Row gutter={16}>
        <Col xs={24} sm={16} md={10} lg={8}>
          <SelectOrganization
            onChange={onChangeOrganization}
            placeholder={<FormattedMessage id="select_work_organization" />}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: '5px' }}>
        <Col xs={24} sm={16} md={10} lg={8}>
          <SelectProfile
            onChange={onChangeProfile}
            placeholder={<FormattedMessage id="select_profile" />}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Table
            dataSource={data}
            rowKey="userId"
            columns={renderTableColumns()}
            size="middle"
            pagination={false}
            showHeader={false}
            style={{ marginTop: '5px' }}
            loading={getLoading}
          />
        </Col>
      </Row>
    </div>
  );
};

RuleByProfile.propTypes = {
  data: PropTypes.shape([]).isRequired,
  onDestroy: PropTypes.func.isRequired,
  onChangeOrganization: PropTypes.func.isRequired,
  onChangeProfile: PropTypes.func.isRequired,
  getLoading: PropTypes.bool.isRequired
};

export default injectIntl(RuleByProfile);
