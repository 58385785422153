import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Menu, Spin, Row, Col
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { api } from 'config/api';

import PersonDetailsPageRoutes from './PersonDetailsPageRoutes';

class PersonDetailsPage extends Component {
  state = {
    data: [],
    loading: false
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { match } = this.props;
    const { personId } = match.params;

    this.setState({ loading: true });

    const body = await api('/shared/person', {
      body: { id: personId }
    });

    this.setState({
      data: body[0],
      loading: false
    });
  };

  render() {
    const { loading, data } = this.state;
    const { match: { url } } = this.props;

    return (
      <Spin spinning={loading}>
        <h1>{data.name}</h1>
        <div style={{ marginBottom: 10 }}>{data.doc_number}</div>

        <Row gutter={20}>
          <Col xs={24} sm={10} md={6} lg={5} xl={3}>
            <Menu mode="inline" defaultSelectedKeys={['basic']}>
              <Menu.Item key="basic">
                <Link to={`${url}/edit`}>
                  <FormattedMessage id="basic" />
                </Link>
              </Menu.Item>
              <Menu.Item key="contacts">
                <Link to={`${url}/contact`}>
                  <FormattedMessage id="contact" values={{ plural: true }} />
                </Link>
              </Menu.Item>
              <Menu.Item key="docs">
                <Link to={`${url}/doc`}>Docs</Link>
              </Menu.Item>
              <Menu.Item key="address">
                <Link to={`${url}/address`}>
                  <FormattedMessage id="address" values={{ plural: true }} />
                </Link>
              </Menu.Item>
            </Menu>
          </Col>
          <Col xs={24} sm={14} md={18} lg={19} xl={21}>
            <PersonDetailsPageRoutes />
          </Col>
        </Row>
      </Spin>
    );
  }
}

export default PersonDetailsPage;
