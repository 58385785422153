import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Resume from 'modules/project/project/components/ProjectResume';
import TaskList from 'modules/project/task/components/TaskList';
import TaskPage from 'modules/project/task/components/TaskPage';
import TaskForm from 'modules/project/task/components/TaskForm';
import HistoryForm from 'modules/project/history/components/HistoryForm';
import ProjectStatusForm from 'modules/project/projectStatus/components/ProjectStatusForm';
import ProjectStatusList from 'modules/project/projectStatus/components/ProjectStatusList';
import FileList from 'modules/project/file/components/FileList';
import FileForm from 'common/components/file/FileForm';
import FileMoveForm from 'modules/project/file/components/FileMoveForm';
import FolderForm from 'modules/project/folder/components/FolderForm';
import FolderMoveForm from 'modules/project/folder/components/FolderMoveForm';
import FolderDestroy from 'modules/project/folder/components/FolderDestroy';

const ManagementPageRoutes = () => {
  const url = '/project/:projectId';

  return (
    <Switch>
      <Route
        path={`${url}/resume`}
        component={Resume}
      />
      <Route
        path={`${url}/task`}
        exact
        component={() => <TaskList showButtons />}
      />
      <Route
        path={`${url}/task/:taskId/details`}
        component={TaskPage}
      />
      <Route
        path={`${url}/task/add`}
        component={TaskForm}
      />
      <Route
        path={`${url}/task/:taskId/edit`}
        component={TaskForm}
      />
      <Route
        path={`${url}/task/:taskId/history/add`}
        component={HistoryForm}
      />
      <Route
        path={`${url}/task/:taskId/history/:historyId/edit`}
        component={HistoryForm}
      />
      <Route
        path={`${url}/project-status`}
        component={ProjectStatusList}
        exact
      />
      <Route
        path={`${url}/project-status/add`}
        component={ProjectStatusForm}
      />
      <Route
        path={`${url}/project-status/:projectStatusId/edit`}
        component={ProjectStatusForm}
      />
      <Route
        path={`${url}/file/:folderId?`}
        component={FileList}
        exact
      />
      <Route
        path={`${url}/file/:fileId/edit`}
        component={FileForm}
      />
      <Route
        path={`${url}/file/:fileId/move`}
        component={FileMoveForm}
      />
      <Route
        path={`${url}/folder/add/:parentId?`}
        component={FolderForm}
      />
      <Route
        path={`${url}/folder/:folderId/edit`}
        component={FolderForm}
      />
      <Route
        path={`${url}/folder/:folderId/move`}
        component={FolderMoveForm}
      />
      <Route
        path={`${url}/folder/:folderId/destroy`}
        component={FolderDestroy}
      />
    </Switch>
  );
};
export default ManagementPageRoutes;
