import React, { useState } from 'react';
import { DatePicker, Table } from 'antd';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { useGet } from 'common/api';
import { dateToLocale } from 'common/utils/misc';

import { useConfig } from 'core/_contexts/ConfigContext';
import { DEBT_DASHBOARD } from 'modules/debt/_constants/cacheKeys';

import styles from './styles.module.css';

const TotalByDueDate = () => {
  const { locale } = useConfig();
  const { formatNumber } = useIntl();
  const [date, setDate] = useState(moment().format('MM/YYYY'));
  const [month, year] = date.split('/');
  const monthName = new Intl.DateTimeFormat(locale, { month: 'long' }).format(new Date(year, month - 1, 1));

  const { data, isLoading } = useGet([DEBT_DASHBOARD, 'totalByDueDate', date], '/debt/dashboard/total-by-due-date', {
    params: { year, month }
  });

  const formatCurrency = (value, symbol) => formatNumber(value || 0, {
    maximumFractionDigits: 2,
    style: 'currency',
    currency: symbol || null
  });

  const columns = [{
    render: ({ dueOn }) => dateToLocale(dueOn),
    key: 'dueOn',
    width: '15%'
  }, {
    render: ({ currency, total }) => formatCurrency(total, currency),
    key: 'total'
  }];

  return (
    <>
      <DatePicker
        picker="month"
        format="MM/YYYY"
        defaultValue={moment()}
        onChange={(_, selectedDate) => setDate(selectedDate)}
        allowClear={false}
      />

      <div className={styles.dueList}>
        <h5 style={{ textTransform: 'capitalize' }}>{`${monthName}/${year}`}</h5>
        <Table
          loading={isLoading}
          dataSource={data}
          columns={columns}
          pagination={false}
          size="middle"
          showHeader={false}
          rowKey="dueOn"
        />
      </div>
    </>
  );
};

export default TotalByDueDate;
