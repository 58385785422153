const apiUrl = process.env.REACT_APP_API_URL;
const showFileUrl = process.env.REACT_APP_SHOW_FILE_URL;
const showCsvUrl = process.env.REACT_APP_SHOW_CSV_URL;
const uploadDir = '/static/uploads';
const initialUrl = '/core/module/list';
const reportFileUrl = process.env.REACT_APP_REPORT_FILE_URL;

export {
  apiUrl,
  showFileUrl,
  showCsvUrl,
  uploadDir,
  initialUrl,
  reportFileUrl
};
