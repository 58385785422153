import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import OrganizationForm from './OrganizationForm';

class OrganizationFormPage extends Component {
  state = {
    form: {
      id: null,
      initials: null,
      name: null
    },

    loading: false
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;
    if (id) {
      this.loadDataToUpdate(parseInt(id, 10));
    }
  }

  handleStore = async () => {
    const { form } = this.state;
    this.setState({ loading: true });

    let response = null;

    if (!form.id) {
      response = await api('/access/organization', {
        method: 'POST',
        body: form
      });
    } else {
      response = await api(`/access/organization/${form.id}`, {
        method: 'PUT',
        body: form
      });
    }

    responseHandler(response);
    this.setState({ loading: false });

    if (response.ok) {
      const { history } = this.props;
      history.replace('/access/organization');
    }
  };

  loadDataToUpdate = async id => {
    this.setState({ loading: true });

    const body = await api('/access/organization', {
      body: { id }
    });

    this.setState({
      form: { id, ...body[0] },
      loading: false
    });
  };

  handleCancel = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleInputInitials = input => {
    this.inputInitials = input;
  };

  render() {
    const { form, loading } = this.state;

    return (
      <OrganizationForm
        onCancel={this.handleCancel}
        onStore={this.handleStore}
        setStateForm={value => this.setState({
          form: { ...form, ...value }
        })}
        getStateForm={form}
        getLoading={loading}
      />
    );
  }
}

export default OrganizationFormPage;
