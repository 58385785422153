import React, { useEffect } from 'react';
import {
  Route, Switch, Redirect, useParams
} from 'react-router-dom';
import { BankOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import BreadcrumbFromUrl from 'common/components/BreadcrumbFromUrl';
import { useGet } from 'common/api';

import { useModuleUpdate } from 'core/_contexts/ModuleContext';

import { DebtProvider } from 'modules/debt/_contexts/DebtContext';
import DebtList from 'modules/debt/debt/pages/DebtList';
import DebtForm from 'modules/debt/debt/pages/DebtForm';
import DebtDetails from 'modules/debt/debt/pages/DebtDetails';
import CalculationList from 'modules/debt/calculation/components/CalculationList';
import CalculationForm from 'modules/debt/calculation/components/CalculationForm';
import DataExport from 'modules/debt/dataExport/pages/DataExport';
import Dashboard from 'modules/debt/dashboard/components';

import ProjectList from 'modules/project/project/components/ProjectList';

import { PROJECT_PROJECT } from 'modules/project/_constants/cacheKeys';

const breadcrumbMap = {
  '/debt': {
    title: (
      <span>
        <BankOutlined />
        <FormattedMessage id="debt" values={{ plural: true }} />
      </span>
    ),
    noLink: true
  },
  '/debt/data-export': {
    title: <FormattedMessage id="data_export" />,
  },
  '/debt/calculation': {
    title: <FormattedMessage id="shared_calculations" />,
    noLink: true
  },
  '/debt/!/list': {
    title: <FormattedMessage id="list" />
  },
  '/debt/!/add': {
    title: <FormattedMessage id="register" />
  },
  '/debt/!/!/edit': {
    title: <FormattedMessage id="edit" />
  }
};

const DebtRoutes = () => {
  const moduleUpdate = useModuleUpdate();
  const { projectId } = useParams();

  const isProjectId = !!projectId.match(/\d+/);
  const cacheKey = [PROJECT_PROJECT, projectId];

  const { data } = useGet(cacheKey, '/project/project', {
    params: { id: projectId },
    query: { enabled: Boolean(isProjectId) }
  });

  useEffect(() => {
    moduleUpdate('debt');
  }, []);

  const renderProjectTitle = () => {
    if (!data) { return null; }

    const { shortName, name } = data.rows[0];

    return (
      <div style={{ fontSize: 18, marginBottom: 15 }}>
        <span style={{ fontSize: 32, marginRight: shortName ? 10 : 0, lineHeight: 1 }}>
          {`${shortName || ''}`}
        </span>
        {name}
      </div>
    );
  };

  return (
    <>
      <BreadcrumbFromUrl
        className=""
        breadcrumbMap={breadcrumbMap}
        withBaseUrl
      />

      <div style={{ padding: 20 }}>
        {renderProjectTitle()}

        <Switch>
          <Route
            path="/debt/dashboard"
            component={Dashboard}
            exact
          />
          <Route
            path="/debt/data-export"
            component={DataExport}
            exact
          />
          <Route
            path="/debt/calculation/list"
            component={() => <CalculationList onlyShared hideButtonAddShared baseUrl="" />}
            exact
          />
          <Route
            path="/debt/calculation/add"
            component={() => <CalculationForm shared />}
            exact
          />
          <Route
            path="/debt/calculation/:calculationId/edit"
            component={() => <CalculationForm shared />}
            exact
          />
          <Route
            path="/debt/project/list"
            component={() => <ProjectList hideMenuActions selectedLink={id => `../${id}/list`} />}
            exact
          />
          <Route
            path="/debt/:projectId/list"
            component={DebtList}
            exact
          />
          <Route
            path="/debt/:projectId/add"
            component={DebtForm}
          />
          <Route
            path="/debt/:projectId/:debtId/edit"
            component={DebtForm}
            exact
          />
          <DebtProvider>
            <Route
              path="/debt/:projectId/:debtId"
              component={DebtDetails}
            />
          </DebtProvider>
          <Redirect to="/404" />
        </Switch>
      </div>
    </>
  );
};

export default DebtRoutes;
