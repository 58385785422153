import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import RuleByOrganization from './RuleByOrganization';

class RuleByOrganizationPage extends Component {
  state = {
    organizationId: null,
    data: [],

    loading: false
  };

  loadDataUser = async () => {
    const { organizationId } = this.state;

    this.setState({ loading: true });

    const body = await api(
      `/access/rule/find-user-by-organization/${organizationId}`
    );

    this.setState({
      data: body,
      loading: false
    });
  };

  handleChangeOrganization = async organizationId => {
    await this.setState({ organizationId });
    this.loadDataUser();
  };

  handleDestroy = async userId => {
    const { organizationId } = this.state;

    const response = await api(
      `/access/rule/destroy-all-by-user/${
        organizationId
      }/${userId}`,
      { method: 'DELETE' }
    );
    responseHandler(response);

    this.loadDataUser();
  };

  render() {
    const { data, loading } = this.state;

    return (
      <RuleByOrganization
        data={data}
        onDestroy={this.handleDestroy}
        onChangeOrganization={this.handleChangeOrganization}
        getLoading={loading}
      />
    );
  }
}

export default RuleByOrganizationPage;
