import React, {
  createContext, useContext, useState, useEffect
} from 'react';

const ConfigContext = createContext();
const ConfigUpdateContext = createContext();

export const useConfig = () => useContext(ConfigContext);
export const useConfigUpdate = () => useContext(ConfigUpdateContext);

const utc = (new Date().getTimezoneOffset() / 60) * -1;
export const defaultConfig = { locale: 'pt', utc };

export const ConfigProvider = ({ children }) => {
  const storage = JSON.parse(localStorage.getItem('config'));

  const [data, setData] = useState(storage || defaultConfig);

  useEffect(() => {
    localStorage.setItem('config', JSON.stringify(data));
  }, [data]);

  return (
    <ConfigContext.Provider value={data}>
      <ConfigUpdateContext.Provider
        value={v => setData({ ...data, ...v })}
      >
        { children }
      </ConfigUpdateContext.Provider>
    </ConfigContext.Provider>
  );
};
