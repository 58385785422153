import qs from 'qs';
import { apiUrl } from './app';

const api = async (rawUrl, initConfig, returnPromiseOnGet = false) => {
  let init = initConfig;
  let url = rawUrl;

  if (typeof init !== 'object') {
    init = {};
  }

  if (typeof init.headers === 'undefined') {
    init = {
      ...init,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Content-Language': 'pt', // FIXME context config
        Locale: 'pt' // FIXME context config
      }
    };
  }

  // Define o método de envio padrão.
  if (typeof init.method === 'undefined') {
    init = { ...init, method: 'GET' };
  }

  // Se o envio é de conteúdo JSON, formata o body para envio.
  if (
    (init.method === 'POST'
      || init.method === 'PUT'
      || init.method === 'PATCH')
    && init.headers['Content-Type'] === 'application/json'
  ) {
    init = {
      ...init,
      body: JSON.stringify(init.body)
    };
  }

  // Parâmetros adicionais na URL
  url += '?';

  // url += `&organizationId=${organization.id}`;
  url += '&organizationId=1'; // FIXME context organization

  init = {
    ...init,
    credentials: 'include'
  };

  if (init.params) {
    url += init.params;
  }

  // Se for método GET e possui body, significa que possui parâmetros a serem passados.
  // Método GET não possui body, passa parâmetros na queryString.
  let qsData = '';
  if (init.method === 'GET' && typeof init.body !== 'undefined') {
    qsData = `&${ qs.stringify(init.body)}`;
    delete init.body;
  }

  if (init.method !== 'GET') {
    return fetch(apiUrl + url + qsData, init);
  }

  if (returnPromiseOnGet) {
    return fetch(apiUrl + url + qsData, init);
  }

  const response = await fetch(apiUrl + url + qsData, init);
  return response.json();
};

// eslint-disable-next-line import/prefer-default-export
export { api };
