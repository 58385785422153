import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import MainLayout from 'core/layouts/MainLayout';

import ErrorBoundary from 'core/error/components/ErrorBoundary';

import AccessRoutes from 'modules/access/AccessRoutes';
import SharedRoutes from 'modules/shared/_routes/SharedRoutes';
import ProjectRoutes from 'modules/project/_routes/ProjectRoutes';
import DebtRoutes from 'modules/debt/_routes/DebtRoutes';

import PageNotFound from 'core/pages/PageNotFound';
import TokenExpired from 'core/pages/TokenExpired';

import LoginForm from 'modules/access/authentication/components/LoginForm';
import ModuleListPage from 'core/components/moduleListPage/ModuleListPage';

import { useAuth } from 'core/_contexts/AuthContext';

const isAuthenticated = (Comp, auth) => routerProps => {
  const { location: { pathname } } = routerProps;
  return (
    auth || pathname === '/access/login'
      ? (
        <MainLayout>
          <ErrorBoundary>
            <Comp {...routerProps} />
          </ErrorBoundary>
        </MainLayout>
      )
      : <Redirect to="/access/login" />
  );
};

const renderExternalPage = Comp => () => (
  <MainLayout>
    <Comp />
  </MainLayout>
);

const Routes = () => {
  const auth = useAuth();

  return (
    <Switch>
      <Route path="/access/login" component={LoginForm} />
      <Route path="/" render={() => <Redirect to="/access/login" />} exact />

      <Route path="/core/module/list" render={isAuthenticated(ModuleListPage, auth)} />
      <Route path="/access" render={isAuthenticated(AccessRoutes, auth)} />
      <Route path="/shared" render={isAuthenticated(SharedRoutes, auth)} />
      <Route path="/project" render={isAuthenticated(ProjectRoutes, auth)} />
      <Route path="/debt/:projectId" render={isAuthenticated(DebtRoutes, auth)} />

      <Route path="/401" exact render={renderExternalPage(TokenExpired)} />
      <Route path="/404" exact render={renderExternalPage(PageNotFound)} />
      <Redirect from="*" to="/404" />
    </Switch>
  );
};

export default Routes;
