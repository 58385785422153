import { apiUrl } from 'config/app';

export const getDefaultFetchOptions = ({ locale }) => ({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Content-Language': locale,
    Locale: locale
  }
});

export const getApiUrl = ({ url, organization }) => {
  const orgParam = organization ? `?organizationId=${organization.id}` : '';
  const baseUrl = !(new RegExp('^http.+')).test(url) ? apiUrl : '';

  return `${baseUrl}${url}${orgParam}`;
};
