import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Button, Tooltip, Menu, Dropdown, Modal
} from 'antd';
import { MoreOutlined, PictureOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

import { showFileUrl } from 'config/app';

const { confirm } = Modal;

const DocList = props => {
  const {
    onGoToEdit, loading, data, onGoToAdd, onDestroy, intl
  } = props;

  const menuActions = record => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => onGoToEdit(record.id)}
          >
            <FormattedMessage id="edit" />
          </Menu.Item>

          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.inactivate_item' }),
                onOk: () => onDestroy(record.id)
              });
            }}
          >
            <FormattedMessage id="inactivate" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      // dataIndex: 'image',
      render: (text, record) => {
        if (record.fileId) {
          return (
            <a
              href={`${showFileUrl}/${record.file.recordedName}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PictureOutlined style={{ fontSize: 18, marginTop: 2 }} />
            </a>
          );
        }
        return null;
      },
      key: 'image',
      width: '3%'
    },
    {
      title: <FormattedMessage id="type" />,
      // dataIndex: 'type',
      render: (text, record) => record.docType.name,
      key: 'type',
      width: '45%'
    },
    {
      title: <FormattedMessage id="number" />,
      dataIndex: 'number',
      key: 'number',
      width: '27%'
    },
    {
      title: <FormattedMessage id="expiration" />,
      dataIndex: 'dueOn',
      key: 'dueOn',
      width: '20%'
    },
    {
      key: 'actions',
      width: '3%',
      align: 'right',
      render: (text, record) => menuActions(record)
    }
  ];

  return (
    <div>
      <Tooltip title={<FormattedMessage id="add" />}>
        <Button
          onClick={onGoToAdd}
          icon={<PlusOutlined />}
          style={{ paddingTop: '1px', marginBottom: '3px' }}
        />
      </Tooltip>

      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        size="middle"
        pagination={false}
        showHeader={false}
        loading={loading}
      />
    </div>
  );
};

DocList.propTypes = {
  data: PropTypes.shape([]),
  onDestroy: PropTypes.func.isRequired,
  onGoToAdd: PropTypes.func.isRequired,
  onGoToEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

DocList.defaultProps = {
  data: []
};

export default injectIntl(DocList);
