/* eslint-disable react/style-prop-object */
import React from 'react';
import {
  Link, useHistory, useParams, useRouteMatch
} from 'react-router-dom';
import {
  Tooltip, Button, Menu, Dropdown, Modal, Table
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import ContentWithLeftButtons from 'common/components/ContentWithLeftButtons';
import { dateToLocale } from 'common/utils/misc';
import { useGet, useDelete } from 'common/api';

import { useDebt } from 'modules/debt/_contexts/DebtContext';
import {
  DEBT_PAYMENT_LIST,
  DEBT_INSTALLMENT_LIST,
  DEBT_DEBT_DASHBOARD
} from 'modules/debt/_constants/cacheKeys';

const { confirm } = Modal;

const PaymentList = () => {
  const intl = useIntl();
  const { installmentId, debtId } = useParams();
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const debt = useDebt();

  const cacheKey = [DEBT_PAYMENT_LIST, installmentId];

  const { data, isLoading } = useGet(cacheKey, '/debt/payment', {
    params: { installmentId }
  });

  const { mutate } = useDelete(id => `/debt/payment/${id}`, {
    cacheInvalidate: [
      cacheKey,
      [DEBT_INSTALLMENT_LIST, debtId],
      [DEBT_DEBT_DASHBOARD, debtId],
    ]
  });

  const renderAmountColumn = currentData => {
    const {
      value,
      valueExecuted,
      currency
    } = currentData;

    const getExecutedAmount = () => {
      if (currency.id === debt.currencyId) { return null; }

      return (
        <>
          <br />
          <FormattedNumber
            value={valueExecuted}
            maximumFractionDigits={2}
            style="currency"
            currency={currency.symbol}
          />
        </>
      );
    };

    return (
      <>
        <FormattedNumber
          value={value}
          maximumFractionDigits={2}
          style="currency"
          currency={debt?.currency?.symbol}
        />
        {getExecutedAmount()}
      </>
    );
  };

  const menuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`details/payment/${id}/edit`}>
              <FormattedMessage id="edit" />
            </Link>
          </Menu.Item>

          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.delete_item' }),
                onOk: () => mutate(id)
              });
            }}
          >
            <FormattedMessage id="delete" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      title: <FormattedMessage id="date" />,
      render: ({ executedOn }) => <div>{dateToLocale(executedOn)}</div>,
      key: 'executedOn',
      width: '16%'
    },
    {
      title: <FormattedMessage id="value" values={{ plural: false }} />,
      render: renderAmountColumn,
      key: 'value',
      width: '14%',
      align: 'right'
    },
    {
      title: <FormattedMessage id="note" />,
      dataIndex: 'note',
      key: 'note'
    },
    {
      render: ({ id }) => menuActions(id),
      key: '7',
      width: '3%',
      align: 'right'
    }
  ];

  const renderButtons = () => (
    <Tooltip title={<FormattedMessage id="add" />}>
      <Button
        onClick={() => push(`${url}/payment/add`)}
        icon={<PlusOutlined />}
      />
    </Tooltip>
  );

  return (
    <ContentWithLeftButtons buttons={renderButtons()}>
      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        showHeader
        size="middle"
        loading={isLoading}
        pagination={false}
      />
    </ContentWithLeftButtons>
  );
};

export default PaymentList;
