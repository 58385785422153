import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Tooltip, Button, Menu, Dropdown, Table, Modal
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import ContentWithLeftButtons from 'common/components/ContentWithLeftButtons';
import { useGet, useDelete } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import { PROJECT_STATUS_LIST } from 'modules/project/_constants/cacheKeys';
import { iconList } from '../static/IconListStatic';

const { confirm } = Modal;

const StatusList = () => {
  const intl = useIntl();
  const { push } = useHistory();
  const { id: organizationId } = useOrganization();

  const cacheKey = [PROJECT_STATUS_LIST, organizationId];

  const { data, isLoading } = useGet(cacheKey, '/project/status');

  const { mutate } = useDelete(id => `/project/status/${id}`, { cacheInvalidate: [cacheKey] });

  const renderMenuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item
            key="0"
            onClick={() => push(`status/${id}/edit`)}
          >
            <FormattedMessage id="edit" />
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.inactivate_item' }),
                onOk: () => mutate(id)
              });
            }}
          >
            <FormattedMessage id="inactivate" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      key: 'status',
      render: ({ icon, color, name }) => {
        const StatusIcon = iconList[icon];
        return (
          <span>
            <StatusIcon
              style={{ marginRight: 6, color }}
            />
            {name}
          </span>
        );
      }
    },
    {
      render: ({ finished }) => (
        <span>{finished ? <FormattedMessage id="finished" /> : null}</span>
      ),
      key: 'finished',
      width: '25%',
      align: 'center'
    },
    {
      key: 'actions',
      width: '3%',
      align: 'right',
      render: (text, record) => renderMenuActions(record.id)
    }
  ];

  const renderButtons = () => (
    <Tooltip title={<FormattedMessage id="add" />}>
      <Button
        onClick={() => { push('status/add'); }}
        icon={<PlusOutlined />}
        style={{ marginBottom: 10 }}
      />
    </Tooltip>
  );

  return (
    <ContentWithLeftButtons buttons={renderButtons()}>
      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        showHeader={false}
        size="middle"
        loading={isLoading}
        pagination={false}
      />
    </ContentWithLeftButtons>
  );
};

export default StatusList;
