/**
 * Converte conteúdo dataURL para Blob, que poderá ser adicionado ao formData.
 *
 * @return Blob
 */
const dataURLtoBlob = dataURL => {
  const BASE64_MARKER = ';base64,';
  let parts = null;
  let contentType = null;
  let raw = null;
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(',');
    [, contentType] = parts[0].split(':');
    [, raw] = parts;

    return new Blob([raw], { type: contentType });
  }

  parts = dataURL.split(BASE64_MARKER);
  [, contentType] = parts[0].split(':');
  raw = window.atob(parts[1]);
  const rawLength = raw.length;

  const uInt8Array = new Uint8Array(rawLength);

  for (let i = 0; i < rawLength; i += 1) {
    uInt8Array[i] = raw.charCodeAt(i);
  }

  return new Blob([uInt8Array], { type: contentType });
};

export default dataURLtoBlob;
