import { useQueryClient } from 'react-query';

import useGet from './hooks/useFetchGet';
import useFetch from './hooks/useFetch';

const usePost = useFetch('POST');
const usePut = useFetch('PUT');
const usePatch = useFetch('PATCH');
const useDelete = useFetch('DELETE');

export {
  useGet,
  usePost,
  usePut,
  usePatch,
  useDelete,
  useQueryClient
};
