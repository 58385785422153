import React from 'react';
import { useParams } from 'react-router-dom';
import { Table } from 'antd';

import { useGet } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import { DEBT_CALCULATION_LIST } from 'modules/debt/_constants/cacheKeys';

const CalculationSelectableTree = props => {
  const { shared, checkedKeys, onCheck } = props;
  const { id: organizationId } = useOrganization();
  const { debtId } = useParams();
  const { data, isLoading } = useGet([DEBT_CALCULATION_LIST, organizationId, !shared ? debtId : 'shared'], '/debt/calculation', {
    params: {
      organizationId,
      debtId: !shared ? debtId : null,
      shared
    }
  });

  const renderTableColumns = [{
    dataIndex: 'name',
    key: '1',
  }];

  const rowSelection = {
    columnWidth: '3%',
    selectedRowKeys: checkedKeys,
    onChange: onCheck
  };

  return (
    <Table
      dataSource={data}
      rowKey="id"
      columns={renderTableColumns}
      showHeader={false}
      size="small"
      loading={isLoading}
      rowSelection={rowSelection}
      pagination={false}
    />
  );
};

export default CalculationSelectableTree;
