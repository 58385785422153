import React, { useEffect } from 'react';
import {
  Redirect, useParams, useRouteMatch, useHistory, useLocation
} from 'react-router-dom';
import {
  Row, Col, Menu, Divider
} from 'antd';
import { BankOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import ProjectDetailsPageRoutes from 'modules/project/_routes/ProjectDetailsPageRoutes';

import { useProject, useProjectUpdate } from 'modules/project/_contexts/ProjectContext';

const ProjectDetailsPage = () => {
  const projectData = useProject();
  const updateProject = useProjectUpdate();
  const { push } = useHistory();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const { projectId } = useParams();

  const menu = pathname.match(/(?:\/project\/\d+\/)(\w+)/);

  useEffect(() => {
    if (menu) updateProject(projectId);
  }, []);

  if (!menu) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      {projectData
      && (
        <div style={{ fontSize: 18, marginBottom: 15 }}>
          <span style={{ fontSize: 32, marginRight: 10, lineHeight: 1 }}>
            {projectData.shortName ? `${projectData.shortName}` : ''}
          </span>
          {projectData.name}
        </div>
      )}
      <Row gutter={20}>
        <Col xs={24} sm={10} md={6} lg={5} xl={3}>
          <Menu mode="inline" selectedKeys={[menu[1]]}>
            <Menu.Item
              key="resume"
              onClick={() => push(`${url}/resume`)}
            >
              <FormattedMessage id="resume" />
            </Menu.Item>
            <Menu.Item
              key="task"
              onClick={() => push(`${url}/task`)}
            >
              <FormattedMessage id="task" values={{ plural: true }} />
            </Menu.Item>
            <Menu.Item
              key="file"
              onClick={() => push(`${url}/file`)}
            >
              <FormattedMessage id="file" values={{ plural: true }} />
            </Menu.Item>
            {/*
          <Menu.Item key="notes">
            <FormattedMessage id="annotation" values={{ plural: true }} />
          </Menu.Item>
           <Menu.Item key="team">
            <FormattedMessage id="team" />
          </Menu.Item>
          <Menu.Item key="categories">
            <FormattedMessage id="category" values={{ plural: true }} />
          </Menu.Item>
          <Menu.Item key="metadata">
            <FormattedMessage id="metadata" />
          </Menu.Item>
          <Menu.Item key="organizations">
            <FormattedMessage id="organization" values={{ plural: true }} />
          </Menu.Item> */}
          </Menu>

          <Divider orientation="left" plain style={{ marginTop: 30 }}>
            <FormattedMessage id="module" values={{ plural: true }} />
          </Divider>

          <Menu mode="inline">
            <Menu.Item
              key="resume"
              icon={<BankOutlined />}
              onClick={() => push(`/debt/${projectId}/list`)}

            >
              <FormattedMessage id="debt" values={{ plural: true }} />
            </Menu.Item>
          </Menu>
        </Col>

        <Col xs={24} sm={14} md={18} lg={19} xl={21}>
          <ProjectDetailsPageRoutes />
        </Col>
      </Row>
    </>
  );
};
export default ProjectDetailsPage;
