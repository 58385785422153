import React from 'react';
import PropTypes from 'prop-types';

const ContentWithLeftButtons = ({ buttons, children }) => {
  if (buttons) {
    return (
      <div className="list-with-buttons">
        <div className="buttons">
          { buttons }
        </div>
        <div className="list-container">
          { children }
        </div>
      </div>
    );
  }

  if (children) {
    return children;
  }

  return null;
};

ContentWithLeftButtons.propTypes = {
  buttons: PropTypes.shape({}),
  children: PropTypes.node
};

ContentWithLeftButtons.defaultProps = {
  buttons: null,
  children: null
};

export default ContentWithLeftButtons;
