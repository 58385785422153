import React, { useState } from 'react';
import { PrinterOutlined, CloudDownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Popover, Empty, Row, Col, Tag, Spin
} from 'antd';

import { useIntl } from 'react-intl';

import { dateToLocale } from 'common/utils/misc';
import { reportFileUrl } from 'config/app';
import { useGet, useQueryClient } from 'common/api';

import { REPORT_NOTIFICATION_LIST } from 'core/_constants/cacheKeys';

import './style.css';

const ReportNotification = ({ style }) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const [visible, setVisible] = useState(false);

  const { data, isLoading, isFetching } = useGet(REPORT_NOTIFICATION_LIST, '/gateway/report/request', {
    staleTime: 60000, // 1 sec
    query: { enabled: Boolean(visible) }
  });

  const popoverTitle = () => (
    <Row align="middle" justify="space-between">
      <Col>{intl.formatMessage({ id: 'report' }, { plural: true })}</Col>
      <Col>
        <ReloadOutlined onClick={() => queryClient.invalidateQueries([REPORT_NOTIFICATION_LIST])} />
      </Col>
    </Row>
  );

  const getTag = status => {
    const tags = {
      ready: <Tag color="green">{intl.formatMessage({ id: 'ready' })}</Tag>,
      cancelled: <Tag color="magenta">{intl.formatMessage({ id: 'cancelled' })}</Tag>,
      execution: <Tag color="orange">{intl.formatMessage({ id: 'execution' })}</Tag>
    };

    return tags[status] || tags.execution;
  };

  const getDownloadIcon = req => {
    if (req.jrStatus === 'ready') {
      return (
        <a href={`${reportFileUrl}/${req.file}`} target="_blank" rel="noopener noreferrer">
          <CloudDownloadOutlined style={{ fontSize: 28, color: '#666666', cursor: 'pointer' }} />
        </a>
      );
    }

    return <CloudDownloadOutlined style={{ fontSize: 28, color: '#CDCDCD' }} />;
  };

  const renderContent = () => {
    const items = data?.rows && data.rows.map(req => (
      <li key={req.id}>
        <Row align="middle" gutter={8} justify="space-between">
          <Col>
            <small>{dateToLocale(req.createdAt, 'L HH:mm')}</small>
            {req.report.name}
            {getTag(req.jrStatus)}
          </Col>
          <Col>
            {getDownloadIcon(req)}
          </Col>
        </Row>
      </li>
    ));

    if (data?.rows?.length || isLoading || isFetching) {
      return (
        <Spin spinning={isLoading || isFetching}>
          <ul className="report-list">
            {items}
          </ul>
        </Spin>
      );
    }

    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  return (
    <Popover
      content={renderContent}
      title={popoverTitle}
      trigger="click"
      placement="bottomRight"
      overlayClassName="topbar-notification"
      onVisibleChange={setVisible}
    >
      <PrinterOutlined
        style={{
          cursor: 'pointer',
          ...style
        }}
      />
    </Popover>
  );
};

export default ReportNotification;
