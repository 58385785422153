import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import ResourceForm from './ResourceForm';

class ResourceFormPage extends Component {
  state = {
    form: {
      id: null,
      parentId: null,
      name: null,
      route: null,
      method: null
    },

    dataGrouper: [],
    loading: false
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    if (id) {
      this.loadDataToUpdate(parseInt(id, 10));
    }

    this.loadDataGrouper();
  }

  handleStore = async () => {
    const { form } = this.state;
    const { history } = this.props;

    this.setState({ loading: true });
    let response = null;

    if (!form.id) {
      response = await api('/access/resource', {
        method: 'POST',
        body: form
      });
    } else {
      response = await api(`/access/resource/${form.id}`, {
        method: 'PUT',
        body: form
      });
    }

    responseHandler(response);
    this.setState({ loading: false });

    if (response.ok) {
      history.push('/access/resource');
    }
  };

  loadDataToUpdate = async id => {
    this.setState({ loading: true });

    const body = await api('/access/resource', {
      body: { id }
    });

    this.setState({
      form: { id, ...body[0] },
      loading: false
    });
  };

  loadDataGrouper = async () => {
    this.setState({ loading: true });

    const body = await api('/access/resource/tree');

    this.setState({
      dataGrouper: body,
      loading: false
    });
  };

  render() {
    const { history } = this.props;
    const { form, dataGrouper, loading } = this.state;

    return (
      <ResourceForm
        onCancel={history.goBack}
        onStore={this.handleStore}
        setStateForm={value => this.setState({
          form: { ...form, ...value }
        })}
        getStateForm={form}
        getDataGrouper={dataGrouper}
        getLoading={loading}
      />
    );
  }
}

export default ResourceFormPage;
