import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Tooltip,
  Button,
  Menu,
  Dropdown,
  Table,
  Modal,
  Drawer,
  DatePicker,
  Row,
  Col
} from 'antd';
import {
  MoreOutlined,
  LockOutlined,
  CheckCircleOutlined,
  WarningOutlined,
  PlusOutlined,
  LeftOutlined,
  RightOutlined,
  DeleteOutlined,
  UploadOutlined,
  CaretRightOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { useIntl } from 'react-intl';
import moment from 'moment';

import ContentWithLeftButtons from 'common/components/ContentWithLeftButtons';
import { useGet, useDelete, usePost } from 'common/api';
import { dateToLocale } from 'common/utils/misc';
import { showCsvUrl } from 'config/app';

import { useDebtYear, useDebtYearUpdate } from 'modules/debt/_contexts/DebtYearContext';
import InstallmentCalculationForm from 'modules/debt/installmentCalculation/components/InstallmentCalculationForm';

import {
  DEBT_INSTALLMENT_LIST,
  DEBT_COMPOSITION_LIST,
  DEBT_INSTALLMENT,
  DEBT_DEBT_DASHBOARD
} from 'modules/debt/_constants/cacheKeys';

const ButtonGroup = Button.Group;
const { confirm } = Modal;

const InstallmentList = () => {
  const { formatMessage, formatNumber } = useIntl();
  const { debtId } = useParams();
  const [selectedRows, setSelectedRows] = useState([]);
  const [drawerCalcVisible, setDrawerCalcVisible] = useState(false);

  const year = useDebtYear();
  const setYear = useDebtYearUpdate();

  const hasRowSelected = selectedRows.length > 0;

  const { data, isLoading } = useGet([DEBT_INSTALLMENT_LIST, debtId, year], '/debt/installment', {
    params: { debtId, year }
  });

  const deleteOptions = {
    cacheInvalidate: [
      [DEBT_INSTALLMENT_LIST, debtId],
      [DEBT_DEBT_DASHBOARD, debtId]
    ],
    onSuccess: () => setSelectedRows([])
  };

  const { mutate } = useDelete(id => `/debt/installment/${id}`, deleteOptions);
  const { mutate: mutateDeleteAll } = useDelete(() => `/debt/installment/destroy-all/${debtId}`, deleteOptions);
  const { mutate: mutateDeleteSelected } = useDelete(
    () => `/debt/installment/destroy-selected/${debtId}/${encodeURIComponent(selectedRows.toString())}`,
    deleteOptions
  );

  const options = {
    fetch: { body: { debtId } },
    cacheInvalidate: [
      [DEBT_INSTALLMENT_LIST, debtId],
      [DEBT_COMPOSITION_LIST, debtId],
      [DEBT_DEBT_DASHBOARD, debtId],
      [DEBT_INSTALLMENT]
    ]
  };
  const { mutate: mutateRecalculate, isLoading: isLoadingRecalculate } = usePost(() => '/debt/debt/recalculate', options);

  const optionsGetCSV = {
    onSuccess: csvFileName => {
      window.open(`${showCsvUrl}/${csvFileName}`, '_blank');
    },
  };
  const { mutate: mutateGetCSV, isLoadingCSV } = usePost(() => `/debt/debt/csv/${debtId}`, optionsGetCSV);

  const menuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`installment/${id}/edit`}>
              {formatMessage({ id: 'edit' })}
            </Link>
          </Menu.Item>

          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: formatMessage({ id: 'message.delete_item' }),
                onOk: () => mutate(id)
              });
            }}
          >
            {formatMessage({ id: 'delete' })}
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = [
    {
      title: formatMessage({ id: 'number' }),
      dataIndex: 'number',
      key: '1',
      width: '6%',
      align: 'center'
    },
    {
      title: formatMessage({ id: 'period' }),
      render: ({
        id,
        startsOn,
        endsOn,
        blocked,
        paymentTotal,
        countManualComposition
      }) => (
        <div>
          <Link to={`installment/${id}/details`}>
            {`${dateToLocale(
              startsOn,
              'L',
              true
            )} ~ ${dateToLocale(endsOn, 'L', true)}`}
          </Link>
          {blocked && (
            <Tooltip title={formatMessage({ id: 'blocked' })}>
              <LockOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          )}
          {paymentTotal > 0 && (
            <Tooltip title={formatMessage({ id: 'paid' })}>
              <CheckCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          )}
          {countManualComposition > 0 && (
            <Tooltip title={formatMessage({ id: 'manual_data_entry' })}>
              <WarningOutlined style={{ marginLeft: 4, color: 'red' }} />
            </Tooltip>
          )}
        </div>
      ),
      key: '2'
    },
    {
      title: formatMessage({ id: 'due_date' }, { plural: false }),
      render: ({ dueOn }) => dateToLocale(dueOn, 'L', true),
      key: '3',
      width: '10%',
      align: 'center'
    },
    {
      title: formatMessage({ id: 'planned' }),
      children: [
        {
          title: formatMessage({ id: 'due' }),
          render: rec => {
            const { compositionTotal, debt: { currency: { symbol } } } = rec;
            return formatNumber(compositionTotal, {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: symbol,
            });
          },
          key: '5',
          width: '14%',
          align: 'right'
        },
        {
          title: formatMessage({ id: 'balance' }),
          render: ({ balance, debt: { currency: { symbol } } }) => formatNumber(balance, {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: symbol
          }),
          key: '6',
          width: '14%',
          align: 'right'
        },
      ]
    },
    {
      title: formatMessage({ id: 'realized' }),
      children: [
        {
          title: formatMessage({ id: 'due' }),
          render: ({ compositionRealizedTotal, debt: { currency: { symbol } } }) => formatNumber(compositionRealizedTotal, {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: symbol,
          }),
          key: '5',
          width: '14%',
          align: 'right'
        },
        {
          title: formatMessage({ id: 'balance' }),
          render: rec => {
            const { balanceRealized, debt: { currency: { symbol } } } = rec;
            return formatNumber(balanceRealized, {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: symbol
            });
          },
          key: '6',
          width: '14%',
          align: 'right'
        },
      ]
    },
    {
      render: ({ id }) => menuActions(id),
      key: '7',
      width: '3%',
      align: 'center'
    }
  ];

  const handleOnCloseDrawer = () => {
    setDrawerCalcVisible(false);
  };

  const rowSelection = {
    columnWidth: '3%',
    selectedRows,
    onChange: setSelectedRows,
    getCheckboxProps: rec => ({
      disabled: rec.blocked
    })
  };

  const renderButtons = () => (
    <>
      <Tooltip title={formatMessage({ id: 'add' })} placement="left">
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu>
              <Menu.Item key="0">
                <Link to="installment/add-auto">
                  {formatMessage({ id: 'auto_generate' })}
                </Link>
              </Menu.Item>
              <Menu.Item key="1">
                <Link to="installment/add-manual">
                  {formatMessage({ id: 'add_manually' })}
                </Link>
              </Menu.Item>
            </Menu>
          )}
        >
          <Button icon={<PlusOutlined />} />
        </Dropdown>
      </Tooltip>

      <Tooltip
        title={(
          <span>
            {formatMessage({ id: 'set' })}
            {' '}
            {formatMessage({ id: 'calculation' }, { plural: true })}
          </span>
        )}
        placement="left"
      >
        <Button
          icon={<SettingOutlined />}
          onClick={() => setDrawerCalcVisible(true)}
          disabled={!hasRowSelected}
        />
      </Tooltip>

      <Tooltip title={formatMessage({ id: 'delete' })} placement="left">
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu>
              <Menu.Item
                key="0"
                disabled={!hasRowSelected}
                onClick={() => {
                  confirm({
                    title: formatMessage({ id: 'message.delete' }),
                    onOk: () => mutateDeleteSelected()
                  });
                }}
              >
                {formatMessage({ id: 'delete_selected' })}
              </Menu.Item>
              <Menu.Item
                key="1"
                onClick={() => {
                  confirm({
                    title: formatMessage({ id: 'message.delete' }),
                    onOk: () => mutateDeleteAll()
                  });
                }}
              >
                {formatMessage({ id: 'delete_all' })}
              </Menu.Item>
            </Menu>
          )}
        >
          <Button icon={<DeleteOutlined />} />
        </Dropdown>
      </Tooltip>
    </>
  );

  return (
    <ContentWithLeftButtons buttons={renderButtons()}>
      <Row justify="space-between">
        <Col>
          <DatePicker
            picker="year"
            style={{ marginBottom: 5, width: 120 }}
            onChange={date => setYear(date ? date.format('YYYY') : null)}
            value={year ? moment(`${year}-01-01`) : null}
          />

          <ButtonGroup style={{ marginRight: 14 }}>
            <Button
              icon={<LeftOutlined />}
              style={{ width: 50 }}
              onClick={() => setYear(`${parseInt(year, 10) - 1}`)}
            />
            <Button
              icon={<RightOutlined />}
              style={{ width: 50 }}
              onClick={() => setYear(`${parseInt(year, 10) + 1}`)}
            />
          </ButtonGroup>

          <Button
            icon={<CaretRightOutlined />}
            onClick={() => {
              confirm({
                title: formatMessage({ id: 'message.recalculate' }),
                onOk: () => mutateRecalculate()
              });
            }}
          >
            {formatMessage({ id: 'recalculate' })}
          </Button>
        </Col>
        <Col>
          <Button
            type="dashed"
            shape="round"
            icon={<UploadOutlined />}
            loading={isLoadingCSV}
            onClick={mutateGetCSV}
          >
            CSV
          </Button>
        </Col>
      </Row>

      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns}
        showHeader
        size="middle"
        loading={isLoading || isLoadingRecalculate}
        pagination={false}
        rowSelection={rowSelection}
        bordered
      />

      <Drawer
        title={formatMessage({ id: 'calculation' }, { plural: true })}
        visible={drawerCalcVisible}
        onClose={handleOnCloseDrawer}
        width={480}
      >
        <InstallmentCalculationForm
          debtId={debtId}
          installmentList={selectedRows}
          onCancel={handleOnCloseDrawer}
          formLayout="vertical"
        />
      </Drawer>
    </ContentWithLeftButtons>
  );
};

export default InstallmentList;
