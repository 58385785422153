import React, {
  createContext, useContext, useState, useEffect
} from 'react';

const AuthContext = createContext();
const AuthUpdateContext = createContext();

export const useAuth = () => useContext(AuthContext);
export const useAuthUpdate = () => useContext(AuthUpdateContext);

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(JSON.parse(localStorage.getItem('auth')));

  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify(data));
  }, [data]);

  return (
    <AuthContext.Provider value={data}>
      <AuthUpdateContext.Provider value={setData}>
        { children }
      </AuthUpdateContext.Provider>
    </AuthContext.Provider>
  );
};
