import React, { Component } from 'react';
import { withRouter } from 'react-router';

import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import ContactList from './ContactList';

class ContactListContainer extends Component {
  state = {
    data: [],
    loading: false
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { match } = this.props;
    const { personId } = match.params;

    this.setState({ loading: true });

    const body = await api('/shared/contact', {
      body: { personId }
    });

    this.setState({
      data: body,
      loading: false
    });
  };

  handleDestroy = async id => {
    this.setState({ loading: true });

    const response = await api(`/shared/contact/${id}`, {
      method: 'DELETE'
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      this.loadData();
    }
  };

  render() {
    const { history } = this.props;
    const { data, loading } = this.state;

    return (
      <ContactList
        data={data}
        onDestroy={this.handleDestroy}
        onGoToAdd={() => history.push('contact/add')}
        onGoToEdit={id => history.push(`contact/${id}/edit`)}
        loading={loading}
      />
    );
  }
}

export default withRouter(ContactListContainer);
