import React from 'react';
import { Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import BreadcrumbFromUrl from 'common/components/BreadcrumbFromUrl';

import PersonFormContainer from './PersonFormContainer';
import ContactListContainer from '../../contact/components/ContactListContainer';
import ContactFormContainer from '../../contact/components/ContactFormContainer';
import DocListContainer from '../../doc/components/DocListContainer';
import DocFormContainer from '../../doc/components/DocFormContainer';
import AddressListContainer from '../../address/components/AddressListContainer';
import AddressFormContainer from '../../address/components/AddressFormContainer';

const breadcrumbMap = {
  '/edit': {
    title: <FormattedMessage id="basic" />
  },
  '/contact': {
    title: <FormattedMessage id="contact" values={{ plural: true }} />
  },
  '/contact/add': {
    title: <FormattedMessage id="register" />
  },
  '/contact/!/edit': {
    title: <FormattedMessage id="change" />
  },
  '/doc': {
    title: <FormattedMessage id="document" values={{ plural: true }} />
  },
  '/doc/add': {
    title: <FormattedMessage id="register" />
  },
  '/doc/!/edit': {
    title: <FormattedMessage id="change" />
  },
  '/address': {
    title: <FormattedMessage id="address" values={{ plural: true }} />
  },
  '/address/add': {
    title: <FormattedMessage id="register" />
  },
  '/address/!/edit': {
    title: <FormattedMessage id="change" />
  }
};

const PersonDetailsPageRoutes = () => (
  <span>
    <BreadcrumbFromUrl className="inner" breadcrumbMap={breadcrumbMap} />

    <Route
      path="/shared/person/:personId/details/edit"
      component={PersonFormContainer}
    />

    <Route
      path="/shared/person/:personId/details/contact"
      component={ContactListContainer}
      exact
    />

    <Route
      path="/shared/person/:personId/details/contact/add"
      component={ContactFormContainer}
    />

    <Route
      path="/shared/person/:personId/details/contact/:contactId/edit"
      component={ContactFormContainer}
    />

    <Route
      path="/shared/person/:personId/details/doc"
      component={DocListContainer}
      exact
    />

    <Route
      path="/shared/person/:personId/details/doc/add"
      component={DocFormContainer}
    />

    <Route
      path="/shared/person/:personId/details/doc/:docId/edit"
      component={DocFormContainer}
    />

    <Route
      path="/shared/person/:personId/details/address"
      component={AddressListContainer}
      exact
    />

    <Route
      path="/shared/person/:personId/details/address/add"
      component={AddressFormContainer}
    />

    <Route
      path="/shared/person/:personId/details/address/:addressId/edit"
      component={AddressFormContainer}
    />
  </span>
);

export default PersonDetailsPageRoutes;
