import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { useAuth } from 'core/_contexts/AuthContext';

const { Option } = Select;

const SelectOrganization = props => {
  const { placeholder, onChange } = props;
  const { organizationList } = useAuth();

  const options = organizationList.map(v => (
    <Option key={`${v.id}`} value={`${v.id}`}>
      {v.name}
    </Option>
  ));

  return (
    <Select
      onChange={id => onChange(id)}
      size="default"
      style={{ width: '100%' }}
      placeholder={placeholder}
    >
      {options}
    </Select>
  );
};

SelectOrganization.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.shape({})
};

SelectOrganization.defaultProps = {
  placeholder: null
};

export default SelectOrganization;
