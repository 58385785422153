import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Switch, Select
} from 'antd';
import { FormattedMessage } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

import ContactTypeStatic from '../../contactType/static/ContactTypeStatic';

const FormItem = Form.Item;
const { Option } = Select;

const ContactForm = props => {
  const {
    getStateForm, setStateForm, onStore, loading, onCancel
  } = props;

  const renderTypeOptions = () => Object.entries(ContactTypeStatic.typeList)
    .map(([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} />
      </Option>
    ));

  return (
    <Form className="soft-box">
      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="type" />}
      >
        <Select
          value={`${getStateForm.type}`}
          onChange={value => setStateForm({ ...getStateForm, type: value })}
          style={{ display: 'inline-block' }}
        >
          {renderTypeOptions()}
        </Select>
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="description" />}
      >
        <Input
          onChange={e => setStateForm({
            ...getStateForm,
            description: e.target.value
          })}
          value={getStateForm.description}
          maxLength={50}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="main" />}
      >
        <Switch
          checked={getStateForm.principal}
          onChange={value => setStateForm({ ...getStateForm, principal: value })}
        />
      </FormItem>

      <FormSubmitButtons
        onStore={onStore}
        onCancel={onCancel}
        loading={loading}
      />
    </Form>
  );
};

ContactForm.propTypes = {
  onStore: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  setStateForm: PropTypes.func.isRequired,
  getStateForm: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired
};

export default ContactForm;
