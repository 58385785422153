import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Tooltip, Button, Menu, Dropdown, Modal, Table
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

const { confirm } = Modal;

class IndexList extends Component {
  state = {
    data: [],
    loading: true
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });

    const body = await api('/shared/index');

    this.setState({
      data: body,
      loading: false
    });
  };

  onDestroy = async id => {
    this.setState({ loading: true });

    const response = await api(`/shared/index/${id}`, {
      method: 'DELETE'
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      this.loadData();
    }
  };

  menuActions = id => {
    const { intl } = this.props;

    return (
      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu>
            <Menu.Item key="0">
              <Link to={`index/${id}/edit`}>
                <FormattedMessage id="edit" />
              </Link>
            </Menu.Item>

            <Menu.Item
              key="1"
              onClick={() => {
                confirm({
                  title: intl.formatMessage({ id: 'message.inactivate_item' }),
                  onOk: () => this.onDestroy(id)
                });
              }}
            >
              <FormattedMessage id="inactivate" />
            </Menu.Item>
          </Menu>
        )}
      >
        <MoreOutlined className="more-options" />
      </Dropdown>
    );
  }

  renderTableColumns = () => [
    {
      title: <FormattedMessage id="name" />,
      render: ({ id, name }) => <Link to={`index/${id}/value`}>{name}</Link>,
      key: '1',
      width: '25%'
    },
    {
      title: <FormattedMessage id="alias" />,
      dataIndex: 'alias',
      key: '2',
      width: '10%'
    },
    {
      title: 'Global',
      render: ({ organizationId }) => (organizationId ? (
        <FormattedMessage id="no" />
      ) : (
        <FormattedMessage id="yes" />
      )),
      key: '3',
      width: '10%',
      align: 'center'
    },
    {
      title: <FormattedMessage id="description" />,
      dataIndex: 'description',
      key: '4'
    },
    {
      render: ({ id }) => this.menuActions(id),
      key: '5',
      width: '3%',
      align: 'right'
    }
  ];

  render() {
    const { history } = this.props;
    const { data, loading } = this.state;

    return (
      <div className="list-with-buttons">
        <div className="buttons">
          <Tooltip title={<FormattedMessage id="add" />}>
            <Button
              onClick={() => history.push('index/add')}
              icon={<PlusOutlined />}
            />
          </Tooltip>
        </div>

        <div className="list-container">
          <Table
            dataSource={data}
            rowKey="id"
            columns={this.renderTableColumns()}
            showHeader
            size="middle"
            loading={loading}
            pagination={false}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(IndexList);
