import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { LineChartOutlined } from '@ant-design/icons';

import BreadcrumbFromUrl from 'common/components/BreadcrumbFromUrl';

import { useModuleUpdate } from 'core/_contexts/ModuleContext';

import { ProjectProvider } from 'modules/project/_contexts/ProjectContext';
import ProjectList from '../project/components/ProjectList';
import ProjectForm from '../project/components/ProjectForm';
import StatusList from '../status/components/StatusList';
import StatusForm from '../status/components/StatusForm';
import TagList from '../tag/components/TagList';
import TagForm from '../tag/components/TagForm';
import ProjectDetailsPage from '../project/pages/ProjectDetails';

const breadcrumbMap = {
  '/project': {
    title: (
      <span>
        <LineChartOutlined />
        <FormattedMessage id="project" values={{ plural: true }} />
      </span>
    ),
    noLink: true
  },
  '/project/add': {
    title: <FormattedMessage id="register" />
  },
  '/project/!/edit': {
    title: <FormattedMessage id="change" />
  },
  '/project/list': {
    title: <FormattedMessage id="select" />
  },
  '/project/status': {
    title: 'Status'
  },
  '/project/status/add': {
    title: <FormattedMessage id="register" />
  },
  '/project/status/!/edit': {
    title: <FormattedMessage id="change" />
  },
  '/project/tag': {
    title: <FormattedMessage id="tag" values={{ plural: true }} />
  },
  '/project/tag/!/edit': {
    title: <FormattedMessage id="change" />
  },
};

const ProjectRoutes = () => {
  const moduleUpdate = useModuleUpdate();

  useEffect(() => {
    moduleUpdate('project');
  }, []);

  return (
    <>
      <BreadcrumbFromUrl
        className=""
        breadcrumbMap={breadcrumbMap}
        withBaseUrl
      />

      <div style={{ padding: 20 }}>
        <Switch>
          <Route
            path="/project/list"
            key="project-select"
            component={ProjectList}
          />
          <Route
            path="/project/add"
            key="project-add"
            component={ProjectForm}
          />
          <Route
            path="/project/:projectId/edit"
            key="project-edit"
            component={ProjectForm}
          />
          <Route
            exact
            path="/project/status"
            key="status-list"
            component={StatusList}
          />
          <Route
            path="/project/status/add"
            key="status-add"
            component={StatusForm}
          />
          <Route
            path="/project/status/:statusId/edit"
            key="status-edit"
            component={StatusForm}
          />
          <Route
            exact
            path="/project/tag"
            key="tag-list"
            component={TagList}
          />
          <Route
            path="/project/tag/:tagId/edit"
            key="tag-edit"
            component={TagForm}
          />
          <ProjectProvider>
            <Route
              path="/project/:projectId"
              key="project"
              component={ProjectDetailsPage}
            />
          </ProjectProvider>
        </Switch>
      </div>
    </>
  );
};

export default ProjectRoutes;
