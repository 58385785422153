import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Table, Tooltip, Button, Dropdown, Menu, Modal
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

const { confirm } = Modal;

const ProfileList = props => {
  const {
    intl, onDestroy, onGoToForm, data, getLoading
  } = props;

  const menuActions = record => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`/access/profile/edit/${record.id}`}>
              <FormattedMessage id="edit" />
            </Link>
          </Menu.Item>

          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.delete_item' }),
                onOk: () => onDestroy(record.id)
              });
            }}
          >
            <FormattedMessage id="delete" />
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      key: 'name',
      dataIndex: 'name',
      width: '97%'
    },
    {
      key: 'actions',
      title: '',
      width: '3%',
      align: 'right',
      render: (text, record) => menuActions(record)
    }
  ];

  return (
    <div>
      <Tooltip title={intl.formatMessage({ id: 'add' })}>
        <Button
          onClick={() => onGoToForm()}
          icon={<PlusOutlined />}
          style={{ paddingTop: '1px' }}
        />
      </Tooltip>

      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        size="middle"
        pagination={false}
        showHeader={false}
        loading={getLoading}
        style={{ marginTop: '5px' }}
      />
    </div>
  );
};

ProfileList.propTypes = {
  data: PropTypes.shape([]).isRequired,
  onDestroy: PropTypes.func.isRequired,
  onGoToForm: PropTypes.func.isRequired,
  getLoading: PropTypes.bool.isRequired
};

export default injectIntl(ProfileList);
