export const DEBT_CALCULATION = 'debt.calculation';
export const DEBT_CALCULATION_LIST = 'debt.calculationList';
export const DEBT_COMPOSITION = 'debt.composition';
export const DEBT_COMPOSITION_LIST = 'debt.compositionList';
export const DEBT_DEBT = 'debt.debt';
export const DEBT_INSTALLMENT = 'debt.installment';
export const DEBT_INSTALLMENT_LIST = 'debt.installmentList';
export const DEBT_LIST = 'debt.list';
export const DEBT_OPERATION = 'debt.operation';
export const DEBT_OPERATION_LIST = 'debt.operationList';
export const DEBT_PAYMENT = 'debt.payment';
export const DEBT_PAYMENT_LIST = 'debt.paymentList';
export const DEBT_DASHBOARD = 'debt.dashboard';
export const DEBT_DEBT_DASHBOARD = 'debt.debtDashboard';
export const DEBT_TYPE_LIST = 'debt.typeList';
