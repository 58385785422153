import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import ProfileList from './ProfileList';

class ProfileListPage extends Component {
  state = {
    data: [],
    loading: true
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });

    const body = await api('/access/profile');

    this.setState({
      data: body,
      loading: false
    });
  };

  handleDestroy = async id => {
    this.setState({ loading: true });

    const response = await api(`/access/profile/${id}`, {
      method: 'DELETE'
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      this.loadData();
    }
  };

  render() {
    const { data, loading } = this.state;
    const { history } = this.props;

    return (
      <ProfileList
        data={data}
        onDestroy={this.handleDestroy}
        onGoToForm={() => history.push('/access/profile/add')}
        getLoading={loading}
      />
    );
  }
}
export default ProfileListPage;
