import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import UserList from './UserList';

class UserListContainer extends Component {
  state = {
    data: [],
    loading: true,
    inactive: false // false=actives / true=inactive
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { inactive } = this.state;

    const body = await api('/access/user', {
      body: {
        deleted: inactive
      }
    });

    this.setState({
      data: body,
      loading: false
    });
  };

  handleDestroy = async id => {
    this.setState({ loading: true });

    const response = await api(`/access/user/${id}`, {
      method: 'DELETE'
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      this.loadData();
    }
  };

  handleRestore = async id => {
    this.setState({ loading: true });

    const response = await api(`/access/user/restore/${id}`, {
      method: 'PUT'
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      this.loadData();
    }
  };

  handleChangeSelectStatus = async value => {
    await this.setState({ inactive: value === 'true' });

    this.loadData();
  };

  render() {
    const { data, inactive, loading } = this.state;
    const { history } = this.props;

    return (
      <UserList
        data={data}
        onChangeSelectStatus={this.handleChangeSelectStatus}
        onDestroy={this.handleDestroy}
        onRestore={this.handleRestore}
        onGoToForm={() => history.push('/access/user/add')}
        showInactive={inactive}
        loading={loading}
      />
    );
  }
}

export default UserListContainer;
