import React from 'react';
import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

import { useProject } from 'modules/project/_contexts/ProjectContext';
import TaskList from '../../task/components/TaskList';
import ProjectStatusCard from '../../projectStatus/components/ProjectStatusCard';

import '../css/resume.css';

const ResumePage = () => {
  const projectData = useProject();

  return (
    <>
      <Row gutter={20}>
        <Col xs={24} sm={24} lg={12} xl={8}>
          <ProjectStatusCard
            data={projectData?.projectStatus}
          />
        </Col>
      </Row>

      <Row gutter={20} style={{ marginTop: 36 }}>
        <Col xs={24} lg={24}>
          <div style={{ marginBottom: 36 }}>
            <h5>
              <FormattedMessage id="highlighted_tasks" />
            </h5>
            <TaskList
              pageSize={15}
              withAddButton={false}
              withFilters={false}
              withBreadcrumb={false}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ResumePage;
