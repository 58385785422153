import React, { Component } from 'react';

import { api } from 'config/api';
import { initialUrl } from 'config/app';
import responseHandler from 'common/utils/responseHandler';

import UserChangePasswordForm from './UserChangePasswordForm';

class UserChangePasswordFormPage extends Component {
  state = {
    form: {
      currentPassword: null,
      password: null,
      passwordConfirmation: null
    },
    loading: false
  };

  handleStore = async () => {
    const { history } = this.props;
    const { form } = this.state;

    this.setState({ loading: true });

    const response = await api('/access/user/change-password', {
      method: 'PATCH',
      body: form
    });

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      history.replace(initialUrl);
    }
  };

  render() {
    const { history } = this.props;
    const { form, loading } = this.state;

    return (
      <UserChangePasswordForm
        onCancel={() => history.replace(initialUrl)}
        onStore={this.handleStore}
        setStateForm={value => this.setState({
          form: { ...form, ...value }
        })}
        loading={loading}
      />
    );
  }
}

export default UserChangePasswordFormPage;
