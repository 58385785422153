import React, { createContext, useContext, useState } from 'react';

import { useGet } from 'common/api';

import { DEBT_DEBT } from 'modules/debt/_constants/cacheKeys';

const DebtContext = createContext();
const DebtUpdateContext = createContext();

export const useDebt = () => useContext(DebtContext);
export const useDebtUpdate = () => useContext(DebtUpdateContext);

export const DebtProvider = ({ children }) => {
  const [id, setId] = useState(null);

  const cacheKey = [DEBT_DEBT, id];
  const { data } = useGet(cacheKey, '/debt/debt', {
    params: { id },
    query: { enabled: Boolean(id) }
  });

  return (
    <DebtContext.Provider value={data ? data[0] : {}}>
      <DebtUpdateContext.Provider value={setId}>
        { children }
      </DebtUpdateContext.Provider>
    </DebtContext.Provider>
  );
};
