class CompositionTypeStatic {
  static list = {
    r: 'monetary_restatement',
    i: 'interest',
    t: 'amortization',
    f: 'fee',
    a: 'administration_fee',
    k: 'risk_fee',
    c: 'commitment_fee',
    d: 'content_replaced'
  };
}

export default CompositionTypeStatic;
