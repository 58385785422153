/** @deprecated */

import React from 'react';
import { notification, Modal } from 'antd';

notification.config({ duration: 5 });

const responseHandler = async (response, body = null) => {
  let content = body;

  if (!content) {
    content = response.json && await response.json();
  }

  const { message } = content;

  if (response.ok) {
    notification.success({ message });
    return;
  }

  if (!response.ok) {
    const { status } = response;

    if (status >= 400 && status < 422) {
      Modal.error({ title: `Error ${status}: ${message}` });
      return;
    }

    if (status === 422) {
      const descriptionList = content.data && <DescriptionList errorList={content.data} />;

      Modal.error({
        title: message,
        content: descriptionList
      });
    }
  }
};

const DescriptionList = ({ errorList }) => (
  <ul style={{ listStyle: 'none', padding: 0, fontSize: '12px' }}>
    {Object.entries(errorList).map(([key, err]) => (
      <li key={`${key}`}>
        -
        {err.error}
      </li>
    ))}
  </ul>
);

export default responseHandler;
