import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Input, Spin } from 'antd';
import { useIntl } from 'react-intl';
import { CompactPicker } from 'react-color';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { useGet, usePost, usePut } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import { PROJECT_TAG, PROJECT_TAG_LIST } from 'modules/project/_constants/cacheKeys';

const FormItem = Form.Item;

const TagForm = () => {
  const intl = useIntl();
  const { goBack } = useHistory();
  const { tagId } = useParams();
  const { id: organizationId } = useOrganization();

  const [form, setForm] = useState({
    id: null,
    organizationId,
    name: null,
    color: ''
  });

  const cacheKey = [PROJECT_TAG, tagId];
  const { data, isLoading } = useGet(cacheKey, '/project/tag', {
    params: { id: tagId },
    query: { enabled: Boolean(tagId) }
  });
  useEffect(() => {
    if (data) setForm(data[0]);
  }, [data]);

  const options = {
    fetch: { body: form },
    onSuccess: goBack,
    cacheInvalidate: [
      [PROJECT_TAG_LIST, organizationId],
      tagId && [PROJECT_TAG, tagId]
    ]
  };
  const { mutate, isLoading: isLoadingMutate } = usePost(() => '/project/tag', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/project/tag/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  return (
    <Spin spinning={isLoading || isLoadingMutate || isLoadingUpdate}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'name' })}
        >
          <Input
            onChange={e => setFormData({ name: e.target.value })}
            value={form.name}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'color' })}
        >
          <CompactPicker
            color={form.color || ''}
            onChangeComplete={color => setFormData({ color: color.hex })}
          />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!tagId ? mutate() : mutateUpdate(tagId))}
          onCancel={() => { goBack(); }}
        />
      </Form>
    </Spin>
  );
};

export default TagForm;
