import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import RuleByProfile from './RuleByProfile';

class RuleByProfilePage extends Component {
  state = {
    organizationId: null,
    profileId: null,
    data: [],
    loading: false
  };

  loadData = async () => {
    const { organizationId, profileId } = this.state;

    if (!organizationId || !profileId) {
      return;
    }

    this.setState({ loading: true });

    const result = await api(
      `/access/rule/find-user-by-profile/${organizationId}/${
        profileId
      }`
    );

    this.setState({
      data: result,
      loading: false
    });
  };

  handleChangeOrganization = async organizationId => {
    await this.setState({ organizationId });

    this.loadData();
  };

  handleChangeProfile = async profileId => {
    await this.setState({ profileId });

    this.loadData();
  };

  handleDestroy = async userId => {
    const { organizationId, profileId } = this.state;

    this.setState({ loading: true });

    const response = await api(
      `/access/rule/${organizationId}/${profileId}/${userId}`,
      { method: 'DELETE' }
    );

    this.setState({ loading: false });
    responseHandler(response);

    this.loadData();
  };

  render() {
    const { data, loading } = this.state;

    return (
      <RuleByProfile
        data={data}
        onDestroy={this.handleDestroy}
        onChangeOrganization={this.handleChangeOrganization}
        onChangeProfile={this.handleChangeProfile}
        getLoading={loading}
      />
    );
  }
}

export default RuleByProfilePage;
