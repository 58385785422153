import React from 'react';
import {
  Row, Col, Form, Select, DatePicker
} from 'antd';
import { useIntl } from 'react-intl';

import { useGet } from 'common/api';
import PersonSelect from 'modules/shared/person/components/PersonSelect';

import { SHARED_CURRENCY_LIST } from 'modules/shared/_constants/cacheKeys';
import { DEBT_TYPE_LIST } from 'modules/debt/_constants/cacheKeys';
import debtStatusStatic from 'modules/debt/debt/static/DebtStatusStatic';
import debtRecordTypeStatic from 'modules/debt/debt/static/DebtRecordTypeStatic';

const { RangePicker } = DatePicker;

const DataExportFilters = ({ setState, disabled }) => {
  const intl = useIntl();

  const statusList = Object.entries(debtStatusStatic).map(
    ([value, label]) => ({ value, label: intl.formatMessage({ id: label }, { plural: false }) })
  );

  const recordTypeList = Object.entries(debtRecordTypeStatic).map(
    ([value, label]) => ({ value, label: intl.formatMessage({ id: label }, { plural: false }) })
  );

  const { data: currencyData, isLoading: isLoadingCurrency } = useGet(SHARED_CURRENCY_LIST, '/shared/currency');

  const { data: typeData, isLoading: isLoadingType } = useGet(DEBT_TYPE_LIST, '/debt/type');

  return (
    <Form className="soft-box">
      <div style={{
        backgroundColor: 'blue', position: 'relative', width: '100%', height: '100%'
      }}
      />
      <h5>{intl.formatMessage({ id: 'filter' }, { plural: true })}</h5>
      <Row gutter={16} style={{ marginBottom: 12 }}>
        <Col span={12}>
          <PersonSelect
            style={{ width: '100%' }}
            onSelect={personId => setState({ personId })}
            placeholder={intl.formatMessage({ id: 'creditor' })}
            disabled={disabled}
          />
        </Col>
        <Col span={12}>
          <RangePicker
            picker="month"
            style={{ width: '100%' }}
            onChange={range => {
              setState({ period: [range?.[0].format('YYYY-MM'), range?.[1].format('YYYY-MM')] });
            }}
            format={intl.formatMessage({ id: 'date_format_month' })}
            disabled={disabled}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={6}>
          <Select
            options={statusList}
            style={{ width: '100%' }}
            allowClear
            placeholder={intl.formatMessage({ id: 'status' })}
            onChange={status => setState({ status })}
            onClear={() => setState({ status: null })}
            disabled={disabled}
          />
        </Col>
        <Col span={6}>
          <Select
            options={typeData?.map(v => ({ value: v.id, label: v.name }))}
            style={{ width: '100%' }}
            allowClear
            placeholder={intl.formatMessage({ id: 'type' })}
            onChange={typeId => setState({ typeId })}
            loading={isLoadingType}
            disabled={disabled}
          />
        </Col>
        <Col span={6}>
          <Select
            options={recordTypeList}
            style={{ width: '100%' }}
            allowClear
            placeholder={intl.formatMessage({ id: 'record_type' })}
            onChange={recordType => setState({ recordType })}
            disabled={disabled}
          />
        </Col>
        <Col span={6}>
          <Select
            options={currencyData?.map(v => ({ value: v.id, label: v.name }))}
            style={{ width: '100%' }}
            allowClear
            placeholder={intl.formatMessage({ id: 'currency' })}
            onChange={currencyId => setState({ currencyId })}
            loading={isLoadingCurrency}
            disabled={disabled}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default DataExportFilters;
