import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge, Menu, Dropdown, Popover
} from 'antd';
import {
  LockOutlined,
  LogoutOutlined,
  BellOutlined,
  UserOutlined
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { api } from 'config/api';

import ReportNotification from 'core/components/reportNotification/ReportNotification';
import NotificationMenuListContainer from 'modules/shared/notification/components/NotificationMenuListContainer';

const styles = {
  dropDown: {
    fontSize: '10px',
    border: '1px solid',
    borderRadius: '6px',
    fontWeight: 'bold',
    cursor: 'pointer',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    paddingRight: 6,
    paddingLeft: 6
  }
};
class HeaderOptions extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    countNotification: 0,
    visibleNotification: false
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    organization: '',
    locale: ''
  };

  componentDidMount() {
    this.observerNotification();
    this.reportNotificationComp = <ReportNotification style={{ marginRight: 20, fontSize: 18 }} />;
  }

  observerNotification = () => {
    this.countNotification();
    setTimeout(this.countNotification, 100000); // one minute FIXME: websocket
  };

  countNotification = async () => {
    const body = await api('/shared/notification/count', {
      body: { unread: true }
    });

    this.setState({ countNotification: body });
  };

  renderNotification = () => {
    const { visibleNotification } = this.state;

    if (!visibleNotification) {
      return null;
    }

    return <NotificationMenuListContainer />;
  };

  getEnvTitle = () => {
    const env = process.env.REACT_APP_ENV.toLowerCase();

    if (env === 'production') {
      return null;
    }

    return (
      <span
        style={{
          color: env === 'STAGING' ? '#ff7b00' : '#94ff00',
          fontSize: 22,
          marginRight: 20
        }}
      >
        {env}
      </span>
    );
  };

  render() {
    const {
      user, onChangeOrganization, onChangeLocale, organizationInitials, locale
    } = this.props;
    const { countNotification } = this.state;
    const userName = user.name.match(/^.+?(\s|$)/)[0].trim();

    const menuOrganization = (
      <Menu
        onClick={
          ({ key }) => onChangeOrganization(user.organizationList.find(elem => elem.id === +key))
        }
      >
        {user.organizationList.map(org => (
          <Menu.Item key={org.id}>
            {org.initials}
          </Menu.Item>
        ))}
      </Menu>
    );

    const menuLocale = (
      <Menu onClick={({ key }) => onChangeLocale(key)}>
        <Menu.Item key="pt">Português</Menu.Item>
        <Menu.Item key="en">English</Menu.Item>
      </Menu>
    );

    const menuUser = (
      <Menu>
        <Menu.Item>
          <Link to="/access/change-password">
            <LockOutlined />
            {' '}
            <FormattedMessage id="change_password" />
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/access/login">
            <LogoutOutlined />
            {' '}
            <FormattedMessage id="logout" />
          </Link>
        </Menu.Item>
      </Menu>
    );

    return (
      <div id="header-options">
        {this.getEnvTitle()}

        {this.reportNotificationComp}

        <Popover
          content={this.renderNotification()}
          trigger="click"
          placement="bottomRight"
          title={<FormattedMessage id="notifications" />}
          onVisibleChange={visibleNotification => this.setState({ visibleNotification })}
          overlayClassName="topbar-notification"
        >
          <Badge dot={countNotification > 0}>
            <BellOutlined
              style={{
                marginRight: 20,
                fontSize: 18,
                cursor: 'pointer'
              }}
            />
          </Badge>
        </Popover>

        <Dropdown
          overlay={menuOrganization}
          trigger={['click']}
          placement="bottomRight"
        >
          <div
            style={{
              ...styles.dropDown,
              ...styles.text,
              marginRight: 20,
            }}
          >
            {organizationInitials}
          </div>
        </Dropdown>

        <Dropdown
          overlay={menuLocale}
          trigger={['click']}
          placement="bottomRight"
        >
          <div
            style={{
              ...styles.dropDown,
              marginRight: 20,
              width: 28
            }}
          >
            {locale === 'pt-br' ? 'pt' : locale}
          </div>
        </Dropdown>

        <Dropdown
          overlay={menuUser}
          trigger={['click']}
          placement="bottomRight"
        >
          <div
            style={{
              ...styles.dropDown,
              ...styles.text
            }}
          >
            <UserOutlined style={{ marginRight: 2, fontSize: 12 }} />
            <span style={{ textTransform: 'capitalize' }}>{userName.toLowerCase()}</span>
          </div>
        </Dropdown>
      </div>
    );
  }
}

export default HeaderOptions;
