import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import uploadConfig from 'config/upload';

const { Dragger } = Upload;

const FileUpload = ({ fileList, onChange }) => (
  <Dragger
    {...uploadConfig()}
    name="file"
    multiple
    fileList={fileList}
    onChange={({ fileList: list }) => onChange([...list])}
  >
    <UploadOutlined style={{ fontSize: 60 }} />
    <p style={{ fontSize: 16, marginTop: 10 }}>
      <FormattedMessage id="message.upload_drag" />
    </p>
  </Dragger>
);

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default FileUpload;
