import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Form, Input, DatePicker, Select, Spin
} from 'antd';
import { useIntl } from 'react-intl';
import moment from 'moment';

import { useGet, usePost, usePut } from 'common/api';
import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import UserSelect from 'modules/access/user/components/UserSelect';
import {
  PROJECT_TAG_LIST,
  PROJECT_TASK,
  PROJECT_TASK_LIST
} from 'modules/project/_constants/cacheKeys';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

const TaskForm = () => {
  const { formatMessage } = useIntl();
  const { goBack } = useHistory();

  const { taskId, projectId } = useParams();
  const { id: organizationId } = useOrganization();

  const [form, setForm] = useState({
    id: null,
    projectId,
    name: null,
    description: null,
    dueAt: null,
    tag: [],
    responsibleId: null
  });

  const { data: tagData, isLoading: isLoadingTag } = useGet(
    [PROJECT_TAG_LIST, organizationId, 'tsk'],
    '/project/tag',
    {
      params: {
        organizationId,
        ref: 'tsk'
      },
      query: { enabled: Boolean(organizationId) }
    }
  );

  const { data, isLoading } = useGet([PROJECT_TASK, taskId], '/project/task', {
    params: { id: taskId },
    query: { enabled: Boolean(taskId) }
  });
  useEffect(() => {
    if (data) {
      const tag = data.rows[0].tag.map(v => `${v.id}`);
      setForm({
        ...data.rows[0],
        tag
      });
    }
  }, [data]);

  const options = {
    fetch: { body: form },
    onSuccess: goBack,
    cacheInvalidate: [
      [PROJECT_TASK_LIST, projectId],
      taskId && [PROJECT_TASK, taskId]
    ]
  };
  const { mutate, isLoadingMutate } = usePost(() => '/project/task', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/project/task/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const tagOptions = tagData?.map(v => (
    <Option key={`${v.id}`} value={`${v.id}`}>
      {v.name}
    </Option>
  ));

  return (
    <Spin spinning={isLoadingMutate || isLoadingUpdate || isLoading}>
      <Form className="soft-box">
        <FormItem
          {...defaultFormItemLayout}
          label={formatMessage({ id: 'title' })}
        >
          <Input
            maxLength={120}
            onChange={e => setFormData({ name: e.target.value })}
            value={form.name}
            className="uppercase"
            autoFocus
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={formatMessage({ id: 'description' })}
        >
          <TextArea
            rows="4"
            onChange={e => setFormData({ description: e.target.value })}
            value={form.description}
          />
        </FormItem>

        {!form.id
          && (
            <FormItem
              {...defaultFormItemLayout}
              label={formatMessage({ id: 'responsible' })}
            >
              <UserSelect onSelect={responsibleId => setFormData({ responsibleId })} />
            </FormItem>
          )}

        <FormItem
          {...defaultFormItemLayout}
          label={formatMessage({ id: 'due_date' }, { plural: false })}
        >
          <DatePicker
            format={formatMessage({
              id: 'date_format'
            })}
            onChange={date => setFormData({
              dueAt: date || null
            })}
            value={form.dueAt ? moment(form.dueAt) : null}
            placeholder={formatMessage({
              id: 'date_placeholder'
            })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={formatMessage({ id: 'tag' }, { plural: true })}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            onChange={tag => setFormData({ tag })}
            value={form.tag}
            loading={isLoadingTag}
          >
            {tagOptions}
          </Select>
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!taskId ? mutate() : mutateUpdate(taskId))}
          onCancel={goBack}
        />
      </Form>
    </Spin>
  );
};

export default TaskForm;
