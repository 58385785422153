import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import BreadcrumbFromUrl from 'common/components/BreadcrumbFromUrl';
import { useModuleUpdate } from 'core/_contexts/ModuleContext';

import OrganizationListContainer from './organization/components/OrganizationListContainer';
import OrganizationFormContainer from './organization/components/OrganizationFormContainer';
import UserListContainer from './user/components/UserListContainer';
import UserFormContainer from './user/components/UserFormContainer';
import ResourceListContainer from './resource/components/ResourceListContainer';
import ResourceFormContainer from './resource/components/ResourceFormContainer';
import ProfileListContainer from './profile/components/ProfileListContainer';
import ProfileFormContainer from './profile/components/ProfileFormContainer';
import PermissionFormContainer from './permission/components/PermissionFormContainer';
import RuleFormContainer from './rule/components/RuleFormContainer';
import RuleByOrganizationContainer from './rule/components/RuleByOrganizationContainer';
import RuleByProfileContainer from './rule/components/RuleByProfileContainer';
import UserChangePasswordFormContainer from './user/components/UserChangePasswordFormContainer';

const breadcrumbMap = {
  '/access': {
    title: (
      <span>
        <LockOutlined />
        <FormattedMessage id="access_control" />
      </span>
    ),
    noLink: true
  },
  '/access/user': {
    title: <FormattedMessage id="user" values={{ plural: false }} />
  },
  '/access/user/add': {
    title: <FormattedMessage id="register" />
  },
  '/access/organization': {
    title: (
      <FormattedMessage id="work_organization" values={{ plural: false }} />
    )
  },
  '/access/organization/add': {
    title: <FormattedMessage id="register" />
  },
  '/access/organization/edit/!': {
    title: <FormattedMessage id="change" />
  },
  '/access/resource': {
    title: <FormattedMessage id="resource" values={{ plural: false }} />
  },
  '/access/resource/add': {
    title: <FormattedMessage id="register" />
  },
  '/access/resource/edit/!': {
    title: <FormattedMessage id="change" />
  },
  '/access/profile': {
    title: <FormattedMessage id="profile" values={{ plural: false }} />
  },
  '/access/profile/add': {
    title: <FormattedMessage id="register" />
  },
  '/access/profile/edit/!': {
    title: <FormattedMessage id="change" />
  },
  '/access/permission': {
    title: <FormattedMessage id="permission" values={{ plural: true }} />
  },
  '/access/rule': {
    title: <FormattedMessage id="rule" values={{ plural: false }} />,
    noLink: true
  },
  '/access/rule/users-by-organization': {
    title: <FormattedMessage id="users_by_organization" />
  },
  '/access/rule/users-by-profile': {
    title: <FormattedMessage id="users_by_profile" />
  },
  '/access/change-password': {
    title: <FormattedMessage id="change_password" />
  }
};

const AccessRoutes = () => {
  const { url } = useRouteMatch();
  const moduleUpdate = useModuleUpdate();

  useEffect(() => {
    moduleUpdate('access');
  }, []);

  return (
    <>
      <BreadcrumbFromUrl
        className=""
        breadcrumbMap={breadcrumbMap}
        withBaseUrl
      />

      <div style={{ padding: 20 }}>
        <Route
          path={`${url}/user`}
          component={UserListContainer}
          exact
        />
        <Route
          path={`${url}/user/add`}
          component={UserFormContainer}
        />
        <Route
          path={`${url}/user/edit/:id?`}
          component={UserFormContainer}
        />
        <Route
          path={`${url}/organization`}
          component={OrganizationListContainer}
          exact
        />
        <Route
          path={`${url}/organization/add`}
          component={OrganizationFormContainer}
        />
        <Route
          path={`${url}/organization/edit/:id?`}
          component={OrganizationFormContainer}
        />

        <Route
          path={`${url}/resource`}
          component={ResourceListContainer}
          exact
        />
        <Route
          path={`${url}/resource/add`}
          component={ResourceFormContainer}
        />
        <Route
          path={`${url}/resource/edit/:id?`}
          component={ResourceFormContainer}
        />
        <Route
          path={`${url}/profile`}
          component={ProfileListContainer}
          exact
        />
        <Route
          path={`${url}/profile/add`}
          component={ProfileFormContainer}
        />
        <Route
          path={`${url}/profile/edit/:id?`}
          component={ProfileFormContainer}
        />
        <Route
          path={`${url}/permission`}
          component={PermissionFormContainer}
        />
        <Route
          path={`${url}/rule`}
          component={RuleFormContainer}
          exact
        />
        <Route
          path={`${url}/rule/users-by-organization`}
          component={RuleByOrganizationContainer}
        />
        <Route
          path={`${url}/rule/users-by-profile`}
          component={RuleByProfileContainer}
        />
        <Route
          path={`${url}/change-password`}
          component={UserChangePasswordFormContainer}
        />
      </div>
    </>
  );
};

export default AccessRoutes;
