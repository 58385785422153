import React from 'react';
import PropTypes from 'prop-types';
import {
  Spin, Row, Col, Button, Tree
} from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import SelectProfile from '../../profile/microComponents/SelectProfile';

const PermissionForm = props => {
  const {
    onChangeProfile,
    getLoading,
    onCheckResource,
    getStateForm,
    getDataResource,
    onStore,
    getLoadingStore
  } = props;

  return (
    <div>
      <Row gutter={16}>
        <Col xs={24} sm={16} md={10} lg={8}>
          <SelectProfile
            onChange={value => onChangeProfile(value)}
            placeholder={<FormattedMessage id="select_profile" />}
          />
        </Col>
      </Row>

      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Spin spinning={getLoading}>
            <div
              style={{
                padding: 5,
                backgroundColor: '#fff',
                borderRadius: 5,
                margin: '5px 0'
              }}
            >
              <Tree
                onCheck={onCheckResource}
                checkedKeys={getStateForm.resourceList}
                checkable
                treeData={getDataResource}
              />
            </div>
          </Spin>
        </Col>
      </Row>

      <Button
        onClick={onStore}
        type="primary"
        icon={<SaveOutlined />}
        disabled={!getStateForm.profileId}
        loading={getLoadingStore}
      >
        <FormattedMessage id="save" />
      </Button>

    </div>
  );
};

PermissionForm.propTypes = {
  onStore: PropTypes.func.isRequired,
  onCheckResource: PropTypes.func.isRequired,
  onChangeProfile: PropTypes.func.isRequired,
  getStateForm: PropTypes.shape({}).isRequired,
  getDataResource: PropTypes.shape([]).isRequired,
  getLoading: PropTypes.bool.isRequired,
  getLoadingStore: PropTypes.bool.isRequired
};

export default PermissionForm;
