import React from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Select, DatePicker, Radio
} from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

const FormItem = Form.Item;
const { Option } = Select;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const PersonForm = props => {
  const {
    getStateForm,
    setStateForm,
    intl,
    dataEntityType,
    dataAdmSphere
  } = props;

  const renderPersonType = () => {
    if (getStateForm.type === 'p') {
      return (
        <span>
          <FormItem
            {...defaultFormItemLayout}
            label={<FormattedMessage id="gender" />}
          >
            <Select
              size="default"
              defaultValue={`${
                getStateForm.physicalPerson.gender
                  ? getStateForm.physicalPerson.gender
                  : ''
              }`}
              onChange={value => setStateForm({
                physicalPerson: {
                  ...getStateForm.physicalPerson,
                  gender: value
                }
              })}
              style={{ display: 'inline-block' }}
            >
              <Option key="f" value="f">
                <FormattedMessage id="female" />
              </Option>
              <Option key="m" value="m">
                <FormattedMessage id="male" />
              </Option>
            </Select>
          </FormItem>

          <FormItem
            {...defaultFormItemLayout}
            label={<FormattedMessage id="birth" />}
          >
            <DatePicker
              format={intl.formatMessage({
                id: 'date_format'
              })}
              onChange={date => setStateForm({
                physicalPerson: {
                  ...getStateForm.physicalPerson,
                  bornOn: date || null
                }
              })}
              value={
                getStateForm.physicalPerson.bornOn
                  ? moment(getStateForm.physicalPerson.bornOn)
                  : null
              }
              placeholder={intl.formatMessage({
                id: 'date_placeholder'
              })}
            />
          </FormItem>
        </span>
      );
    }

    if (getStateForm.type === 'l') {
      const optionsEntityType = dataEntityType.map(d => (
        <Option key={d.id.toString()} value={d.id.toString()}>
          {d.name}
        </Option>
      ));
      const optionsAdmSphere = dataAdmSphere.map(d => (
        <Option key={d.id.toString()} value={d.id.toString()}>
          {d.name}
        </Option>
      ));

      return (
        <span>
          <FormItem
            {...defaultFormItemLayout}
            label={<FormattedMessage id="fantasy_name" />}
          >
            <Input
              size="default"
              onChange={e => setStateForm({
                legalPerson: {
                  ...getStateForm.legalPerson,
                  tradingName: e.target.value
                }
              })}
              value={getStateForm.legalPerson.tradingName}
              className="uppercase"
              maxLength={120}
            />
          </FormItem>

          <FormItem
            {...defaultFormItemLayout}
            label={<FormattedMessage id="initials" />}
          >
            <Input
              size="default"
              onChange={e => setStateForm({
                legalPerson: {
                  ...getStateForm.legalPerson,
                  initials: e.target.value
                }
              })}
              value={getStateForm.legalPerson.initials}
              className="uppercase"
              maxLength={10}
            />
          </FormItem>

          <FormItem
            {...defaultFormItemLayout}
            label={<FormattedMessage id="entity_type" />}
          >
            <Select
              size="default"
              defaultValue={`${
                getStateForm.legalPerson.entityTypeId
                  ? getStateForm.legalPerson.entityTypeId
                  : ''
              }`}
              onChange={value => setStateForm({
                legalPerson: {
                  ...getStateForm.legalPerson,
                  entityTypeId: value
                }
              })}
              style={{ display: 'inline-block' }}
            >
              {optionsEntityType}
            </Select>
          </FormItem>

          <FormItem
            {...defaultFormItemLayout}
            label={<FormattedMessage id="administrative_sphere" />}
          >
            <Select
              size="default"
              defaultValue={`${
                getStateForm.legalPerson.admSphereId
                  ? getStateForm.legalPerson.admSphereId
                  : ''
              }`}
              onChange={value => setStateForm({
                legalPerson: {
                  ...getStateForm.legalPerson,
                  admSphereId: value
                }
              })}
              style={{ display: 'inline-block' }}
            >
              {optionsAdmSphere}
            </Select>
          </FormItem>
        </span>
      );
    }

    return null;
  };

  const { onStore, onCancel, loading } = props;

  return (
    <Form className="soft-box">
      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="type" />}
      >
        <RadioGroup
          onChange={e => setStateForm({ type: e.target.value })}
          value={getStateForm.type}
          disabled={getStateForm.id !== null}
        >
          <RadioButton value="p">
            <FormattedMessage id="physical_person" />
          </RadioButton>
          <RadioButton value="l">
            <FormattedMessage id="legal_person" />
          </RadioButton>
        </RadioGroup>
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="person_doc" />}
      >
        <Input
          size="default"
          onChange={e => setStateForm({
            docNumber: e.target.value
          })}
          value={getStateForm.docNumber}
          maxLength={14}
          disabled={getStateForm.id !== null}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="name" />}
      >
        <Input
          size="default"
          onChange={e => setStateForm({
            name: e.target.value
          })}
          value={getStateForm.name}
          className="uppercase"
          maxLength={120}
        />
      </FormItem>

      {renderPersonType()}

      <FormSubmitButtons
        onStore={onStore}
        onCancel={onCancel}
        loading={loading}
        hideCancel
      />
    </Form>
  );
};

PersonForm.propTypes = {
  onStore: PropTypes.func.isRequired,
  setStateForm: PropTypes.func.isRequired,
  getStateForm: PropTypes.shape({}).isRequired,
  dataEntityType: PropTypes.shape([]).isRequired,
  dataAdmSphere: PropTypes.shape([]).isRequired,
  loading: PropTypes.bool.isRequired
};

export default injectIntl(PersonForm);
