import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Tooltip, Button, Menu, Dropdown, Table, Modal
} from 'antd';
import { MoreOutlined, PlusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

import ContentWithLeftButtons from 'common/components/ContentWithLeftButtons';
import { dateToLocale } from 'common/utils/misc';
import { useGet, useDelete } from 'common/api';

import { useDebt } from 'modules/debt/_contexts/DebtContext';
import {
  DEBT_OPERATION_LIST,
  DEBT_DEBT_DASHBOARD,
  DEBT_COMPOSITION_LIST,
  DEBT_INSTALLMENT_LIST
} from 'modules/debt/_constants/cacheKeys';
import IncludeStatic from '../static/IncludeStatic';

const { confirm } = Modal;

const OperationList = () => {
  const { formatMessage, formatNumber } = useIntl();
  const { push } = useHistory();
  const { debtId } = useParams();
  const debt = useDebt();

  const cacheKey = [DEBT_OPERATION_LIST, debtId];

  const { data, isLoading } = useGet(cacheKey, '/debt/operation', {
    params: { debtId }
  });

  const { mutate } = useDelete(id => `/debt/operation/${id}`, {
    cacheInvalidate: [
      cacheKey,
      [DEBT_DEBT_DASHBOARD, debtId],
      [DEBT_COMPOSITION_LIST, debtId],
      [DEBT_INSTALLMENT_LIST, debtId]
    ]
  });

  const menuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`operation/${id}/edit`}>
              {formatMessage({ id: 'edit' })}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: formatMessage({ id: 'message.inactivate_item' }),
                onOk: () => mutate(id)
              });
            }}
          >
            {formatMessage({ id: 'delete' })}
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderAmountColumn = d => {
    const {
      value,
      valueExecuted,
      currency
    } = d;

    const getExecutedAmount = () => {
      if (currency.id === debt.currencyId) { return null; }

      return (
        <>
          <br />
          {
            formatNumber(valueExecuted, {
              maximumFractionDigits: 2,
              style: 'currency',
              currency: currency?.symbol
            })
          }
        </>
      );
    };

    return (
      <>
        {
          formatNumber(value, {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: debt?.currency?.symbol
          })
        }
        {getExecutedAmount()}
      </>
    );
  };

  const renderTableColumns = [
    {
      title: formatMessage({ id: 'execution' }),
      render: ({ executedOn, realized }) => (
        <>
          {dateToLocale(executedOn, 'L', true)}
          {realized && (
            <Tooltip title={formatMessage({ id: 'realized' })}>
              <CheckCircleOutlined style={{ marginLeft: 4 }} />
            </Tooltip>
          )}
        </>
      ),
      key: '1',
      width: '16%'
    },
    {
      title: formatMessage({ id: 'amount' }),
      render: renderAmountColumn,
      key: '2',
      width: '24%',
      align: 'right'
    },
    {
      title: formatMessage({ id: 'period' }),
      render: ({ include }) => formatMessage({ id: IncludeStatic.list[include] }),
      width: '20%',
      key: '3'
    },
    {
      title: formatMessage({ id: 'description' }),
      dataIndex: 'description',
      key: '3'
    },
    {
      render: ({ id }) => menuActions(id),
      key: '4',
      width: '3%',
      align: 'right'
    }
  ];

  const renderButtons = () => (
    <Tooltip title={formatMessage({ id: 'add' })}>
      <Button
        onClick={() => push('operation/add')}
        icon={<PlusOutlined />}
      />
    </Tooltip>
  );

  const renderFooter = operations => {
    const total = formatNumber(operations.reduce((acc, o) => acc + o.value, 0), {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: debt?.currency?.symbol
    });

    return `Total: ${total}`;
  };

  return (
    <ContentWithLeftButtons buttons={renderButtons()}>
      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns}
        size="middle"
        loading={isLoading}
        pagination={false}
        footer={renderFooter}
      />
    </ContentWithLeftButtons>
  );
};

export default OperationList;
