import React, { createContext, useContext, useState } from 'react';

import { useGet } from 'common/api';

import { PROJECT_PROJECT } from 'modules/project/_constants/cacheKeys';

const ProjectContext = createContext();
const ProjectUpdateContext = createContext();

export const useProject = () => useContext(ProjectContext);
export const useProjectUpdate = () => useContext(ProjectUpdateContext);

export const ProjectProvider = ({ children }) => {
  const [id, setId] = useState();

  const cacheKey = [PROJECT_PROJECT, id];
  const { data } = useGet(cacheKey, '/project/project', {
    params: { id },
    query: { enabled: Boolean(id) }
  });

  return (
    <ProjectContext.Provider value={data?.rows?.[0] || {}}>
      <ProjectUpdateContext.Provider value={setId}>
        { children }
      </ProjectUpdateContext.Provider>
    </ProjectContext.Provider>
  );
};
