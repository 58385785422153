import React from 'react';
import {
  FileWordOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  FileJpgOutlined,
  FileTextOutlined
} from '@ant-design/icons';

const FileIcon = props => {
  const {
    style, color, size, extension
  } = props;
  let iconType = {};

  switch (extension.toLowerCase()) {
    case '.doc':
    case '.docx':
      iconType = { icon: FileWordOutlined, color: '#0041c4' };
      break;

    case '.pdf':
      iconType = { icon: FilePdfOutlined, color: '#e50000' };
      break;

    case '.xls':
    case '.xlsx':
      iconType = { icon: FileExcelOutlined, color: '#18b700' };
      break;

    case '.jpg':
    case '.jpeg':
    case '.png':
    case '.gif':
      iconType = { icon: FileJpgOutlined, color: '#40A9FF' };
      break;

    default:
      iconType = { icon: FileTextOutlined, color: '#rgba()' };
  }

  return (
    <iconType.icon
      {...props}
      style={{
        ...style,
        color: color || iconType.color,
        fontSize: size
      }}
    />
  );
};

FileIcon.defaultProps = {
  size: 14
};

export default FileIcon;
