import React, { createContext, useState, useContext } from 'react';

const DebtYearContext = createContext();
const DebtYearUpdateContext = createContext();

export const useDebtYear = () => useContext(DebtYearContext);
export const useDebtYearUpdate = () => useContext(DebtYearUpdateContext);

export const DebtYearProvider = ({ children }) => {
  const [data, setData] = useState(new Date().getFullYear());

  return (
    <DebtYearContext.Provider value={`${data}`}>
      <DebtYearUpdateContext.Provider value={setData}>
        { children }
      </DebtYearUpdateContext.Provider>
    </DebtYearContext.Provider>
  );
};
