import React from 'react';

import './styles.css';

const CardIcon = ({
  title, children, icon
}) => (
  <div className="card-icon">
    <div className="icon">
      {icon}
    </div>
    <div style={{ width: '100%' }}>
      <small>
        {title}
      </small>
      <div className="content">
        {children}
      </div>
    </div>
  </div>
);

export default CardIcon;
