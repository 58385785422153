import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, withRouter } from 'react-router-dom';

const breadcrumbItems = ({ match, location }, breadcrumbMap, withBaseUrl) => {
  let { pathname } = location;

  // Remove baseurl from url
  if (!withBaseUrl) {
    pathname = location.pathname.replace(match.url, '');
  }

  const originalPathSnippets = pathname.split('/').filter(i => i);

  // Change integer values to "!"
  const pathSnippets = originalPathSnippets.map(v => (parseInt(v, 10) ? '!' : v));

  let items = pathSnippets.map((_, index) => {
    let link = `/${originalPathSnippets.slice(0, index + 1).join('/')}`;
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

    if (!withBaseUrl) {
      link = `${match.url}${link}`;
    }

    if (breadcrumbMap[url]) {
      // If not the last, return with link
      if (pathSnippets.length - 1 > index && !breadcrumbMap[url].noLink) {
        return (
          <Breadcrumb.Item key={url}>
            <Link to={link}>{breadcrumbMap[url].title}</Link>
          </Breadcrumb.Item>
        );
      }
      return (
        <Breadcrumb.Item key={url}>{breadcrumbMap[url].title}</Breadcrumb.Item>
      );
    }

    return null;
  });

  items = items.filter(v => v !== null);

  if (items.length > 0) {
    return items;
  }

  return null;
};

const BreadcrumbFromUrl = props => {
  const { breadcrumbMap, withBaseUrl, className } = props;
  const items = breadcrumbItems(props, breadcrumbMap, withBaseUrl);

  return <Breadcrumb className={className}>{items}</Breadcrumb>;
};

BreadcrumbFromUrl.defaultProps = {
  className: 'inner',
  withBaseUrl: false
};

export default withRouter(BreadcrumbFromUrl);
