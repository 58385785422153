import React from 'react';
import { useParams } from 'react-router-dom';
import { Progress } from 'antd';
import { useIntl } from 'react-intl';

import { useGet } from 'common/api';

import { useDebt } from 'modules/debt/_contexts/DebtContext';
import { DEBT_DEBT_DASHBOARD } from 'modules/debt/_constants/cacheKeys';
import compositionTypeStatic from 'modules/debt/composition/static/CompositionTypeStatic';

const TotalsByCompositionType = () => {
  const { formatMessage, formatNumber } = useIntl();
  const { debtId } = useParams();
  const debt = useDebt();
  const symbol = debt?.currency?.symbol;

  const { data } = useGet([DEBT_DEBT_DASHBOARD, debtId, 'totalsByCompositionType'], '/debt/debt-dashboard/totals-by-composition-type', {
    params: { id: debtId }
  });

  const totalGlobal = data?.reduce((acc, { total }) => acc + total, 0);

  const formatCurrency = value => {
    if (!symbol) {
      return formatNumber(value || 0, { maximumFractionDigits: 0 });
    }

    return (
      formatNumber(value || 0, {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: symbol || null
      })
    );
  };

  const renderType = ({ type, total }) => (
    <div key={type} className="item">
      <div className="type">
        <div>{formatMessage({ id: compositionTypeStatic.list[type] }, { plural: false })}</div>
        <div className="amount">{formatCurrency(total)}</div>
      </div>
      <Progress percent={Math.round((total / totalGlobal) * 100)} size="small" />
    </div>
  );

  return (
    <div className="totals-composition">
      {data?.map(renderType)}
    </div>
  );
};

export default TotalsByCompositionType;
