import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Form, Input, Select, Switch, Tooltip, Spin
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import AceEditor from 'react-ace';
import 'brace/ext/language_tools';
import 'brace/mode/javascript';
import 'brace/theme/tomorrow';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import { useGet, usePost, usePut } from 'common/api';

import { useOrganization } from 'core/_contexts/OrganizationContext';

import FormSubmitButtons from 'common/components/FormSubmitButtons';
import { SHARED_INDEX_LIST } from 'modules/shared/_constants/cacheKeys';
import { DEBT_CALCULATION, DEBT_CALCULATION_LIST } from 'modules/debt/_constants/cacheKeys';
import CalculationTypeStatic from '../static/CalculationTypeStatic';
import CalculationScopeStatic from '../static/CalculationScopeStatic';
import CalculationExecutionStatic from '../static/CalculationExecutionStatic';
import CalculationApplyInStatic from '../static/CalculationApplyInStatic';
import CalculationFormHelpVariables from './CalculationFormHelpVariables';
import CalculationFormHelpFunctions from './CalculationFormHelpFunctions';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

const CalculationForm = props => {
  const { shared } = props;

  const history = useHistory();
  const intl = useIntl();
  const { debtId, calculationId } = useParams();
  const { id: organizationId } = useOrganization();
  const isEdit = Boolean(calculationId);

  const [form, setForm] = useState({
    id: null,
    organizationId,
    debtId,
    shared,
    name: null,
    description: null,
    groupName: null,
    scope: null,
    type: null,
    execution: null,
    applyIn: null,
    code: '$valor = 0;',
    amortize: false,
    checkOnly: false,
    indexList: []
  });

  const { data: indexData, isLoading: isLoadingIndex } = useGet(SHARED_INDEX_LIST, '/shared/index');

  const cacheKey = [DEBT_CALCULATION, calculationId];

  const { data, isLoading: isLoadingData } = useGet(cacheKey, '/debt/calculation', {
    params: { id: calculationId },
    query: { enabled: Boolean(calculationId) }
  });
  useEffect(() => {
    if (data) {
      const indexList = data[0].index.map(v => v.id);
      setForm({ ...data[0], indexList });
    }
  }, [data]);

  const options = {
    fetch: { body: form },
    onSuccess: history.goBack,
    cacheInvalidate: [
      [DEBT_CALCULATION_LIST, organizationId],
      calculationId ? [DEBT_CALCULATION, calculationId] : null
    ]
  };
  const { mutate, isLoading } = usePost(() => '/debt/calculation', options);
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = usePut(id => `/debt/calculation/${id}`, options);

  const setFormData = value => setForm({ ...form, ...value });

  const typeOptions = Object.entries(CalculationTypeStatic).map(
    ([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} values={{ plural: false }} />
      </Option>
    )
  );

  const scopeOptions = Object.entries(CalculationScopeStatic).map(
    ([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} values={{ plural: false }} />
      </Option>
    )
  );

  const executionOptions = Object.entries(CalculationExecutionStatic).map(
    ([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} values={{ plural: false }} />
      </Option>
    )
  );

  const applyInOptions = Object.entries(CalculationApplyInStatic).map(
    ([key, value]) => (
      <Option key={key} value={`${key}`}>
        <FormattedMessage id={value} values={{ plural: false }} />
      </Option>
    )
  );

  const indexOptions = indexData?.map(v => (
    <Option key={`${v.id}`} value={v.id}>
      {v.alias}
    </Option>
  ));

  const renderSharedSwitch = () => {
    if (shared) {
      return (
        <FormItem
          {...defaultFormItemLayout}
          label={(
            <>
              <Tooltip title="Todas as dívidas que utilizam este cálculo, serão afetadas pelas alteração ou inativação deste">
                <QuestionCircleOutlined style={{ marginRight: 4 }} />
              </Tooltip>
              {intl.formatMessage({ id: 'shared' })}
            </>
          )}
        >
          <Switch
            checked
            disabled
          />
        </FormItem>
      );
    }

    return null;
  };

  return (
    <Spin spinning={isLoading || isLoadingUpdate || isLoadingData}>
      <Form className="soft-box">
        {renderSharedSwitch()}

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'name' })}
        >
          <Input
            maxLength={80}
            onChange={e => setFormData({ name: e.target.value })}
            value={form.name}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'description' })}
        >
          <TextArea
            rows="2"
            onChange={e => setFormData({ description: e.target.value })}
            value={form.description}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'grouper' })}
        >
          <Input
            maxLength={80}
            onChange={e => setFormData({ groupName: e.target.value })}
            value={form.groupName}
            className="uppercase"
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'type' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={type => setFormData({ type })}
            value={form.type ? `${form.type}` : ''}
          >
            {typeOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={(
            <span>
              <Tooltip title="O resultado fará parte da composição da parcela, ou apenas afetará no saldo devedor?">
                <QuestionCircleOutlined style={{ marginRight: 4 }} />
              </Tooltip>
              {intl.formatMessage({ id: 'scope' })}
            </span>
          )}
        >
          <Select
            style={{ width: '100%' }}
            onChange={scope => setFormData({ scope })}
            value={form.scope ? `${form.scope}` : ''}
          >
            {scopeOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={(
            <span>
              <Tooltip title="Deverá ser calculado um valor para cada dia do período, ou apenas um cálculo para todo o período?">
                <QuestionCircleOutlined style={{ marginRight: 4 }} />
              </Tooltip>
              {intl.formatMessage({ id: 'execution' })}
            </span>
          )}
        >
          <Select
            style={{ width: '100%' }}
            onChange={execution => setFormData({ execution })}
            value={form.execution ? `${form.execution}` : ''}
            disabled={isEdit}
          >
            {executionOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'apply_in' })}
        >
          <Select
            style={{ width: '100%' }}
            onChange={applyIn => setFormData({ applyIn })}
            value={form.applyIn ? `${form.applyIn}` : ''}
            disabled={isEdit}
          >
            {applyInOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'index' }, { plural: true })}
        >
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            onChange={indexList => setFormData({ indexList })}
            value={form.indexList}
            loading={isLoadingIndex}
          >
            {indexOptions}
          </Select>
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={`${intl.formatMessage({
            id: 'amortize'
          })}`}
        >
          <Switch
            checked={form.amortize}
            onChange={amortize => setFormData({ amortize })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={(
            <span>
              <Tooltip title="O resultado do cálculo será mostrado, mas não fará parte do somatório total da parcela?">
                <QuestionCircleOutlined style={{ marginRight: 4 }} />
              </Tooltip>
              {intl.formatMessage({ id: 'check_only' })}
            </span>
          )}
        >
          <Switch
            checked={form.checkOnly}
            onChange={checkOnly => setFormData({ checkOnly })}
          />
        </FormItem>

        <FormItem
          {...defaultFormItemLayout}
          label={intl.formatMessage({ id: 'code' })}
        >
          <AceEditor
            mode="javascript"
            theme="tomorrow"
            width="100%"
            height="360px"
            fontSize={14}
            onChange={code => setFormData({ code })}
            value={form.code}
            showPrintMargin={false}
            tabSize={2}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true
            }}
          />
          <i>
            *
            <FormattedMessage id="use_ecmascript" />
          </i>

          <CalculationFormHelpVariables />
          <CalculationFormHelpFunctions />
        </FormItem>

        <FormSubmitButtons
          onStore={() => (!calculationId ? mutate() : mutateUpdate(calculationId))}
          onCancel={history.goBack}
        />
      </Form>
    </Spin>
  );
};

export default CalculationForm;
