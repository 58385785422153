import moment from 'moment';

export const getWindowHeight = offset => {
  const h = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  return h - (offset || 0);
};

export const clearPunctuation = value => {
  if (value) {
    return value.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '');
  }

  return value;
};

export const clearEmptyChildren = content => {
  const data = content;

  if (data.children) {
    if (data.children.length === 0) {
      delete data.children;
    } else {
      for (let i = 0, count = data.children.length; i < count; i++) {
        clearEmptyChildren(data.children[i]);
      }
    }
  }

  return data;
};

export const changeKeyName = (content, oldName, newName) => {
  const data = content;
  data[newName] = data[oldName];
  delete data[oldName];

  if (data.children) {
    for (let i = 0, count = data.children.length; i < count; i++) {
      changeKeyName(data.children[i], oldName, newName);
    }
  }

  return data;
};

export const dateLocaleToISO = (content, format = 'L') => {
  const date = moment(content, format);

  if (date.isValid()) {
    return date.toISOString();
  }

  return null;
};

export const dateToLocale = (date, format = 'L', utc = false) => {
  if (moment(date).isValid()) {
    if (!utc) {
      return moment(date).format(format);
    }

    return moment.utc(date).format(format);
  }

  return null;
};

export const decimalToDb = (value, precision = 2) => {
  let date = value;

  if (date) {
    date = `${date}`;

    return `${date
      .slice(0, (precision + 1) * -1)
      .replace(/\.|,/, '')}.${date.slice(precision * -1)}`;
  }

  return null;
};
