import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { api } from 'config/api';

const { Option } = Select;

class SelectProfile extends Component {
  state = {
    data: []
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const body = await api('/access/profile');

    this.setState({ data: body });
  };

  render() {
    const { data } = this.state;
    const { onChange, placeholder } = this.props;

    const options = data.map(v => (
      <Option key={`${v.id}`} value={`${v.id}`}>
        {v.name}
      </Option>
    ));

    return (
      <Select
        onChange={id => onChange(id)}
        size="default"
        style={{ width: '100%' }}
        placeholder={placeholder}
      >
        {options}
      </Select>
    );
  }
}

SelectProfile.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.shape({}).isRequired
};

export default SelectProfile;
