import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Table,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Select,
  Modal
} from 'antd';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

const { Option } = Select;
const { confirm } = Modal;

const OrganizationList = props => {
  const {
    getInactive, intl, onDestroy, onRestore, onGoToForm, onChangeSelectStatus, data, getLoading
  } = props;

  const menuActions = id => {
    if (!getInactive) {
      return (
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu>
              <Menu.Item key="0">
                <Link to={`/access/organization/edit/${id}`}>
                  <FormattedMessage id="edit" />
                </Link>
              </Menu.Item>

              <Menu.Item
                key="1"
                onClick={() => {
                  confirm({
                    title: intl.formatMessage({ id: 'message.inactivate_item' }),
                    onOk: () => onDestroy(id)
                  });
                }}
              >
                <FormattedMessage id="inactivate" />
              </Menu.Item>
            </Menu>
          )}
        >
          <MoreOutlined className="more-options" />
        </Dropdown>
      );
    }
    return (
      <Dropdown
        trigger={['click']}
        overlay={(
          <Menu>
            <Menu.Item
              key="1"
              onClick={() => {
                confirm({
                  title: intl.formatMessage({ id: 'message.reactivate_item' }),
                  onOk: () => onRestore(id)
                });
              }}
            >
              <FormattedMessage id="reactivate" />
            </Menu.Item>
          </Menu>
        )}
      >
        <MoreOutlined className="more-options" />
      </Dropdown>
    );
  };

  const renderTableColumns = () => [
    {
      key: 'name',
      render: (text, record) => (
          `${record.number}. ${record.name}${
          record.initials ? ` - ${record.initials}` : ''}`
      ),
      width: '97%'
    },
    {
      key: 'actions',
      width: '3%',
      align: 'right',
      render: (text, { id }) => menuActions(id)
    }
  ];

  return (
    <div>
      <Tooltip title={intl.formatMessage({ id: 'add' })}>
        <Button
          onClick={onGoToForm}
          icon={<PlusOutlined />}
          style={{ paddingTop: '1px' }}
        />
      </Tooltip>

      <Select
        defaultValue="false"
        style={{ marginLeft: '6px' }}
        onChange={value => {
          onChangeSelectStatus(value);
        }}
      >
        <Option value="false">
          <FormattedMessage id="actives" />
        </Option>
        <Option value="true">
          <FormattedMessage id="inactive" />
        </Option>
      </Select>

      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        size="middle"
        pagination={false}
        showHeader={false}
        loading={getLoading}
        style={{ marginTop: '5px' }}
      />
    </div>
  );
};

OrganizationList.propTypes = {
  data: PropTypes.shape([]).isRequired,
  onChangeSelectStatus: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  onGoToForm: PropTypes.func.isRequired,
  getInactive: PropTypes.bool.isRequired,
  getLoading: PropTypes.bool.isRequired
};

export default injectIntl(OrganizationList);
