import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './App';

import 'core/css/antd.css';
import 'core/css/index.css';

const appEnv = process.env.REACT_APP_ENV;

Sentry.init(appEnv !== 'development' ? {
  dsn: 'https://2489072c1448400dbeb8920094550ef2@o397932.ingest.sentry.io/5252972',
  environment: appEnv
} : {});

ReactDOM.render(<App />, document.getElementById('root'));
