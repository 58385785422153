import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FormattedMessage, injectIntl } from 'react-intl';

import { defaultFormItemLayout } from 'common/layout/formItemGrid';
import FormSubmitButtons from 'common/components/FormSubmitButtons';

const FormItem = Form.Item;
const { Search } = Input;

const AddressForm = props => {
  const {
    intl, onLoadZipCode, loadingZipCode, onStore, onCancel, loading, getStateForm, setStateForm
  } = props;

  return (
    <Form className="soft-box">
      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="title" />}
      >
        <Input
          autoFocus
          onChange={e => setStateForm({
            ...getStateForm,
            title: e.target.value
          })}
          value={getStateForm.title}
          maxLength={40}
          className="uppercase"
          placeholder={intl.formatMessage({
            id: 'message.address_title'
          })}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="zip_code" />}
      >
        <Search
          enterButton
          onSearch={onLoadZipCode}
          onChange={e => setStateForm({
            ...getStateForm,
            zipCode: e.target.value
          })}
          value={getStateForm.zipCode}
          style={{ maxWidth: 150 }}
          maxLength={9}
          suffix={loadingZipCode ? <LoadingOutlined /> : ''}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="address" values={{ plural: false }} />}
      >
        <Input
          onChange={e => setStateForm({
            ...getStateForm,
            address: e.target.value
          })}
          value={getStateForm.address}
          maxLength={200}
          className="uppercase"
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="number" />}
      >
        <Input
          onChange={e => setStateForm({
            ...getStateForm,
            number: e.target.value
          })}
          style={{ maxWidth: 150 }}
          value={getStateForm.number}
          maxLength={10}
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="complement" />}
      >
        <Input
          onChange={e => setStateForm({
            ...getStateForm,
            complement: e.target.value
          })}
          value={getStateForm.complement}
          maxLength={200}
          className="uppercase"
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="district" />}
      >
        <Input
          onChange={e => setStateForm({
            ...getStateForm,
            district: e.target.value
          })}
          value={getStateForm.district}
          maxLength={60}
          className="uppercase"
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="city" />}
      >
        <Input
          onChange={e => setStateForm({
            ...getStateForm,
            city: e.target.value
          })}
          value={getStateForm.city}
          maxLength={80}
          className="uppercase"
        />
      </FormItem>

      <FormItem
        {...defaultFormItemLayout}
        label={<FormattedMessage id="federated_unit" />}
      >
        <Input
          onChange={e => setStateForm({
            ...getStateForm,
            state: e.target.value
          })}
          style={{ maxWidth: 150 }}
          value={getStateForm.state}
          maxLength={2}
          className="uppercase"
        />
      </FormItem>

      <FormSubmitButtons
        onStore={onStore}
        onCancel={onCancel}
        loading={loading}
      />
    </Form>
  );
};

AddressForm.propTypes = {
  onStore: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onLoadZipCode: PropTypes.func.isRequired,
  setStateForm: PropTypes.func.isRequired,
  getStateForm: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired,
  loadingZipCode: PropTypes.bool.isRequired
};

export default injectIntl(AddressForm);
