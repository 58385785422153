import React, { Component } from 'react';
import { notification } from 'antd';
import { injectIntl } from 'react-intl';
import { api } from 'config/api';

import PersonList from './PersonList';

class PersonListContainer extends Component {
  state = {
    searchContent: '',
    data: [],
    loading: false,
    found: false
  };

  handleLoadData = async () => {
    const { searchContent } = this.state;
    const { intl } = this.props;

    if (searchContent.length < 3) {
      notification.error({
        message: intl.formatMessage({
          id: 'message.autocomplete'
        })
      });
      return;
    }

    this.setState({ loading: true, found: true });

    const body = await api('/shared/person', {
      body: { search: searchContent }
    });

    this.setState({
      data: body,
      loading: false
    });
  };

  render() {
    const {
      data, searchContent, found, loading
    } = this.state;
    const { history } = this.props;

    return (
      <PersonList
        data={data}
        onSearch={this.handleLoadData}
        onGoToForm={() => history.push('/shared/person/add')}
        setSearchContent={v => this.setState({ searchContent: v })}
        getSearchContent={searchContent}
        getFound={found}
        getLoading={loading}
      />
    );
  }
}

export default injectIntl(PersonListContainer);
