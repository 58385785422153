import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  Tooltip, Button, Menu, Dropdown, Table, Modal, DatePicker
} from 'antd';
import {
  MoreOutlined, PlusOutlined, LeftOutlined, RightOutlined
} from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';

import { useGet, useDelete } from 'common/api';
import ContentWithLeftButtons from 'common/components/ContentWithLeftButtons';

import { SHARED_HOLIDAY_LIST } from 'modules/shared/_constants/cacheKeys';

const { confirm } = Modal;
const ButtonGroup = Button.Group;

const HolidayList = () => {
  const intl = useIntl();
  const history = useHistory();
  const [year, setYear] = useState(new Date().getFullYear());

  const cacheKey = [SHARED_HOLIDAY_LIST, year];
  const { data, isLoading } = useGet(cacheKey, '/shared/holiday', { params: { year } });
  const { mutate } = useDelete(id => `/shared/holiday/${id}`, { cacheInvalidate: [cacheKey] });

  const menuActions = id => (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu>
          <Menu.Item key="0">
            <Link to={`holiday/${id}/edit`}>
              {intl.formatMessage({ id: 'edit' })}
            </Link>
          </Menu.Item>
          <Menu.Item
            key="1"
            onClick={() => {
              confirm({
                title: intl.formatMessage({ id: 'message.delete_item' }),
                onOk: () => mutate(id)
              });
            }}
          >
            {intl.formatMessage({ id: 'delete' })}
          </Menu.Item>
        </Menu>
      )}
    >
      <MoreOutlined className="more-options" />
    </Dropdown>
  );

  const renderTableColumns = () => [
    {
      title: `${intl.formatMessage({ id: 'date' })} (dd/mm)`,
      render: ({ day, month }) => `${day}/${month}`,
      key: '1',
      width: '7%'
    }, {
      title: intl.formatMessage({ id: 'description' }),
      dataIndex: 'description',
      key: '2'
    }, {
      key: 'actions',
      width: '3%',
      align: 'right',
      render: ({ id }) => menuActions(id),
    }
  ];

  const renderButtons = () => (
    <Tooltip title={<FormattedMessage id="add" />}>
      <Button
        onClick={() => history.push('holiday/add')}
        icon={<PlusOutlined />}
      />
    </Tooltip>
  );

  return (
    <ContentWithLeftButtons buttons={renderButtons()}>
      <div>
        <DatePicker
          picker="year"
          style={{ marginBottom: 5, width: 120 }}
          onChange={date => setYear(date ? date.format('YYYY') : null)}
          value={year ? moment(`${year}-01-01`) : null}
        />
        <ButtonGroup style={{ marginRight: 14 }}>
          <Button
            icon={<LeftOutlined />}
            style={{ width: 50 }}
            onClick={() => setYear(`${parseInt(year, 10) - 1}`)}
          />
          <Button
            icon={<RightOutlined />}
            style={{ width: 50 }}
            onClick={() => setYear(`${parseInt(year, 10) + 1}`)}
          />
        </ButtonGroup>
      </div>

      <Table
        dataSource={data}
        rowKey="id"
        columns={renderTableColumns()}
        showHeader={false}
        size="middle"
        loading={isLoading}
        pagination={false}
        style={{ display: 'inline-block', width: '100%' }}
      />
    </ContentWithLeftButtons>
  );
};

export default HolidayList;
