import React, { Component } from 'react';
import { api } from 'config/api';
import responseHandler from 'common/utils/responseHandler';

import ContactForm from './ContactForm';

class ContactFormContainer extends Component {
  state = {
    form: {
      id: null,
      personId: null,
      type: '',
      description: null,
      principal: false
    },
    loading: false
  };

  componentDidMount() {
    const { match } = this.props;
    const { contactId, personId } = match.params;
    const { form } = this.state;

    this.setState({
      form: {
        ...form,
        id: contactId,
        personId
      }
    });

    if (contactId) {
      this.loadDataToUpdate(contactId);
    }
  }

  handleStore = async () => {
    const { history } = this.props;
    const { form } = this.state;
    this.setState({ loading: true });

    let response = null;

    if (!form.id) {
      response = await api('/shared/contact', {
        method: 'POST',
        body: form
      });
    } else {
      response = await api(`/shared/contact/${ form.id}`, {
        method: 'PUT',
        body: form
      });
    }

    this.setState({ loading: false });
    responseHandler(response);

    if (response.ok) {
      history.goBack();
    }
  };

  loadDataToUpdate = async contactId => {
    this.setState({ loading: true });

    const body = await api('/shared/contact', {
      body: { id: contactId }
    });

    this.setState({
      form: { ...body[0] },
      loading: false
    });
  };

  render() {
    const { history } = this.props;
    const { form, loading } = this.state;

    return (
      <ContactForm
        onStore={this.handleStore}
        onCancel={history.goBack}
        setStateForm={value => this.setState({
          form: { ...form, ...value }
        })}
        getStateForm={form}
        loading={loading}
      />
    );
  }
}

export default ContactFormContainer;
