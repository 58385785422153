import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ShareAltOutlined } from '@ant-design/icons';

import BreadcrumbFromUrl from 'common/components/BreadcrumbFromUrl';

import { useModuleUpdate } from 'core/_contexts/ModuleContext';

import PersonListContainer from 'modules/shared/person/components/PersonListContainer';
import PersonFormContainer from 'modules/shared/person/components/PersonFormContainer';
import PersonDetailsPage from 'modules/shared/person/components/PersonDetailsPage';
import IndexList from 'modules/shared/index/components/IndexList';
import IndexForm from 'modules/shared/index/components/IndexForm';
import IndexValueList from 'modules/shared/indexValue/components/IndexValueList';
import IndexValueForm from 'modules/shared/indexValue/components/IndexValueForm';
import IndexValueImport from 'modules/shared/indexValue/components/IndexValueImport';
import HolidayList from 'modules/shared/holiday/components/HolidayList';
import HolidayForm from 'modules/shared/holiday/components/HolidayForm';

const breadcrumbMap = {
  '/shared': {
    title: (
      <span>
        <ShareAltOutlined />
        <FormattedMessage id="registries" />
      </span>
    ),
    noLink: true
  },
  '/shared/person': {
    title: <FormattedMessage id="person" values={{ plural: false }} />,
    noLink: true
  },
  '/shared/person/list': {
    title: <FormattedMessage id="list" />
  },
  '/shared/person/add': { title: <FormattedMessage id="register" /> },
  '/shared/person/edit/!': { title: <FormattedMessage id="change" /> },
  '/shared/contact': {
    title: <FormattedMessage id="contact" values={{ plural: false }} />,
    noLink: true
  },
  '/shared/contact/add': { title: <FormattedMessage id="register" /> },
  '/shared/index': {
    title: <FormattedMessage id="index" values={{ plural: true }} />
  },
  '/shared/index/add': { title: <FormattedMessage id="register" /> },
  '/shared/index/!/edit': { title: <FormattedMessage id="change" /> },
  '/shared/index/!/value': {
    title: <FormattedMessage id="value" values={{ plural: true }} />
  },
  '/shared/index/!/value/add': { title: <FormattedMessage id="register" /> },
  '/shared/index/!/value/!/edit': { title: <FormattedMessage id="change" /> },
  '/shared/holiday': {
    title: <FormattedMessage id="holiday" values={{ plural: true }} />
  },
  '/shared/holiday/add': { title: <FormattedMessage id="register" /> },
  '/shared/holiday/!/edit': { title: <FormattedMessage id="change" /> },
};

const SharedRoutes = () => {
  const { url } = useRouteMatch();
  const moduleUpdate = useModuleUpdate();

  useEffect(() => {
    moduleUpdate('shared');
  }, []);

  return (
    <div>
      <BreadcrumbFromUrl
        className=""
        breadcrumbMap={breadcrumbMap}
        withBaseUrl
      />

      <div style={{ padding: 20 }}>
        <Route
          path={`${url}/person`}
          component={PersonListContainer}
          exact
        />
        <Route
          path={`${url}/person/add`}
          component={PersonFormContainer}
        />
        <Route
          path={`${url}/person/:personId/details`}
          component={PersonDetailsPage}
        />

        <Route
          path={`${url}/index`}
          exact
          component={IndexList}
        />
        <Route
          path={`${url}/index/add`}
          component={IndexForm}
        />
        <Route
          path={`${url}/index/:indexId/edit`}
          component={IndexForm}
        />

        <Route
          path={`${url}/index/:indexId/value`}
          exact
          component={IndexValueList}
        />
        <Route
          path={`${url}/index/:indexId/value/add`}
          component={IndexValueForm}
        />
        <Route
          path={`${url}/index/:indexId/value/:indexValueId/edit`}
          component={IndexValueForm}
        />
        <Route
          path={`${url}/index/:indexId/value/import`}
          component={IndexValueImport}
        />

        <Route
          path={`${url}/holiday`}
          exact
          component={HolidayList}
        />
        <Route
          path={`${url}/holiday/add`}
          component={HolidayForm}
        />
        <Route
          path={`${url}/holiday/:holidayId/edit`}
          component={HolidayForm}
        />
      </div>
    </div>
  );
};

export default SharedRoutes;
