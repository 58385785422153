import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Breadcrumb, Button, Row, Col
} from 'antd';
import {
  AppstoreOutlined,
  LineChartOutlined,
  BankOutlined,
  ShareAltOutlined,
  LockOutlined
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { useModuleUpdate } from 'core/_contexts/ModuleContext';

import './style.css';

const ModuleListPage = () => {
  const { push } = useHistory();
  const moduleUpdate = useModuleUpdate();

  useEffect(() => {
    moduleUpdate('core');
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <AppstoreOutlined style={{ marginRight: 4 }} />
          <FormattedMessage id="system_modules" />
        </Breadcrumb.Item>
      </Breadcrumb>

      <Row
        type="flex"
        justify="center"
        className="module-list"
        style={{
          padding: 20,
          textAlign: 'center'
        }}
      >
        <Col xs={24} md={8} lg={3}>
          <Button onClick={() => push('/project/list')}>
            <LineChartOutlined style={{ fontSize: 52 }} />
          </Button>
          <div>
            <FormattedMessage id="project" values={{ plural: true }} />
          </div>
        </Col>

        <Col xs={24} md={8} lg={3}>
          <Button onClick={() => push('/debt/dashboard')}>
            <BankOutlined style={{ fontSize: 52 }} />
          </Button>
          <div>
            <FormattedMessage id="debt" values={{ plural: true }} />
          </div>
        </Col>

        <Col xs={24} md={8} lg={3}>
          <Button onClick={() => push('/shared/person')}>
            <ShareAltOutlined style={{ fontSize: 52 }} />
          </Button>
          <div>
            <FormattedMessage id="registries" />
          </div>
        </Col>

        <Col xs={24} md={8} lg={3}>
          <Button onClick={() => push('/access/user')}>
            <LockOutlined style={{ fontSize: 52 }} />
          </Button>
          <div>
            <FormattedMessage id="access_control" />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ModuleListPage;
