import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  Tooltip,
  Avatar,
  Button,
  Progress,
  Menu,
  Dropdown,
  Upload
} from 'antd';
import { MoreOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import uploadConfig from 'config/upload';
import { showFileUrl } from 'config/app';
import { useGet } from 'common/api';

import { PROJECT_HISTORY_LIST } from 'modules/project/_constants/cacheKeys';

const menuActions = historyId => (
  <Dropdown
    trigger={['click']}
    overlay={(
      <Menu>
        <Menu.Item key="0">
          <Link to={`history/${historyId}/edit`}>
            <FormattedMessage id="edit" />
          </Link>
        </Menu.Item>
      </Menu>
    )}
  >
    <MoreOutlined className="more-options" />
  </Dropdown>
);

const nextResponsible = history => (history.responsible
    && (
      <small
        style={{
          color: '#999',
          display: 'block'
        }}
      >
        <i>
          <FormattedMessage id="responsible" />
          :
        </i>
        {` ${history.responsible?.person.name}`}
      </small>
    )
);

const fileList = files => files.map(file => ({
  uid: file.recordedName,
  name: file.name,
  status: 'done',
  url: `${showFileUrl}/${file.recordedName}`
}));

const HistoryList = () => {
  const { taskId } = useParams();
  const { push } = useHistory();

  const [data, setData] = useState([]);

  const { data: historyData } = useGet([PROJECT_HISTORY_LIST, taskId], '/project/history', {
    params: {
      taskId,
      withTask: true
    }
  });

  useEffect(() => {
    if (historyData) setData(historyData.rows);
  }, [historyData]);

  return (
    <span>
      <Tooltip title={<FormattedMessage id="add" />}>
        <Button
          onClick={() => push('history/add')}
          icon={<PlusOutlined />}
          style={{ marginBottom: 10, marginLeft: 46 }}
        />
      </Tooltip>

      <ul
        className="card-with-avatar"
        style={{ marginTop: 0, backgroundColor: 'transparent' }}
      >
        {
          data.map(history => (
            <li key={history.id}>
              <Tooltip title={history.user.person.name}>
                <Avatar
                  icon={<UserOutlined />}
                  shape="square"
                  style={{
                    float: 'left',
                    marginTop: 10
                  }}
                  src={
                    history.user.file
                      ? `${showFileUrl}/${history.user.file.recordedName}`
                      : null
                  }
                />
              </Tooltip>

              <div className="card-with-avatar-content">
                <div style={{ float: 'left' }}>
                  <Progress
                    type="circle"
                    percent={history.progress}
                    width={60}
                  />
                </div>

                <div style={{ marginLeft: 80 }}>
                  <div style={{ float: 'right', top: 0 }}>
                    {menuActions(history.id)}
                  </div>
                  <div>{history.description}</div>
                  <small style={{ color: '#999' }}>
                    {moment.utc(history.createdAt).fromNow()}
                  </small>

                  {nextResponsible(history)}

                  <div style={{ marginTop: 10 }}>
                    <Upload
                      {...uploadConfig()}
                      fileList={fileList(history.file)}
                      showUploadList={{ showRemoveIcon: false }}
                    />
                  </div>
                </div>
              </div>
            </li>
          ))
        }
      </ul>
    </span>
  );
};

export default HistoryList;
